import { Box, Grid, NoSsr, Typography } from '@og-pro/ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFormValues } from 'redux-form';

import { createMockRequisition } from '@og-pro/shared-config/requisitions/mocks';

import { fieldNames, form, sectionNames } from './constants';
import { withRequestTypeForm } from './RequestTypeFormHOC';
import { SettingsStickySidebar } from './SettingsStickySidebar';
import { useRequestTypeActions } from '../RequestTypeActionsContext';
import { bodyContainer, formContainer } from '../styles';
import { getRequestType } from '../../../../../selectors/govApp';
import { RequestTypeFormDisplay } from './RequestTypeFormDisplay';
import { loadMockRequisition } from '../../../../../actions/requisitions';

const { PURCHASE_DETAILS_MODE } = fieldNames;

export const DEFAULT_SECTION = sectionNames.FORM_SETTINGS;

const ConnectedRequestTypeForm = () => {
    const [selectedSection, setSelectedSection] = React.useState(DEFAULT_SECTION);
    const requestType = useSelector(getRequestType);
    const requestTypeFormValues = useSelector(getFormValues(form));
    const purchaseDetailsModeFormValue = requestTypeFormValues[PURCHASE_DETAILS_MODE];
    const { disabled } = useRequestTypeActions();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadMockRequisition({ requestType: { ...requestTypeFormValues } }));
    }, [requestTypeFormValues]);

    return (
        <NoSsr>
            <Box sx={{ ...bodyContainer, paddingY: 2 }}>
                <Typography variant="h2">{requestType.name || 'Untitled'} Form</Typography>
            </Box>
            <Box sx={formContainer}>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        <RequestTypeFormDisplay
                            customFormId={requestType.customFormId}
                            initialValues={createMockRequisition({
                                requestType: { ...requestTypeFormValues },
                                purchaseDetailsMode: purchaseDetailsModeFormValue,
                            })}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SettingsStickySidebar
                            disabled={disabled}
                            onClose={() => setSelectedSection(DEFAULT_SECTION)}
                            section={selectedSection}
                        />
                    </Grid>
                </Grid>
            </Box>
        </NoSsr>
    );
};

export const RequestTypeForm = withRequestTypeForm()(ConnectedRequestTypeForm);
