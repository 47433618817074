import { buildQaTag, componentNames, IconButton } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Close as CloseIcon } from '@mui/icons-material';

import { CUSTOM_FIELD_CHARACTER_LIMIT } from '../constants';
import { priceItemFieldNames } from '../../../../../../constants';
import { InputText, MenuItem } from '../../../../../../../../components';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';
import { RequisitionAttachment } from '../../../Attachments/RequisitionAttachment';
import { getRequisitionEndsInPurchaseOrder } from '../../../../../../../../selectors/govApp';
import {
    MAX_FIN_FILE_SIZE_IN_MB,
    MAX_S3_FILE_SIZE_IN_MB,
} from '../../../Attachments/UploadDropzone';

const { CUSTOM_2, ATTACHMENTS } = priceItemFieldNames;

export const MoreInformation = ({
    change,
    disabled,
    fieldName,
    priceItem,
    showFormValidation,
    ...props
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const [internalNote, setInternalNote] = useState(priceItem[CUSTOM_2]);

    const [attachments, setAttachment] = useState(
        priceItem[ATTACHMENTS] && priceItem[ATTACHMENTS].length > 0
    );

    const normalizeTextLength = limitTextLength(CUSTOM_FIELD_CHARACTER_LIMIT);

    const removeInternalNote = () => {
        setInternalNote(false);
        dispatch(change(`${fieldName}.${CUSTOM_2}`, null));
    };

    const removeAttachment = () => {
        setAttachment(false);
        dispatch(change(`${fieldName}.${ATTACHMENTS}`, []));
    };

    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    return (
        <div className={styles.moreInformation}>
            <div className="row">
                {internalNote && (
                    <div className="col-md-6">
                        <Field
                            characterLimit={CUSTOM_FIELD_CHARACTER_LIMIT}
                            component={InputText}
                            disabled={disabled}
                            label={
                                <>
                                    Internal Note&nbsp;
                                    <IconButton
                                        disabled={disabled}
                                        onClick={removeInternalNote}
                                        qaTag="requisitionCreate-priceItem-removeInternalNote"
                                        size="small"
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </>
                            }
                            name={`${fieldName}.${CUSTOM_2}`}
                            normalize={normalizeTextLength}
                            qaTag="requisitionCreate-priceItem-internalNote"
                            showFormValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                )}
            </div>
            <div className="row">
                {attachments && (
                    <div className="col-md-12">
                        <RequisitionAttachment
                            canRemove
                            fieldNamePrefix={fieldName}
                            maxFileSize={
                                endsInPurchaseOrder
                                    ? MAX_FIN_FILE_SIZE_IN_MB
                                    : MAX_S3_FILE_SIZE_IN_MB
                            }
                            removeFn={removeAttachment}
                            {...props}
                        />
                    </div>
                )}
            </div>
            {(!internalNote || !attachments) && (
                <DropdownButton
                    className={styles.addMoreInformation}
                    data-qa={buildQaTag(
                        'requisitionCreate-addMoreInformation',
                        componentNames.BUTTON
                    )}
                    disabled={disabled}
                    title={
                        <>
                            <i className="fa fa-plus" />
                            Add More Information
                        </>
                    }
                >
                    {!internalNote && (
                        <MenuItem
                            eventKey="2"
                            onClick={() => setInternalNote(true)}
                            qaTag="requisitionCreate-addInternalNote"
                        >
                            Internal Note
                        </MenuItem>
                    )}
                    {!attachments && (
                        <MenuItem
                            eventKey="3"
                            onClick={() => setAttachment(true)}
                            qaTag="requisitionCreate-addAttachment"
                        >
                            Attachment
                        </MenuItem>
                    )}
                </DropdownButton>
            )}
        </div>
    );
};

MoreInformation.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    priceItem: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
};
