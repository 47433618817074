import React from 'react';

import { Field } from 'redux-form';

import { ReduxFormRadioGroup } from '@og-pro/ui';

import PropTypes from 'prop-types';

import {
    characterLimitsArray,
    customFieldFields,
} from '@og-pro/shared-config/customFormService/customField';

const { CHARACTER_LIMIT } = customFieldFields;

export const LongTextOptions = ({ disabled }) => {
    return (
        <Field
            component={ReduxFormRadioGroup}
            defaultSelected
            disabled={disabled}
            label="Set Character Limit"
            name={CHARACTER_LIMIT}
            options={characterLimitsArray}
            showValidation
        />
    );
};

LongTextOptions.propTypes = {
    disabled: PropTypes.bool,
};
