import React from 'react';
import PropTypes from 'prop-types';
import { fieldTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { fieldTypesData } from '../Form/constants';

export const FieldTypePreview = ({ fieldType, customFieldDefinition }) => {
    const FieldTypePreviewComponent = fieldTypesData[fieldType].previewComponent;

    if (!FieldTypePreviewComponent) {
        return null;
    }

    return <FieldTypePreviewComponent customFieldDefinition={customFieldDefinition} />;
};

FieldTypePreview.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
    customFieldDefinition: PropTypes.object,
};
