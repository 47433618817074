import React, { useContext } from 'react';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { ErrorBanner } from '@og-pro/ui';
import {
    createCustomFieldFormName,
    customFieldFields,
    fieldDataTypes,
} from '@og-pro/shared-config/customFormService/customField';

import { LoadingError, Main, NotFound, PageTitle } from '../../../../components';
import { CreateCustomFieldHeader } from './CreateCustomFieldHeader';

import { pageContainerStyles } from './styles';
import { isSystemAdminUser } from '../../../selectors';
import { hasRequisitionSubscription } from '../../../GovApp/selectors';
import { CreateCustomFieldForm } from './CreateCustomFieldForm';
import { withCreateCustomFieldForm } from './CreateCustomFieldFormHOC';
import { scrollToFirstError } from '../../RequisitionsCreate/utils';
import { CreateCustomFieldContext } from './context';
import { useCreateCustomField } from '../../../../lib/customFormService/useCreateCustomField';
import { useUpdateCustomField } from '../../../../lib/customFormService/useUpdateCustomField';
import { useCopyCustomField } from '../../../../lib/customFormService/useCopyCustomField';

const { ID } = customFieldFields;

const ConnectedRequisitionsCreateCustomField = ({ handleSubmit }) => {
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const isSystemAdmin = useSelector(isSystemAdminUser);
    const hasRequisitions = useSelector(hasRequisitionSubscription);
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const { showFormValidation, formErrorsCount } = useContext(CreateCustomFieldContext);
    const createCustomFieldMutation = useCreateCustomField();
    const copyCustomFieldMutation = useCopyCustomField(formValues[ID]);
    const updateCustomFieldMutation = useUpdateCustomField(formValues[ID]);

    const fieldType = formValues.fieldType;
    const isExisting = formValues[ID] !== undefined;
    const isLoading =
        !!createCustomFieldMutation.isLoading ||
        !!updateCustomFieldMutation.isLoading ||
        !!copyCustomFieldMutation.isLoading;

    const showErrorBanner = showFormValidation && formErrorsCount > 0;

    if (!enableCustomFields) {
        return <NotFound />;
    }

    if (!isSystemAdmin || !hasRequisitions) {
        return (
            <LoadingError error="You do not have permission to view this page" useOpenGovStyle />
        );
    }

    const title = isExisting
        ? `${fieldDataTypes[fieldType].label} Field`
        : `Create a New ${fieldDataTypes[fieldType].label} Field`;

    return (
        <Main>
            <PageTitle title={title} />
            <Box sx={pageContainerStyles}>
                <CreateCustomFieldHeader
                    copyCustomFieldMutation={copyCustomFieldMutation}
                    createCustomFieldMutation={createCustomFieldMutation}
                    fieldType={fieldType}
                    handleSubmit={handleSubmit}
                    updateCustomFieldMutation={updateCustomFieldMutation}
                />
                {showErrorBanner && (
                    <ErrorBanner
                        errorCount={formErrorsCount}
                        jumpToErrorFn={scrollToFirstError}
                        title="This Field Cannot Be Saved Yet"
                    />
                )}
                <CreateCustomFieldForm fieldType={fieldType} isLoading={isLoading} />
            </Box>
        </Main>
    );
};

ConnectedRequisitionsCreateCustomField.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const RequisitionsCreateCustomField = withCreateCustomFieldForm()(
    ConnectedRequisitionsCreateCustomField
);
