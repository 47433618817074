import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Link } from '@og-pro/ui';
import { ArrowBack } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { submit } from 'redux-form';

import { RequisitionsSettingsForm } from './RequisitionsSettingsForm';
import { getRequisitionsAdminPath } from '../selectors';
import { getRequisitionSettings } from './selectors';
import connectData from '../../../ConnectData';
import { showSnackbar } from '../../../../actions/notification';
import { loadReqSetting, updateReqSetting } from '../../../../actions/requisitionsAdmin';
import { LoadingError, LoadingSpinner } from '../../../../components';

const fetchData = (getState, dispatch) => {
    return dispatch(loadReqSetting());
};

export const ConnectedRequisitionsSettings = () => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    const requisitionsAdminPath = useSelector((state) =>
        getRequisitionsAdminPath(state, { params })
    );
    const requisitionSettings = useSelector(getRequisitionSettings);
    const isLoading = useSelector((state) => state.requisitionsAdmin.get('loadingReqSetting'));
    const loadingError = useSelector((state) => state.requisitionsAdmin.get('loadReqSettingError'));

    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        setUpdating(true);
        setUpdateError(null);
        return dispatch(updateReqSetting(formValues))
            .then(() => {
                setUpdating(false);
                dispatch(showSnackbar('Settings updated'));
            })
            .catch((error) => {
                setUpdating(false);
                setUpdateError(error.message);
            });
    };

    const handleRequestsAdminBack = () => {
        navigate(requisitionsAdminPath);
    };

    const handleUpdateSettingsClick = () => {
        dispatch(submit('requisitionsSettingsForm'));
    };

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadingError) {
        return <LoadingError error={loadingError} useOpenGovStyle />;
    }

    return (
        <Box>
            <Box
                sx={{
                    px: 7,
                    py: 2,
                    marginTop: '-20px',
                    gap: 2,
                }}
            >
                <Box>
                    <Button
                        color="secondary"
                        component={Link}
                        onClick={handleRequestsAdminBack}
                        qaTag="requisitionsAdminSettings-back"
                        size="small"
                        startIcon={<ArrowBack />}
                        sx={{
                            pl: 0,
                        }}
                        variant="text"
                    >
                        Back to Requests Admin
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h2">Request Settings</Typography>
                        <Button
                            color="primary"
                            onClick={handleUpdateSettingsClick}
                            variant="contained"
                        >
                            Update Settings
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        backgroundColor: capitalDesignTokens.foundations.colors.white,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            px: 14,
                            py: 4,
                        }}
                    >
                        <RequisitionsSettingsForm
                            initialValues={requisitionSettings}
                            onSubmit={onSubmit}
                            updateError={updateError}
                            updating={updating}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export const RequisitionsSettings = connectData(fetchData)(ConnectedRequisitionsSettings);
