import { Box, Button, FormControl, MenuItem, Switch, TextField, Typography } from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import { mdiSitemapOutline } from '@mdi/js';

import MdiIcon from '@mdi/react';

import { SelectCustomFieldForm } from './SelectCustomFieldForm';
import { inColumnsStyle, indentedBlockStyle } from './styles';
import { useCustomFormEditor } from './CustomFormEditorContext';
import { FieldTypePreview } from '../../../RequisitionsCreateCustomField/FieldTypePreview';

export const ConditionalCustomField = ({
    conditionalField,
    conditionalFieldIndex,
    rootFieldOptions,
    rootCustomFieldId,
    showEditingControls,
}) => {
    const {
        toggleRequiredField,
        removeConditionalField,
        isRequired,
        setConditionalField,
        setConditionalValue,
        getCustomFieldDefinition,
    } = useCustomFormEditor();

    const { show_custom_field_id: customFieldId } = conditionalField;

    const handleSelectFn = useCallback(
        (id) => {
            setConditionalField(rootCustomFieldId, conditionalFieldIndex)(id);
        },
        [setConditionalField, rootCustomFieldId, conditionalFieldIndex]
    );

    const handleSetConditionalValue = useCallback(
        (event) => {
            const value = event.target.value === '' ? '' : event.target.value;
            setConditionalValue(rootCustomFieldId, conditionalFieldIndex)(value);
        },
        [setConditionalValue, rootCustomFieldId, conditionalFieldIndex]
    );
    const customFieldDefinition = getCustomFieldDefinition(customFieldId);
    return (
        <Box key={conditionalFieldIndex} sx={indentedBlockStyle}>
            <Box sx={{ ...inColumnsStyle, pl: 1 }}>
                <Typography color="secondary" variant="h6">
                    <MdiIcon path={mdiSitemapOutline} size={0.5} /> Conditional Field{' '}
                    {conditionalFieldIndex + 1}
                </Typography>
                {showEditingControls && (
                    <Button
                        color="secondary"
                        onClick={() => removeConditionalField(rootCustomFieldId, customFieldId)}
                        qaTag="customFieldOption-removeConidtionslField"
                        startIcon={<CloseIcon />}
                        variant="text"
                    >
                        Remove
                    </Button>
                )}
            </Box>
            {showEditingControls && (
                <FormControl fullWidth sx={{ p: 0, mb: 2 }}>
                    <TextField
                        fullWidth
                        label="If Response Is Selected"
                        onChange={handleSetConditionalValue}
                        select
                        value={conditionalField.operandValue || ''}
                        variant="outlined"
                    >
                        {rootFieldOptions.map((option) => (
                            <MenuItem
                                key={option}
                                qaTag={`customFieldOption-${rootCustomFieldId}-conditionalField-${conditionalFieldIndex}-option-${option}`}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
            {conditionalField.operandValue &&
                (customFieldId ? (
                    <Box sx={{ px: 1, my: 1 }}>
                        {showEditingControls ? (
                            <Typography>Add field to this Section:</Typography>
                        ) : (
                            <Typography>
                                If &quot;{conditionalField.operandValue}&quot; is selected, show:
                            </Typography>
                        )}

                        <FieldTypePreview
                            customFieldDefinition={customFieldDefinition}
                            fieldType={customFieldDefinition.fieldType}
                        />
                    </Box>
                ) : (
                    <FormControl fullWidth sx={{ p: 0 }}>
                        <SelectCustomFieldForm
                            conditionalFieldIndex={conditionalFieldIndex}
                            selectFn={handleSelectFn}
                            variant="small"
                        />
                    </FormControl>
                ))}

            {showEditingControls && customFieldId && (
                <FormControl component="fieldset" sx={{ p: 1.5, mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isRequired(customFieldId)}
                                onChange={() => {
                                    toggleRequiredField(customFieldId);
                                }}
                                size="small"
                            />
                        }
                        label={<Typography variant="bodySmall">Required</Typography>}
                        labelPlacement="end"
                    />
                </FormControl>
            )}
        </Box>
    );
};

ConditionalCustomField.propTypes = {
    conditionalField: PropTypes.shape({
        show_custom_field_id: PropTypes.number,
        // Could be null if this is the first time the field is being created
        conditionalStatementId: PropTypes.number,
        operandValue: PropTypes.string,
    }),
    conditionalFieldIndex: PropTypes.number,
    rootCustomFieldId: PropTypes.number,
    rootFieldOptions: PropTypes.arrayOf(PropTypes.string),
    showEditingControls: PropTypes.bool,
};
