import { startCase } from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';
import {
    contractStatuses,
    durationTextMap,
    durationTypes,
    durationTypesDict,
    termUnits,
} from '@og-pro/shared-config/contracts';

import {
    Button,
    CategoryCodes,
    ContactInfo,
    ContractRequisitionsDisplay,
    DisplaySection,
    HtmlContent,
    OutlineButton,
    ProcuratedBadge,
} from '../..';
import { contractStatusIcon, contractStatusText } from '../../helpers/statusHelper';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { loadContract, updateRenewal } from '../../../actions/contracts';
import { showVendorProfileModal } from '../../../actions/vendorProfile';
import { hasRequisitionSubscription } from '../../../containers/GovApp/selectors';
import { getUserJS } from '../../../containers/selectors';
import { currencyFormatter } from '../../../helpers';

const { TERM_BASED } = durationTypesDict;

export const ContractInfo = ({
    contract,
    contract: {
        budget: { amount },
        categories,
        contractId,
        contractParty: { companyEmail, companyName, vendor },
        contractRenewals,
        departmentName,
        durationType,
        endDate,
        fundingSourceTags,
        hasClaim,
        hasProtest,
        id,
        initialTermLength,
        initialTermLengthUnit,
        isCooperative,
        isEmergency,
        isPiggyback,
        procurementClassification,
        project,
        purchaseOrderNo,
        requisitionIdentifier,
        startDate,
        status,
        summary,
        tags,
        vendorAssignedNo,
    },
    isEditor = false,
    isPublicView = false,
    timezone,
}) => {
    const updateRenewalError = useSelector((state) => state.contracts.get('updateRenewalError'));
    const updatingRenewal = useSelector((state) => state.contracts.get('updatingRenewal'));
    const user = useSelector(getUserJS);
    const hasRequisitions = useSelector(hasRequisitionSubscription);

    const dispatch = useDispatch();
    const styles = require('../index.scss');

    const handleChangeRenewal = (contractRenewal) => {
        const { id: contractRenewalId, selected } = contractRenewal;

        const renewalSuccessHandler = (result) => {
            if (result.isLastRenewal) {
                dispatch(
                    showConfirmationSimpleModal(() => {}, {
                        bsStyle: 'primary',
                        btnText: 'Okay',
                        hideCancelButton: true,
                        icon: 'check',
                        text:
                            'The last renewal option has been exercised.\n' +
                            'We have automatically updated your notifications to reflect this.',
                        title: 'Notifications Updated',
                    })
                );
            }
        };

        dispatch(
            showConfirmationSimpleModal(
                () =>
                    dispatch(
                        updateRenewal(
                            id,
                            contractRenewalId,
                            { selected: !selected, updateContractEndDate: true },
                            (result) => {
                                dispatch(loadContract(id));
                                renewalSuccessHandler(result);
                            }
                        )
                    ),
                {
                    bsStyle: 'primary',
                    btnText: 'Update End Date',
                    cancelText: 'No Thanks',
                    icon: 'calendar',
                    iconSize: '',
                    onCancel: () =>
                        dispatch(
                            updateRenewal(
                                id,
                                contractRenewalId,
                                { selected: !selected },
                                renewalSuccessHandler
                            )
                        ),
                    text: 'Would you like to update the contract end date based on your change?',
                    title: 'Update End Date?',
                }
            )
        );
    };

    const handleChangeRenewalSelectedClick = (contractRenewal) => {
        const { selected } = contractRenewal;
        return () => {
            dispatch(
                showConfirmationSimpleModal(() => handleChangeRenewal(contractRenewal), {
                    bsStyle: !selected ? 'success' : 'warning',
                    btnText: !selected ? 'Activate Renewal' : 'De-Activate Renewal',
                    cancelText: 'No Thanks',
                    chainModals: true,
                    icon: 'calendar',
                    iconSize: '',
                    text: !selected
                        ? 'Are you sure you would like to activate this renewal option?'
                        : 'Are you sure you would like to de-activate this renewal option?',
                    title: !selected ? 'Activate Renewal?' : 'De-Activate Renewal?',
                })
            );
        };
    };

    const handleVendorNameClick = () => {
        dispatch(showVendorProfileModal(vendor.id));
    };

    const items = [
        {
            icon: 'dot-circle-o',
            label: 'Status',
            value: (
                <div className={contractStatusText(status)}>
                    <i className={`fa fa-${contractStatusIcon(status)}`} />
                    &nbsp;
                    {startCase(status)}
                </div>
            ),
        },
        {
            icon: 'calendar',
            label: 'Start Date',
            value: moment(startDate).tz(timezone).format('ll'),
        },
        {
            icon: 'calendar',
            label: 'Duration',
            value:
                durationType === TERM_BASED
                    ? `${durationTextMap[durationType]}. Initial Term: ${initialTermLength} ${initialTermLengthUnit}`
                    : durationTextMap[durationType],
        },
        {
            icon: 'file-text',
            label: 'Summary',
            value: summary ? (
                <HtmlContent content={summary} />
            ) : (
                <span className={styles.italicText}>None</span>
            ),
        },
    ];

    if (durationType === TERM_BASED) {
        items.push({
            icon: 'hourglass-start',
            label: 'Renewal Options',
            value: (
                <>
                    {updateRenewalError && <div className="text-danger">{updateRenewalError}</div>}
                    {contractRenewals &&
                        contractRenewals.map((contractRenewal, index) => {
                            const selected = contractRenewal.selected;
                            return (
                                <div
                                    className={`row ${styles.renewalRow}`}
                                    key={contractRenewal.id}
                                >
                                    {!isPublicView && (
                                        <div className="col-sm-3">
                                            <OutlineButton
                                                block
                                                bsSize="xs"
                                                bsStyle={selected ? 'danger' : 'success'}
                                                disabled={!isEditor || updatingRenewal}
                                                invertHover={isEditor}
                                                onClick={handleChangeRenewalSelectedClick(
                                                    contractRenewal
                                                )}
                                                qaTag="contractDetails-activation"
                                            >
                                                {selected ? (
                                                    <span>
                                                        <i className="fa fa-fw fa-ban" />
                                                        &nbsp;De-Activate
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <i className="fa fa-fw fa-check" />
                                                        &nbsp;Activate
                                                    </span>
                                                )}
                                            </OutlineButton>
                                        </div>
                                    )}
                                    <div className="col-sm-9">
                                        <div>
                                            Option {index + 1}: {contractRenewal.length}{' '}
                                            {contractRenewal.lengthUnit}
                                        </div>
                                        {selected && (
                                            <div className="text-muted">
                                                Activated on{' '}
                                                {moment(contractRenewal.updated_at).format('ll')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </>
            ),
        });
    }

    items.push(
        {
            icon: 'calendar',
            label: 'End Date',
            value: endDate ? (
                moment(endDate).tz(timezone).format('ll')
            ) : (
                <span className={styles.italicText}>None</span>
            ),
        },
        {
            icon: 'map-marker',
            label: 'Contract ID',
            value: contractId || <span className={styles.italicText}>None</span>,
        }
    );

    if (!isPublicView) {
        const requisitionsItem = hasRequisitions
            ? {
                  icon: 'shopping-cart',
                  label: 'Request(s)',
                  value: (
                      <ContractRequisitionsDisplay contractId={contract.id} isEditor={isEditor} />
                  ),
              }
            : {
                  icon: 'map-marker',
                  label: 'Request ID',
                  value: requisitionIdentifier || <span className={styles.italicText}>None</span>,
              };
        items.push(requisitionsItem);
    }

    items.push(
        ...(isPublicView
            ? []
            : [
                  {
                      icon: 'dollar',
                      label: 'Contract Amount',
                      value: currencyFormatter({ value: amount }),
                  },
              ]),
        {
            icon: 'briefcase',
            label: 'Vendor',
            value: (
                <div>
                    {vendor && !isPublicView ? (
                        <span className={styles.vendorDataCell}>
                            <Button
                                bsStyle="link"
                                onClick={handleVendorNameClick}
                                qaTag="contractDetails-companyNameLink"
                                zeroPadding
                            >
                                {companyName}
                            </Button>
                            {user && <ProcuratedBadge supplierId={vendor.id} />}
                        </span>
                    ) : (
                        companyName
                    )}
                    {companyEmail && !isPublicView && (
                        <>
                            <br />
                            <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                        </>
                    )}
                    {vendorAssignedNo && !isPublicView && (
                        <>
                            <br />
                            Vendor Number: {vendorAssignedNo}
                        </>
                    )}
                </div>
            ),
        }
    );

    if (!isPublicView) {
        items.push({
            icon: 'user',
            label: 'Project Contact',
            value: <ContactInfo className={styles.contact} {...contract} />,
        });
    }

    items.push(
        {
            icon: 'user',
            label: 'Procurement Contact',
            value: (
                <ContactInfo
                    className={styles.contact}
                    fieldNames={mappedProcurementContactFields}
                    {...contract}
                />
            ),
        },
        {
            icon: 'star',
            label: 'Department',
            value: departmentName,
        },
        {
            icon: 'repeat',
            label: 'Contains Cooperative Language',
            value: isPiggyback ? 'Yes' : 'No',
        },
        {
            icon: 'handshake-o',
            label: 'Acquired via Cooperative',
            value: isCooperative ? 'Yes' : 'No',
        },
        {
            icon: 'exclamation-triangle',
            label: 'Bid Protest',
            value: hasProtest ? 'Yes' : 'No',
        },
        {
            icon: 'flag',
            label: 'Contract Claim',
            value: hasClaim ? 'Yes' : 'No',
        }
    );

    if (isEmergency) {
        items.push({
            icon: 'ambulance',
            label: 'Emergency',
            value: 'Yes',
        });
    }

    if (project) {
        items.push({
            icon: 'folder',
            label: 'Associated Project',
            value: (
                <Link
                    to={
                        isPublicView
                            ? `/portal/${project.government.code}/projects/${project.id}`
                            : `/governments/${project.government.id}/projects/${project.id}`
                    }
                >
                    <span className="pseudoLink">{project.title || 'Untitled Project'}</span>
                </Link>
            ),
        });
    }

    items.push(
        {
            icon: 'edit',
            label: 'Procurement Classification',
            value: <div>{procurementClassification}</div>,
        },
        {
            icon: 'tags',
            label: 'Contract Type',
            value: (
                <div>
                    {tags.map((tag) => (
                        <div key={tag.id}>{tag.name}</div>
                    ))}
                </div>
            ),
        },
        {
            icon: 'usd',
            label: 'Funding Source',
            value: (
                <div>
                    {fundingSourceTags.map((tag) => (
                        <div key={tag.id}>{tag.name}</div>
                    ))}
                </div>
            ),
        },
        {
            icon: 'list-ul',
            label: 'Category Codes',
            value: (
                <div>
                    <CategoryCodes codes={categories} noCategoriesText="None" />
                </div>
            ),
        }
    );

    if (purchaseOrderNo) {
        items.push({
            icon: 'map-marker',
            label: 'PO Number',
            value: purchaseOrderNo,
        });
    }

    return <DisplaySection header="CONTRACT INFORMATION" items={items} />;
};

ContractInfo.propTypes = {
    contract: PropTypes.shape({
        budget: PropTypes.shape({
            amount: PropTypes.number,
        }),
        categories: PropTypes.array.isRequired,
        contractId: PropTypes.string,
        contractParty: PropTypes.shape({
            companyEmail: PropTypes.string,
            companyName: PropTypes.string.isRequired,
            vendor: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }),
        }).isRequired,
        contractRenewals: PropTypes.arrayOf(
            PropTypes.shape({
                length: PropTypes.number.isRequired,
                lengthUnit: PropTypes.oneOf(termUnits).isRequired,
                selected: PropTypes.bool.isRequired,
            })
        ),
        departmentName: PropTypes.string.isRequired,
        durationType: PropTypes.oneOf(durationTypes).isRequired,
        endDate: PropTypes.string,
        fundingSourceTags: PropTypes.array.isRequired,
        hasClaim: PropTypes.bool,
        hasProtest: PropTypes.bool,
        id: PropTypes.number.isRequired,
        initialTermLength: PropTypes.number,
        initialTermLengthUnit: PropTypes.oneOf(termUnits),
        isCooperative: PropTypes.bool,
        isEmergency: PropTypes.bool,
        isPiggyback: PropTypes.bool,
        procurementClassification: PropTypes.string,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            }).isRequired,
            title: PropTypes.string,
        }),
        purchaseOrderNo: PropTypes.string,
        requisitionIdentifier: PropTypes.string,
        startDate: PropTypes.string.isRequired,
        status: PropTypes.oneOf(contractStatuses).isRequired,
        summary: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
            })
        ),
        vendorAssignedNo: PropTypes.string,
    }),
    isEditor: PropTypes.bool,
    isPublicView: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
};
