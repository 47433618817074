import React from 'react';
import { Panel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Outlet, useParams, useLocation } from 'react-router-dom';

import { Nav, NavItem, SectionTitle } from '../../../components';
import { isEmbedded } from '../../../helpers';
import { getGovernmentJS } from '../selectors';

export const GovPortalMain = () => {
    const params = useParams();
    const location = useLocation();

    const { displayPublicVendorInformation } = useSelector(getGovernmentJS);

    const { governmentCode } = params;

    const isEmbed = isEmbedded(location);

    const portalPath = `/portal/${isEmbed ? 'embed/' : ''}${governmentCode}`;

    return (
        <Panel>
            <Panel.Body>
                {!isEmbed && <SectionTitle title="Procurement Portal" />}
                <Nav bsStyle="tabs" className="no-print">
                    <NavItem
                        data-tag="publicPortal-projects"
                        key="projects-list"
                        to={`${portalPath}${isEmbed ? '/project-list' : ''}`}
                    >
                        Projects
                    </NavItem>
                    <NavItem
                        data-tag="publicPortal-calendar"
                        key="calendar"
                        to={`${portalPath}/calendar`}
                    >
                        Calendar
                    </NavItem>
                    {displayPublicVendorInformation && (
                        <NavItem
                            data-tag="publicPortal-vendors"
                            key="vendors"
                            to={`${portalPath}/vendors`}
                        >
                            Vendors
                        </NavItem>
                    )}
                </Nav>
                <Outlet />
            </Panel.Body>
        </Panel>
    );
};
