import {
    fieldNames,
    requestTypeGroupSequenceFieldNames,
    reviewGroupPositionFieldNames,
    reviewGroupPositionUsersFieldNames,
} from '../constants';
import { arrayError } from '../../../../../../Forms/validation';

const { REVIEW_GROUP_POSITIONS, REQUEST_TYPE_GROUP_SEQUENCES } = fieldNames;
const { REQUEST_TYPE_ID, REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;
const { REVIEW_GROUP_POSITION_USERS, USER_IDS: REVIEW_GROUP_POSITION_USER_IDS } =
    reviewGroupPositionFieldNames;
const { IS_PRIMARY } = reviewGroupPositionUsersFieldNames;

export default function validateSequence(values) {
    const errors = {};

    const requestTypeGroupSequencesValues = values[REQUEST_TYPE_GROUP_SEQUENCES] || [];

    if (requestTypeGroupSequencesValues.length === 0) {
        errors[REQUEST_TYPE_GROUP_SEQUENCES] = arrayError(
            'Please include at least one request type'
        );
        return errors;
    }

    errors[REQUEST_TYPE_GROUP_SEQUENCES] = requestTypeGroupSequencesValues.map(
        (requestTypeGroupSequence) => {
            const requestTypeGroupSequenceErrors = {};

            if (!requestTypeGroupSequence[REQUEST_TYPE_ID]) {
                requestTypeGroupSequenceErrors[REQUEST_TYPE_ID] =
                    'Please select a request type to use';
            }

            if (!requestTypeGroupSequence[REVIEW_SEQUENCE_ID]) {
                requestTypeGroupSequenceErrors[REVIEW_SEQUENCE_ID] =
                    'Please select an approval sequence to use';
            }

            return requestTypeGroupSequenceErrors;
        }
    );

    errors[REVIEW_GROUP_POSITIONS] = (values[REVIEW_GROUP_POSITIONS] || []).map((position) => {
        const positionErrors = {};

        if ((position[REVIEW_GROUP_POSITION_USER_IDS] || []).length === 0) {
            positionErrors[REVIEW_GROUP_POSITION_USER_IDS] =
                'At least one user must be assigned to the position group';
        } else if (
            (position[REVIEW_GROUP_POSITION_USERS] || []).every(
                (reviewGroupPositionUser) => !reviewGroupPositionUser[IS_PRIMARY]
            )
        ) {
            positionErrors[REVIEW_GROUP_POSITION_USER_IDS] = 'A primary user must be assigned';
        }

        return positionErrors;
    });

    return errors;
}
