import React from 'react';

import { ReduxFormRadioGroup } from '@og-pro/ui';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { ENUM_OPTIONS, NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const YesNoPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];
    const yesNoOptions = customFieldDefinition[ENUM_OPTIONS] || [];

    const yesNoOptionsArray = yesNoOptions.map((option) => {
        return {
            value: option,
            label: option,
        };
    });

    return (
        <Field
            component={ReduxFormRadioGroup}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="yesNo_PREVIEW"
            options={yesNoOptionsArray}
            sx={{
                width: '100%',
            }}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

YesNoPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
