import { Box } from '@og-pro/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { vendorSelectionStates } from '@og-pro/shared-config/requisitions';

import { useGetAssociatedVendorData } from './hooks';
import { MismatchContractDialog } from './MismatchContractDialog';
import { Vendors } from './Vendors';
import { fieldNames } from '../../constants';
import { getInitialVendorSearch, requisitionsCreateFormValueSelector } from '../../selectors';
import { qaTagPageName } from '../../../../RequisitionGroups/ReviewSequenceEdit/constants';
import { setInitialVendorSelectionInputData } from '../../../../../../actions/requisitionsCreate';
import { InputText, RadioGroup } from '../../../../../../components';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { getRequisitionEndsInPurchaseOrder } from '../../../../../../selectors/govApp';
import { useVendorSelectionFormDisplay } from '../../../../../../lib/ogFinancials/useVendorSelection';

const { VENDORS, ADDITIONAL_VENDOR_NOTES, VENDOR_SELECTION_STATE } = fieldNames;
const { NO_VENDORS, SELECTED_VENDORS } = vendorSelectionStates;

export const VendorSelection = ({ change, disabled, isApprovalView, showFormValidation }) => {
    const { showInput } = useSelector(getInitialVendorSearch);

    const [showAddVendorInput, setShowAddVendorInput] = useState(showInput);
    const [mismatchModalData, setMismatchModalData] = useState({ show: false, data: null });

    const dispatch = useDispatch();
    const styles = require('./index.scss');
    const sharedStyles = require('../../shared.scss');

    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);
    const vendorSelectionFormDisplay = useVendorSelectionFormDisplay();
    const vendorSelectionState = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, VENDOR_SELECTION_STATE)
    );
    const vendors = useSelector((state) => requisitionsCreateFormValueSelector(state, VENDORS));

    const hideMismatchModal = () => setMismatchModalData({ show: false, data: null });

    const showMismatchModal = ({ contract, vendor, ...data }) =>
        setMismatchModalData({ show: true, data: { contract, vendor, ...data } });

    // this size is arbitrary, it is just here to prevent unreasonably large inputs
    const normalizeText = limitTextLength(2048);

    useEffect(() => {
        if (showInput) {
            // Reset the initial show vendor selection input value after its used on first render
            dispatch(setInitialVendorSelectionInputData());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (vendorSelectionState === NO_VENDORS) {
            dispatch(change(VENDORS, []));
        }
        if (vendorSelectionState === SELECTED_VENDORS && (!vendors || vendors.length === 0)) {
            setShowAddVendorInput(true);
        }
    }, [vendorSelectionState]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        loading: loadingVendorData,
        vendors: associatedVendorData,
        contracts: associatedContractData,
    } = useGetAssociatedVendorData({
        endsInPurchaseOrder,
        vendors,
    });

    return (
        <Box
            className={classNames({ [sharedStyles.approvalContainer]: isApprovalView })}
            sx={{ ...(isApprovalView && { gap: 0 }) }}
        >
            {!isApprovalView && (
                <>
                    <h4 className={styles.header}>Have you identified a vendor? *</h4>
                    <Field
                        component={RadioGroup}
                        disabled={disabled}
                        groupLabel="vendorSelection"
                        name={VENDOR_SELECTION_STATE}
                        options={[
                            {
                                name: 'Yes',
                                value: SELECTED_VENDORS,
                                qaTag: `${qaTagPageName}-vendorSelectionYes`,
                            },
                            {
                                name: 'Not at this time.',
                                value: NO_VENDORS,
                                qaTag: `${qaTagPageName}-vendorSelectionNo`,
                            },
                        ]}
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </>
            )}
            {(vendorSelectionState === SELECTED_VENDORS || isApprovalView) && (
                <>
                    <FieldArray
                        associatedContractData={associatedContractData}
                        associatedVendorData={associatedVendorData}
                        change={change}
                        component={Vendors}
                        disabled={disabled}
                        endsInPurchaseOrder={endsInPurchaseOrder}
                        isApprovalView={isApprovalView}
                        loadingVendorData={loadingVendorData}
                        name={VENDORS}
                        setShowAddVendorInput={setShowAddVendorInput}
                        showAddVendorInput={showAddVendorInput}
                        showFormValidation={showFormValidation}
                        showMismatchModal={showMismatchModal}
                        vendorSelectionFormDisplay={vendorSelectionFormDisplay}
                        vendorSelectionState={vendorSelectionState}
                    />
                    {vendors?.length > 0 && (
                        <Field
                            component={InputText}
                            label={
                                <>
                                    Additional Vendor Notes
                                    <span className={styles.internalBadge}>
                                        <i className="fa fa-lock" /> Internal
                                    </span>
                                </>
                            }
                            minRows={4}
                            name={ADDITIONAL_VENDOR_NOTES}
                            normalize={normalizeText}
                            qaTag={`${qaTagPageName}-additionalVendorNotes`}
                            showValidation={showFormValidation}
                            type="textarea"
                            useOpenGovStyle
                        />
                    )}
                </>
            )}
            {mismatchModalData.show && (
                <MismatchContractDialog
                    data={mismatchModalData.data}
                    hideModal={hideMismatchModal}
                />
            )}
        </Box>
    );
};

VendorSelection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isApprovalView: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
