import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { fieldNames, requestTypeGroupSequenceFieldNames } from './constants';
import { replaceReviewGroupPositions } from './helpers';
import { RequisitionGroupPositionsField } from './RequisitionGroupPositionsField';
import { getRequisitionGroupFormValues, getReviewSequenceOptions } from '../selectors';
import { SearchSelect } from '../../../../../components';
import { getReviewSequences } from '../../../../../selectors/govApp';

const { REVIEW_GROUP_POSITIONS, REVIEW_SEQUENCE } = fieldNames;
const { REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;

export const RequisitionGroupSequenceSection = ({
    change,
    disabled,
    fields,
    showFormValidation,
}) => {
    const formValues = useSelector(getRequisitionGroupFormValues);
    const sequenceOptions = useSelector(getReviewSequenceOptions);
    const sequences = useSelector(getReviewSequences);

    const onSequenceChange = (sequenceId) => {
        const selectedSequence = sequences.find((sequence) => sequence.id === sequenceId);
        change(REVIEW_SEQUENCE, selectedSequence);

        const reviewGroupPositionFormValues = get(formValues, REVIEW_GROUP_POSITIONS) || [];
        const sequencePositions = replaceReviewGroupPositions(reviewGroupPositionFormValues, [
            selectedSequence,
        ]);
        change(REVIEW_GROUP_POSITIONS, sequencePositions);
    };

    return (
        <>
            {fields.map((field, index) => (
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    help="Sequence to use when routing requests in this group for approval"
                    key={index}
                    label="Approval Sequence"
                    name={`${field}.${REVIEW_SEQUENCE_ID}`}
                    onChange={onSequenceChange}
                    options={sequenceOptions}
                    placeholder="Select approval sequence"
                    showValidation={showFormValidation}
                />
            ))}
            <FieldArray
                change={change}
                component={RequisitionGroupPositionsField}
                disabled={disabled}
                name={REVIEW_GROUP_POSITIONS}
                showFormValidation={showFormValidation}
            />
        </>
    );
};

RequisitionGroupSequenceSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
};
