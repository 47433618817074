import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import { fieldNames } from './constants';
import { RequestTypesSection } from './RequestTypesSection';
import { RequisitionGroupSequenceSection } from './RequisitionGroupSequenceSection';

const { REQUEST_TYPE_GROUP_SEQUENCES } = fieldNames;

export const RequisitionGroupRequestTypesSection = ({ change, disabled, showFormValidation }) => {
    const isCustomFieldsFlagEnabled = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    return (
        <FieldArray
            change={change}
            component={
                isCustomFieldsFlagEnabled ? RequestTypesSection : RequisitionGroupSequenceSection
            }
            disabled={disabled}
            name={REQUEST_TYPE_GROUP_SEQUENCES}
            showFormValidation={showFormValidation}
        />
    );
};

RequisitionGroupRequestTypesSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
