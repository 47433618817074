import { createSelector } from 'reselect';
import { noop } from 'lodash/util';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { qaTagPageName } from '../../constants';
import { getCurrentStepUserApprovalsData } from '../../selectors';
import { getUserJS } from '../../../../selectors';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import {
    preCheckBudget,
    showRequisitionApprovalModal,
    submitRequisition,
    toggleRequisitionOnHold,
} from '../../../../../actions/requisitions';
import {
    reloadRequisitionAndApprovals,
    remindApprovers,
    showMissingVendorAddressBanner,
    showMissingVendorBanner,
    showRejectionNoteModal,
    submitApproval,
} from '../../../../../actions/requisitionsApprovals';
import { actionLabelNames } from '../../../../../lib/ogFinancials/constants';
import {
    getRequisitionCurrentStep,
    getRequisitionEndsInPurchaseOrder,
    getRequisitionJS,
    hasRequisitionCreatorAccess,
} from '../../../../../selectors/govApp';

const { APPROVE: APPROVE_LABEL, SUBMIT: SUBMIT_LABEL } = actionLabelNames;

const approveAction =
    (stepPositionApprover, additionalApprovalData, approvalModalOptions) => (dispatch) => {
        const {
            allowPurchaseOrderCreation = false,
            hasVendorSelected = false,
            endsInPurchaseOrder,
            isSomeSelectedVendorInvalid,
        } = additionalApprovalData ?? {};

        if (allowPurchaseOrderCreation && !hasVendorSelected && endsInPurchaseOrder) {
            dispatch(showMissingVendorBanner());
        } else if (isSomeSelectedVendorInvalid && endsInPurchaseOrder) {
            dispatch(showMissingVendorAddressBanner());
        } else {
            const onConfirm = (approvalModalData) =>
                dispatch(
                    submitApproval(stepPositionApprover.id, {
                        status: approvalStatusTypes.APPROVED,
                        ...additionalApprovalData,
                        ...approvalModalData,
                    })
                );

            dispatch(
                preCheckBudget(
                    stepPositionApprover.requisition_id,
                    APPROVE_LABEL,
                    () =>
                        dispatch(
                            showRequisitionApprovalModal(onConfirm, {
                                actionLabel: APPROVE_LABEL,
                                bsStyle: 'primary',
                                btnText: 'Approve',
                                icon: 'fa-check',
                                text: 'Are you sure you want to approve this request?',
                                ...approvalModalOptions,
                            })
                        ),
                    noop
                )
            );
        }
    };

const getApproveActions = (
    userApprovalsData,
    qaTag,
    additionalApprovalData,
    approvalModalOptions
) => {
    if (userApprovalsData.length === 1) {
        return {
            onClick: approveAction(
                userApprovalsData[0].stepPositionApprover,
                additionalApprovalData,
                approvalModalOptions
            ),
            qaTag,
        };
    }

    const action = additionalApprovalData?.action ?? 'Approve';

    return {
        header: `${action} on behalf of`,
        options: userApprovalsData.map((datum, index) => {
            const { stepPositionApproval, stepPositionApprover } = datum;
            const disabled = stepPositionApproval.status !== approvalStatusTypes.PENDING;
            return {
                disabled,
                onClick: approveAction(
                    stepPositionApprover,
                    additionalApprovalData,
                    approvalModalOptions
                ),
                qaTag: `${qaTag}Option${index + 1}`,
                text: `${stepPositionApproval.position.name}${disabled ? ' [Approved]' : ''}`,
            };
        }),
        qaTag,
    };
};

const getRejectAction = (userApprovalsData, qaTag) => {
    if (userApprovalsData.length === 1) {
        const { stepPositionApprover } = userApprovalsData[0];
        return {
            onClick: (dispatch) => dispatch(showRejectionNoteModal({ stepPositionApprover })),
            qaTag,
        };
    }

    return {
        header: 'Reject on behalf of',
        options: userApprovalsData.map((datum, index) => {
            const { stepPositionApproval, stepPositionApprover } = datum;
            const disabled = stepPositionApproval.status !== approvalStatusTypes.PENDING;
            return {
                disabled,
                onClick: (dispatch) => dispatch(showRejectionNoteModal({ stepPositionApprover })),
                qaTag: `${qaTag}Option${index + 1}`,
                text: `${stepPositionApproval.position.name}${disabled ? ' [Approved]' : ''}`,
            };
        }),
        qaTag,
    };
};

const APPROVE = (userApprovalsData) => ({
    bsStyle: 'primary',
    icon: 'fa fa-check',
    text: 'Approve',
    ...getApproveActions(userApprovalsData, `${qaTagPageName}-approve`),
});

const SUBMIT_FOR_REVIEW = (requisition) => ({
    bsStyle: 'primary',
    onClick: (dispatch) => {
        const onConfirm = (approvalModalData) =>
            dispatch(
                submitRequisition(requisition.id, approvalModalData, {
                    onSuccess: () => {
                        dispatch(reloadRequisitionAndApprovals(requisition.id));
                    },
                })
            );
        dispatch(
            preCheckBudget(
                requisition.id,
                SUBMIT_LABEL,
                () =>
                    dispatch(
                        showRequisitionApprovalModal(onConfirm, {
                            actionLabel: SUBMIT_LABEL,
                            bsStyle: 'primary',
                            btnText: 'Submit for Review',
                            icon: 'fa-send',
                            text: 'Are you sure you want to submit this request for review?',
                        })
                    ),
                noop
            )
        );
    },
    qaTag: `${qaTagPageName}-submitForReview`,
    text: 'Submit for Review',
});

const ACKNOWLEDGE = (userApprovalsData) => ({
    bsStyle: 'primary',
    icon: 'fa fa-thumbs-up',
    text: 'Acknowledge',
    ...getApproveActions(
        userApprovalsData,
        `${qaTagPageName}-acknowledge`,
        {
            action: 'Acknowledge',
        },
        {
            btnText: 'Acknowledge',
            icon: 'fa-thumbs-up',
        }
    ),
});

const CREATE_PO = (userApprovalsData, requisition, endsInPurchaseOrder) => {
    const purchaseOrderCount = requisition.vendors.length;
    let buttonText;
    if (!endsInPurchaseOrder) {
        buttonText = 'Approve & Complete';
    } else if (purchaseOrderCount > 1) {
        buttonText = `Approve & Create ${purchaseOrderCount} POs`;
    } else {
        buttonText = 'Approve & Create PO';
    }

    const icon = endsInPurchaseOrder ? 'fa-dollar' : 'fa-check';
    const plural = purchaseOrderCount > 1 ? 's' : '';
    const isSomeSelectedVendorInvalid = requisition.vendors?.some(
        (vendor) => !vendor.address1 || !vendor.state || !vendor.city || !vendor.zipCode
    );

    return {
        bsStyle: 'primary',
        icon: `fa ${icon}`,
        text: buttonText,
        ...getApproveActions(
            userApprovalsData,
            `${qaTagPageName}-createPurchaseOrder`,
            {
                allowPurchaseOrderCreation: true,
                hasVendorSelected: purchaseOrderCount > 0,
                endsInPurchaseOrder,
                isSomeSelectedVendorInvalid,
            },
            {
                btnText: buttonText,
                icon,
                text: `Are you sure you want to approve this request? This is the final step in the approval process. Approving will ${
                    endsInPurchaseOrder
                        ? `create ${purchaseOrderCount} Purchase Order${plural}.`
                        : 'successfully complete the request.'
                }`,
            }
        ),
    };
};

const REJECT = (userApprovalsData) => ({
    bsStyle: 'danger',
    icon: 'fa fa-ban',
    text: 'Reject',
    ...getRejectAction(userApprovalsData, `${qaTagPageName}-reject`),
});

const ON_HOLD = (requisition) => ({
    bsStyle: undefined,
    icon: 'fa fa-pause',
    onClick: (dispatch) => {
        const onConfirm = () => dispatch(toggleRequisitionOnHold(requisition.id, true));

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                btnText: 'Put On Hold',
                bsStyle: 'warning',
                icon: 'pause',
                text: 'Are you sure you want to put this request on hold? No editing or approvals can happen while on hold.',
            })
        );
    },
    qaTag: `${qaTagPageName}-onHold`,
    text: 'On Hold',
});

const OFF_HOLD = (requisition) => ({
    bsStyle: 'primary',
    icon: 'fa fa-play',
    onClick: (dispatch) => {
        const onConfirm = () => dispatch(toggleRequisitionOnHold(requisition.id, false));

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                btnText: 'Take Off Hold',
                bsStyle: 'primary',
                icon: 'play',
                text: 'Are you sure you want to take this request off hold? Editing and approvals will be able to resume.',
            })
        );
    },
    qaTag: `${qaTagPageName}-offHold`,
    text: 'Take Off Hold',
});

const REMIND = (requisition) => ({
    bsStyle: undefined,
    icon: 'fa fa-bullhorn',
    onClick: (dispatch) => {
        const onConfirm = () => dispatch(remindApprovers(requisition.id, true));

        dispatch(
            showConfirmationSimpleModal(onConfirm, {
                btnText: 'Send Reminders',
                bsStyle: 'primary',
                icon: 'send',
                text: 'Send reminder emails to all approvers that have not yet approved',
            })
        );
    },
    qaTag: `${qaTagPageName}-remind`,
    text: 'Remind',
});

const getActionButtons = createSelector(
    [getRequisitionJS, getCurrentStepUserApprovalsData, getRequisitionEndsInPurchaseOrder],
    (requisition, userApprovalsData, endsInPurchaseOrder) => {
        return {
            ACKNOWLEDGE: ACKNOWLEDGE(userApprovalsData),
            APPROVE: APPROVE(userApprovalsData),
            CREATE_PO: CREATE_PO(userApprovalsData, requisition, endsInPurchaseOrder),
            OFF_HOLD: OFF_HOLD(requisition),
            ON_HOLD: ON_HOLD(requisition),
            REJECT: REJECT(userApprovalsData),
            REMIND: REMIND(requisition),
            SUBMIT_FOR_REVIEW: SUBMIT_FOR_REVIEW(requisition),
        };
    }
);

export const getReviewerActionButtons = createSelector(
    [
        getRequisitionJS,
        hasRequisitionCreatorAccess,
        getCurrentStepUserApprovalsData,
        getRequisitionCurrentStep,
        getActionButtons,
        getUserJS,
    ],
    (requisition, isCreator, userApprovalsData, currentStep, actionButtons, user) => {
        const pendingUserApprovalsData = userApprovalsData.filter(
            (datum) => datum.stepPositionApproval.status === approvalStatusTypes.PENDING
        );

        const isApprover = userApprovalsData.length > 0;
        const isPendingApprover = pendingUserApprovalsData.length > 0;
        const isDirectPendingApprover = pendingUserApprovalsData.some(
            (datum) => datum.stepPositionApprover.user_id === user.id
        );

        if (requisition.isOnHold && isApprover) {
            return [actionButtons.OFF_HOLD];
        }

        // Current reviewers that have not reviewed should be displayed approval options
        if (isPendingApprover) {
            const remindActionButton = isDirectPendingApprover ? [] : [actionButtons.REMIND];
            if (currentStep.reviewType === reviewTypes.APPROVE) {
                const APPROVE_ACTION = currentStep.isPoCreationStep ? 'CREATE_PO' : 'APPROVE';
                return [
                    ...remindActionButton,
                    actionButtons.ON_HOLD,
                    actionButtons.REJECT,
                    actionButtons[APPROVE_ACTION],
                ];
            }
            if (currentStep.reviewType === reviewTypes.CONFIRM) {
                const APPROVE_ACTION = currentStep.isPoCreationStep ? 'CREATE_PO' : 'ACKNOWLEDGE';
                return [
                    ...remindActionButton,
                    actionButtons.ON_HOLD,
                    actionButtons[APPROVE_ACTION],
                ];
            }
            return [];
        }

        // Creators and past step approvers should be able to remind reviewers
        if (isCreator || isApprover) {
            return [actionButtons.REMIND, ...(isApprover ? [actionButtons.ON_HOLD] : [])];
        }

        // All others have no actions
        return [];
    }
);

export const getResubmitActionButtons = createSelector(
    [hasRequisitionCreatorAccess, getActionButtons],
    (isCreator, actionButtons) => {
        if (isCreator) {
            return [actionButtons.SUBMIT_FOR_REVIEW];
        }
        return [];
    }
);
