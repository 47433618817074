import React from 'react';

import { Button } from '@og-pro/ui';
import AddIcon from '@mui/icons-material/Add';

import { Box } from '@mui/material';

import { RootCustomField, SelectCustomFieldForm, useCustomFormEditor } from '.';
import { LoadingSpinner } from '../../../../../../components';

export const CustomFieldsEditor = () => {
    const {
        isCustomFormLoading,
        rootCustomFields,
        addField,
        showAddFieldForm,
        setShowAddFieldForm,
        focusedCustomFieldId,
        setFocusedCustomFieldId,
    } = useCustomFormEditor();

    if (isCustomFormLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    const handleSelectedField = (selectedFieldId) => {
        addField(selectedFieldId);
        setShowAddFieldForm(false);
    };
    return (
        <Box
            onClick={(e) => {
                setFocusedCustomFieldId(null);
                e.stopPropagation();
            }}
        >
            {rootCustomFields.map((rootCustomField) => {
                return (
                    <RootCustomField
                        key={rootCustomField.customFieldId}
                        rootCustomField={rootCustomField}
                        showEditingControls={focusedCustomFieldId === rootCustomField.customFieldId}
                    />
                );
            })}
            {showAddFieldForm && <SelectCustomFieldForm selectFn={handleSelectedField} />}
            <Button
                color="primary"
                disabled={showAddFieldForm}
                onClick={(e) => {
                    setShowAddFieldForm(true);
                    setFocusedCustomFieldId(null);
                    e.stopPropagation();
                }}
                qaTag="customFieldOption-addCustomField"
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
                variant="outlined"
            >
                {showAddFieldForm || rootCustomFields.length > 0
                    ? 'Add Another Field'
                    : 'Add a Field'}
            </Button>
        </Box>
    );
};
