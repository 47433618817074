import {
    renderProjectStaticMarkup,
    renderTableOfContentsStaticMarkup,
    renderProjectTimelineStaticMarkup,
} from './renderProjectStaticMarkup';
import { usesDocxHeadingFormatters } from '../containers/GovApp/selectors';
import { downloadFile } from './utils';
import { showSnackbar } from './notification';

export const HIDE_MODAL = 'exportProject/HIDE_MODAL';
export const SHOW_MODAL = 'exportProject/SHOW_MODAL';
export const SET_EXPORT_DATA = 'exportProject/SET_EXPORT_DATA';
export const CONFIGURE_EXPORT = 'exportProject/CONFIGURE_EXPORT';
export const LOAD = 'exportProject/LOAD';
export const LOAD_SUCCESS = 'exportProject/LOAD_SUCCESS';
export const LOAD_FAIL = 'exportProject/LOAD_FAIL';
export const LOAD_DOCUMENT = 'exportProject/LOAD_DOCUMENT';
export const LOAD_DOCUMENT_SUCCESS = 'exportProject/LOAD_DOCUMENT_SUCCESS';
export const LOAD_DOCUMENT_FAIL = 'exportProject/LOAD_DOCUMENT_FAIL';
export const LOAD_DOCX_HEADING_FORMATTERS_SUCCESS =
    'exportProject/LOAD_DOCX_HEADING_FORMATTERS_SUCCESS';

export function hideExportModal() {
    return { type: HIDE_MODAL };
}

export function showExportModal(text, saveOnExport, sectionId) {
    return { type: SHOW_MODAL, text, saveOnExport, sectionId };
}

const loadHeadingFormatters = async (dispatch, getState, client) => {
    const headingFormattersEnabled = usesDocxHeadingFormatters(getState());

    if (!headingFormattersEnabled) {
        return;
    }

    const project = getState().govProjects.get('selectedProject').toJS();

    try {
        const result = await client.get(
            `/project/${project.id}/government/docx-heading-formatters`
        );

        dispatch({ type: LOAD_DOCX_HEADING_FORMATTERS_SUCCESS, payload: result });
    } catch (e) {} // eslint-disable-line
};

export function exportProject() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });

        return loadHeadingFormatters(dispatch, getState, client)
            .then(() => {
                const project = getState().govProjects.get('selectedProject').toJS();

                const html = renderProjectStaticMarkup(
                    project,
                    { isDocx: true },
                    { includeSignatures: true }
                );
                const tableOfContents = renderTableOfContentsStaticMarkup(project);
                const timelineHtml = renderProjectTimelineStaticMarkup(project);
                const data = { html, tableOfContents, timelineHtml };

                return client.post(`/project/${project.id}/export`, { data });
            })
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, ...result });
                const { zipFilename, zipUrl } = result;

                const state = getState();
                const { downloadImmediately } = state.exportProject.get('exportData').toJS();

                if (downloadImmediately) {
                    const link = document.createElement('a');
                    link.setAttribute('href', zipUrl);
                    link.setAttribute('download', zipFilename);
                    document.body.appendChild(link); // Required for FF

                    link.click();
                    link.remove();
                }
            })
            .catch((error) => {
                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export function exportProjectDocument() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_DOCUMENT });

        return loadHeadingFormatters(dispatch, getState, client)
            .then(() => {
                const state = getState();
                const exportData = state.exportProject.get('exportData').toJS();
                const isPreview = state.exportProject.get('showModal') === 'preview';
                const sectionId = state.exportProject.get('sectionId');
                const project = state.govProjects.get('selectedProject').toJS();

                const html = renderProjectStaticMarkup(
                    project,
                    { isDocx: true },
                    { sectionId, includeSignatures: !sectionId }
                );
                const tableOfContents = renderTableOfContentsStaticMarkup(project);
                const timelineHtml = renderProjectTimelineStaticMarkup(project);
                const data = {
                    exportPdf: exportData.isPdf,
                    exportGoogleDrive: exportData.isGoogleDrive,
                    html,
                    sectionId,
                    tableOfContents,
                    timelineHtml,
                    useSignedUrl: !(isPreview && !exportData.isPdf),
                };

                return client.post(`/project/${project.id}/exportDocument`, { data });
            })
            .then((result) => {
                const { googleUrl, filename, wordUrl, pdfUrl } = result;
                dispatch({
                    type: LOAD_DOCUMENT_SUCCESS,
                    googleUrl,
                    wordUrl,
                    filename,
                    pdfUrl,
                });

                const state = getState();
                const { downloadImmediately } = state.exportProject.get('exportData').toJS();

                if (downloadImmediately) {
                    const link = document.createElement('a');
                    link.setAttribute('href', result.pdfUrl || result.wordUrl);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link); // Required for FF

                    link.click();
                    link.remove();
                }
            })
            .catch((error) => {
                dispatch({ type: LOAD_DOCUMENT_FAIL, error });
            });
    };
}

export function setExportData(data) {
    return { type: SET_EXPORT_DATA, data };
}

/*
 * Allows configuration of the export without showing the modal
 */
export function configureExport(exportData, sectionId) {
    return { type: CONFIGURE_EXPORT, exportData, sectionId };
}

export function compileDocument(projectId, downloadImmediately, projectDeliveryMethod) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_DOCUMENT });

        return client
            .post(`/project/${projectId}/compile`, { data: { projectDeliveryMethod } })
            .then((result) => {
                const { filename, url } = result;
                dispatch({
                    type: LOAD_DOCUMENT_SUCCESS,
                    pdfUrl: url,
                    googleUrl: url,
                    filename,
                });
                if (downloadImmediately) {
                    downloadFile(url, filename);
                }
            })
            .catch((error) => {
                dispatch(showSnackbar('Error compiling document.', { isError: true }));
                dispatch({ type: LOAD_DOCUMENT_FAIL, error });

                return error;
            });
    };
}
