import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import {
    Edit as EditIcon,
    Person as PersonIcon,
    Description as DescriptionIcon,
} from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiDrawPen } from '@mdi/js';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

const { colors } = capitalDesignTokens.foundations;

const Wrapper = ({ children }) => {
    return (
        <Box
            sx={{
                backgroundColor: `${colors.cerulean200}CC`,
                borderRadius: 1,
                opacity: 0.8,
                pb: 0.2,
                pt: 0.8,
                px: 0.8,
            }}
        >
            {children}
        </Box>
    );
};
Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export const Sign = () => {
    return (
        <Wrapper>
            <Icon color={colors.cerulean800} path={mdiDrawPen} size={1} />
        </Wrapper>
    );
};

export const Pencil = () => {
    return (
        <Wrapper>
            <EditIcon sx={{ color: colors.cerulean800 }} />
        </Wrapper>
    );
};

export const Person = () => {
    return (
        <Wrapper>
            <PersonIcon sx={{ color: colors.cerulean800 }} />
        </Wrapper>
    );
};

export const File = () => {
    return (
        <Wrapper>
            <DescriptionIcon sx={{ color: colors.cerulean800 }} />
        </Wrapper>
    );
};
