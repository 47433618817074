import PropTypes from 'prop-types';
import React from 'react';
import {
    ArrowDropDown as ArrowDropDownIcon,
    Check as CheckIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import {
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Button,
    Box,
} from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { getContractAttachmentTagSelectOptions } from '../../../selectors';

export const DocumentTypesMenu = ({ handleToggle, selected, onClearAll }) => {
    const attachmentTags = useSelector(getContractAttachmentTagSelectOptions);
    const {
        typography: { fontSize, fontWeight },
        colors,
    } = capitalDesignTokens.foundations;
    return (
        <DropdownMenu
            endIcon={<ArrowDropDownIcon color={colors.gray800} />}
            keepMenuOpenOnClick
            label="Document Types"
            onClick={(e) => {
                e.stopPropagation();
            }}
            sx={{
                color: colors.gray800,
                borderColor: colors.gray800,
            }}
        >
            <Box sx={{ maxHeight: '320px !important' }}>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                    <Box alignItems="center" display="flex">
                        <Typography
                            sx={{
                                fontSize: `${fontSize.bodyDefault} !important`,
                                color: colors.gray800,
                                fontWeight: 'bold',
                                px: 1,
                                py: 1,
                            }}
                        >
                            Filters
                        </Typography>
                        {selected.length > 0 && ( // counter of selected filters
                            <Typography
                                sx={{
                                    fontSize: `${fontSize.bodyDefault} !important`,
                                    backgroundColor: colors.gray100,
                                    borderRadius: 0.5,
                                    px: 0.5,
                                    color: colors.gray800,
                                    height: 'fit-content',
                                }}
                            >
                                {selected.length}
                            </Typography>
                        )}
                    </Box>

                    {selected.length > 0 && (
                        <Button
                            onClick={onClearAll}
                            startIcon={<CloseIcon fontSize="small" />}
                            sx={{
                                fontWeight: fontWeight.medium,
                                fontSize: fontSize.bodyDefault,
                            }}
                        >
                            Clear All
                        </Button>
                    )}
                </Box>
                {attachmentTags.map((attachmentType) => (
                    <ListItem disablePadding key={attachmentType.value}>
                        <ListItemButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleToggle(attachmentType.value);
                            }}
                            role={undefined}
                        >
                            <ListItemIcon>
                                {selected.indexOf(attachmentType.value) !== -1 && <CheckIcon />}
                            </ListItemIcon>
                            <ListItemText
                                id={`attachmentType-list-label-${attachmentType.value}`}
                                primary={attachmentType.label}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </Box>
        </DropdownMenu>
    );
};

DocumentTypesMenu.propTypes = {
    handleToggle: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    onClearAll: PropTypes.func.isRequired,
};
