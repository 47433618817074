import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { basePageStyles } from '../../../../styles';

export const pageContainerStyles = {
    ...basePageStyles,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 0,
    paddingRight: {
        xs: 0,
        sm: `calc(${capitalDesignTokens.foundations.units.unit7}px - 15px)`,
    },
    paddingLeft: {
        xs: 0,
        sm: `calc(${capitalDesignTokens.foundations.units.unit7}px - 15px)`,
    },
};

export const pageBodyStyles = {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: {
        xs: 'calc(100% + 62px)', // 62px is the width of the 2 margins that are added to the pageContainer
        sm: '100%',
    },
    background: capitalDesignTokens.foundations.colors.white,
    borderRadius: {
        xs: 0,
        sm: 1,
    },
    paddingTop: 4,
    paddingBottom: 4,
    border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
    marginLeft: {
        xs: '-31px',
        sm: 0,
    },
    marginRight: {
        xs: '-31px',
        sm: 0,
    },
};

export const pageSectionContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};
