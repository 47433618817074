import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { ReduxFormRadioGroup, Box, Typography } from '@og-pro/ui';

import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import {
    fieldNames,
    form,
    isDescriptionRequiredOptions,
    showAccountInformationOptions,
    useBudgetGroupOptions,
    usePreEncumbranceOptions,
} from './constants';

import { validate } from './validate';
import { getIsGovernmentUsingFMS } from '../../../../../selectors/govApp';

const { IS_DESCRIPTION_REQUIRED, USE_BUDGET_GROUP, USE_PRE_ENCUMBRANCE, SHOW_ACCOUNT_INFORMATION } =
    fieldNames;

const formConfig = {
    form,
    validate,
};

const ConnectedRequisitionsSettingsForm = ({ handleSubmit, updateError, updating }) => {
    const hasFMS = useSelector(getIsGovernmentUsingFMS);
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    return (
        <form onSubmit={handleSubmit}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >
                {hasFMS && (
                    <>
                        <Box
                            sx={{
                                mb: 2,
                            }}
                        >
                            <Typography variant="h3">Budget Settings</Typography>
                        </Box>
                        <Field
                            component={ReduxFormRadioGroup}
                            description="Should pre-encumbered funds from existing requests be included when performing budget checks?"
                            disabled={updating}
                            label="Pre-Encumberances in Budget Check"
                            name={USE_PRE_ENCUMBRANCE}
                            options={usePreEncumbranceOptions}
                            row
                        />

                        <Field
                            component={ReduxFormRadioGroup}
                            description="Should the budget check be done on accounts or groups?"
                            disabled={updating}
                            label="Default Budget Level"
                            name={USE_BUDGET_GROUP}
                            options={useBudgetGroupOptions}
                            row
                        />
                    </>
                )}
                {!enableCustomFields && (
                    <>
                        <Field
                            component={ReduxFormRadioGroup}
                            defaultSelected
                            description="Specifies whether to require descriptions when creating requests"
                            disabled={updating}
                            label="Request Name"
                            name={IS_DESCRIPTION_REQUIRED}
                            options={isDescriptionRequiredOptions}
                            row
                        />

                        {!hasFMS && (
                            <Field
                                component={ReduxFormRadioGroup}
                                defaultSelected
                                description="Determines whether to require account information, allow to put account information or just don't allow to add account information on price items"
                                disabled={updating}
                                label="Show Account Information"
                                name={SHOW_ACCOUNT_INFORMATION}
                                options={showAccountInformationOptions}
                                row
                            />
                        )}
                    </>
                )}
            </Box>
            <div className="text-center">
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        </form>
    );
};

ConnectedRequisitionsSettingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    updateError: PropTypes.string,
    updating: PropTypes.bool,
};

export const RequisitionsSettingsForm = reduxForm(formConfig)(ConnectedRequisitionsSettingsForm);
