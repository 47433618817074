import React from 'react';
import { Button, Dialog, Box } from '@og-pro/ui';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { govUpdateProject } from '../../../../actions/govProjects';
import { limitTextLength } from '../../../../Forms/normalizers';
import { InputText } from '../../../../components';
import { contractDocumentsFieldNames } from '../constants';

const { INTERNAL_NOTES } = contractDocumentsFieldNames;

const ConnectedContractDocumentNotesModal = reduxForm({
    form: 'contractDocumentsInternalNotes',
    validate: (values) => {
        const errors = {};
        if (values[INTERNAL_NOTES]?.length > 1000) {
            errors[INTERNAL_NOTES] = 'Notes can not be more than 1000 characters long';
        }

        return errors;
    },
})(({ handleClose, handleSubmit, submitting }) => {
    return (
        <Dialog
            allowOverflowedContent
            dialogTitle="Edit Assembled Document"
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            open
            scroll="body"
        >
            <Box>
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Field
                            component={InputText}
                            disabled={submitting}
                            hasFeedback={false}
                            label="Notes"
                            minRows={3}
                            name={INTERNAL_NOTES}
                            normalize={limitTextLength(1200)}
                            qaTag="projectInformationForm-internalNotes"
                            showValidation
                            type="textarea"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" sx={{ pt: 3 }}>
                        <Box sx={{ mr: 1 }}>
                            <Button
                                color="secondary"
                                disabled={submitting}
                                onClick={handleClose}
                                qaTag="contractDocument-internalNotesCancel"
                                type="button"
                                variant="text"
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="primary"
                                disabled={submitting}
                                qaTag="contractDocument-internalNotesSave"
                                type="submit"
                                variant="contained"
                            >
                                {submitting ? 'Saving...' : 'Save'}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Dialog>
    );
});

export const ContractDocumentNotesModal = ({ afterSubmit, contractDocument, handleClose }) => {
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        await dispatch(govUpdateProject(contractDocument.id, values));

        afterSubmit();
        handleClose();
    };

    if (!contractDocument) {
        return null;
    }

    return (
        <ConnectedContractDocumentNotesModal
            handleClose={handleClose}
            initialValues={{ [INTERNAL_NOTES]: contractDocument[INTERNAL_NOTES] }}
            onSubmit={onSubmit}
        />
    );
};

ContractDocumentNotesModal.propTypes = {
    afterSubmit: PropTypes.func.isRequired,
    contractDocument: PropTypes.shape({
        id: PropTypes.number.isRequired,
        internalNotes: PropTypes.string.isRequired,
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
};
