import React from 'react';
import { Outlet } from 'react-router-dom';

import { IntegrationRedirect } from '../../containers';
import { AccountDetailsPublicRoute } from '../../containers/Requisitions/AccountDetails/Public/AccountDetailsPublicRoute';
import * as Public from '../../containers/PublicApp';
import ProjectRoutes from './Project';
import { PublicContractsList, PublicContract } from './Contract';
import {
    legacyFetchData,
    legacyFetchDataLowercaseGov,
    redirectToLowercasePortal,
    requireOpenGovUser,
    redirectFromOldProjectPathToPortal,
    requireAnyGovUser,
} from '../loaders';
import requireDisplayPublicVendorInformation from '../loaders/requireDisplayPublicVendorInformation';

const PublicPortal = (getState, dispatch) => [
    {
        element: <Public.App />,
        children: [
            {
                element: <Public.GovList />,
                loader: async (props) => {
                    const permissionRedirect = await requireOpenGovUser(getState)(props);

                    if (permissionRedirect) {
                        return permissionRedirect;
                    }

                    return legacyFetchData(getState, dispatch, Public.GovList)(props);
                },
                path: '/portal',
            },
            {
                element: <Outlet />,
                loader: redirectToLowercasePortal,
                path: '/portal/embed/:governmentCode',
                children: [
                    {
                        element: <Public.GovPortal />,
                        loader: legacyFetchData(getState, dispatch, Public.GovPortal),
                        children: [
                            {
                                element: <Public.GovPortalMain />,
                                children: [
                                    {
                                        element: <Public.GovProjectsList />,
                                        loader: legacyFetchDataLowercaseGov(
                                            getState,
                                            dispatch,
                                            Public.GovProjectsList
                                        ),
                                        path: 'project-list',
                                    },
                                    {
                                        element: <Public.GovPortalCalendar />,
                                        loader: legacyFetchDataLowercaseGov(
                                            getState,
                                            dispatch,
                                            Public.GovPortalCalendar
                                        ),
                                        path: 'calendar',
                                    },
                                    {
                                        element: <Public.GovVendorList />,
                                        loader: requireDisplayPublicVendorInformation(
                                            getState,
                                            dispatch
                                        ),
                                        path: 'vendors',
                                    },
                                ],
                            },
                        ],
                    },
                    ...PublicContractsList(getState, dispatch),
                ],
            },
            {
                element: <Outlet />,
                loader: redirectToLowercasePortal,
                path: '/portal/:governmentCode',
                children: [
                    {
                        element: <Public.GovPortal />,
                        loader: legacyFetchData(getState, dispatch, Public.GovPortal),
                        children: [
                            {
                                element: <Public.GovPortalNav />,
                                children: [
                                    {
                                        element: <Public.GovPortalMain />,
                                        children: [
                                            {
                                                element: <Public.GovProjectsList />,
                                                index: true,
                                                loader: legacyFetchDataLowercaseGov(
                                                    getState,
                                                    dispatch,
                                                    Public.GovProjectsList
                                                ),
                                            },
                                            {
                                                element: <Public.GovPortalCalendar />,
                                                loader: legacyFetchDataLowercaseGov(
                                                    getState,
                                                    dispatch,
                                                    Public.GovPortalCalendar
                                                ),
                                                path: 'calendar',
                                            },
                                            {
                                                element: <Public.GovVendorList />,
                                                loader: requireDisplayPublicVendorInformation(
                                                    getState,
                                                    dispatch
                                                ),
                                                path: 'vendors',
                                            },
                                        ], // end GovPortalMain children
                                    },
                                    ...PublicContractsList(getState, dispatch),
                                ], // end GovPortalNav children
                            },
                            {
                                element: <Public.VendorPublicProfile />,
                                loader: requireDisplayPublicVendorInformation(
                                    getState,
                                    dispatch,
                                    true
                                ),
                                path: 'vendors/:vendorCode',
                            },
                            {
                                element: <Public.ProjectNav />,
                                loader: legacyFetchData(getState, dispatch, Public.ProjectNav),
                                path: 'projects/:projectId',
                                children: [
                                    ...ProjectRoutes(getState, dispatch),
                                    ...PublicContractsList(getState, dispatch),
                                    ...PublicContract(getState, dispatch),
                                ], // end Public.ProjectNav children
                            },
                            ...PublicContract(getState, dispatch),
                        ], // end GovPortal children
                    },
                ], // end RedirectToLowercasePortal children
            },
            {
                element: <Outlet />,
                loader: redirectFromOldProjectPathToPortal(getState, dispatch),
                path: '/projects/:projectId',
            },
            {
                element: <IntegrationRedirect />,
                loader: requireAnyGovUser(getState, dispatch),
                path: '/integration',
            },
            {
                element: <AccountDetailsPublicRoute />,
                loader: requireAnyGovUser(getState, dispatch),
                path: '/accounts/:ogEntityId/:accountNumber/:fiscalPeriod',
            },
        ], // end PublicApp children
    },
];

export default PublicPortal;
