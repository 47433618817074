import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUsersById } from '../selectors';
import { UserProfileList } from '../../../../../components';

export const RequestTypeGroupSequences = ({ positions, requestTypeGroupSequences }) => {
    const getUserById = useSelector(getUsersById);
    const requestTypeGroupSequencesInUse = useMemo(
        () =>
            requestTypeGroupSequences.filter(({ request_type_id: requestTypeId }) => requestTypeId),
        [requestTypeGroupSequences]
    );

    if (requestTypeGroupSequencesInUse.length === 0) {
        return 'No request types selected';
    }

    const styles = require('./shared.scss');

    return (
        <div>
            <i className="fa fa-fw fa-code-fork" /> Request Types (
            {requestTypeGroupSequencesInUse.length})
            {positions.map(({ position, reviewGroupPositionUsers }) => {
                const users = reviewGroupPositionUsers
                    .map((reviewGroupPositionUser) => getUserById[reviewGroupPositionUser.user_id])
                    .filter((user) => user);
                return (
                    <div className={styles.reviewGroupPositionUsersList} key={position.id}>
                        <i className="fa fa-fw fa-user-plus" /> {position.name} (
                        {reviewGroupPositionUsers.length})&nbsp;&nbsp;&nbsp;
                        <UserProfileList className={styles.userProfileList} users={users} />
                    </div>
                );
            })}
        </div>
    );
};

RequestTypeGroupSequences.propTypes = {
    positions: PropTypes.array.isRequired,
    requestTypeGroupSequences: PropTypes.array.isRequired,
};
