import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { useGetReviewSequenceOptions } from './hooks';
import { requestTypeGroupSequenceFieldNames } from '../constants';
import { Button, SearchSelect } from '../../../../../../components';

const { REQUEST_TYPE_ID, REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;

export const RequestTypeGroupSequences = ({
    change,
    disabled,
    field,
    requestTypeId,
    requestTypeOptions,
    removeHandler,
    showRemoveButton,
    showFormValidation,
}) => {
    const reviewSequenceOptions = useGetReviewSequenceOptions(requestTypeId);

    const clearReviewSequenceOnRequestTypeChange = () => {
        change(`${field}.${REVIEW_SEQUENCE_ID}`, null);
    };

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    label="Request Type"
                    name={`${field}.${REQUEST_TYPE_ID}`}
                    onChange={clearReviewSequenceOnRequestTypeChange}
                    options={requestTypeOptions}
                    placeholder="Select request type"
                    showValidation={showFormValidation}
                />
            </Box>
            <Box sx={{ flex: 1 }}>
                <Field
                    component={SearchSelect}
                    disabled={reviewSequenceOptions.length === 0 || disabled}
                    key={requestTypeId}
                    label="Approval Sequence"
                    name={`${field}.${REVIEW_SEQUENCE_ID}`}
                    options={reviewSequenceOptions}
                    placeholder="Select approval sequence"
                    showValidation={showFormValidation}
                />
            </Box>
            {showRemoveButton && (
                <Box sx={{ paddingTop: 3.5 }}>
                    <Button
                        bsSize="small"
                        bsStyle="link"
                        onClick={removeHandler}
                        qaTag="requisitionGroupEdit-remove"
                        zeroPadding
                    >
                        <span className="text-danger">
                            <i className="fa fa-trash" /> Remove
                        </span>
                    </Button>
                </Box>
            )}
        </Box>
    );
};

RequestTypeGroupSequences.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    removeHandler: PropTypes.func.isRequired,
    requestTypeId: PropTypes.number,
    requestTypeOptions: PropTypes.array.isRequired,
    showFormValidation: PropTypes.bool,
    showRemoveButton: PropTypes.bool,
};
