import React from 'react';

import { ReduxFormCheckboxGroup } from '@og-pro/ui';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { ENUM_OPTIONS, NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const CheckboxPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];

    const checkBoxArray = customFieldDefinition[ENUM_OPTIONS];
    const checkBoxOptions = checkBoxArray.map((option) => {
        if (option === '') {
            return {
                label: 'Checkbox text goes here.',
                value: 'Checkbox text goes here.',
            };
        }

        return {
            label: option,
            value: option,
        };
    });

    return (
        <Field
            component={ReduxFormCheckboxGroup}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="checkbox_PREVIEW"
            options={checkBoxOptions}
            sx={{
                width: '100%',
            }}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

CheckboxPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
