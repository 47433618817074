import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactToggle from 'react-toggle';

import { contractStatusesDict } from '@og-pro/shared-config/contracts';

import { Button, ButtonGroup, ContractRetentionPolicyButton } from '..';
import { contractStatusIcon, contractStatusText } from '../helpers/statusHelper';
import { contractMenuActionHandler, showPermissionsModal } from '../../actions/contracts';
import { showVendorProfileModal } from '../../actions/vendorProfile';
import { COPY_CONTRACT } from '../../constants/menuActions';

const { ENDED } = contractStatusesDict;

const mapDispatchToProps = {
    contractMenuActionHandler,
    showPermissionsModal,
    showVendorProfileModal,
};

// @connect
class ConnectedContractHeader extends PureComponent {
    static propTypes = {
        contract: PropTypes.shape({
            contractParty: PropTypes.shape({
                companyName: PropTypes.string.isRequired,
                vendor: PropTypes.shape({
                    id: PropTypes.number,
                }),
            }).isRequired,
            departmentName: PropTypes.string.isRequired,
            endDate: PropTypes.string,
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    logo: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    timezone: PropTypes.string.isRequired,
                    timezoneReadable: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            isPublic: PropTypes.bool,
            note: PropTypes.string,
            retention_code_id: PropTypes.number,
            status: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        contractMenuActionHandler: PropTypes.func.isRequired,
        handlePublicChange: PropTypes.func,
        isContainedByProject: PropTypes.bool,
        isContractAdmin: PropTypes.bool,
        isEditor: PropTypes.bool,
        isPublicView: PropTypes.bool,
        isRetentionAdmin: PropTypes.bool.isRequired,
        showPermissionsModal: PropTypes.func.isRequired,
        showVendorProfileModal: PropTypes.func.isRequired,
        updatingContract: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    handleInviteClick = () => {
        this.props.showPermissionsModal();
    };

    handleVendorNameClick = () => {
        const {
            contract: {
                contractParty: {
                    vendor: { id: vendorId },
                },
            },
        } = this.props;
        this.props.showVendorProfileModal(vendorId);
    };

    renderActionButtons() {
        const { contract, isContractAdmin, isEditor, updatingContract } = this.props;

        const buttons = [];
        if (isContractAdmin) {
            buttons.push(
                <Button
                    bsSize="sm"
                    disabled={updatingContract}
                    onClick={() => this.props.contractMenuActionHandler(COPY_CONTRACT, contract)}
                    qaTag="connectedContractHeader-copy"
                >
                    <i className="fa fa-copy" /> Copy
                </Button>
            );
        }
        if (isEditor) {
            buttons.push(
                <Button
                    bsSize="sm"
                    disabled={updatingContract}
                    onClick={this.handleInviteClick}
                    qaTag="connectedContractHeader-invite"
                >
                    <i className="fa fa-user-plus" /> Invite
                </Button>
            );
        }
        return <ButtonGroup block={false}>{buttons}</ButtonGroup>;
    }

    renderPublicToggle() {
        const {
            contract: { isPublic },
            handlePublicChange,
            isEditor,
            updatingContract,
        } = this.props;

        if (!isEditor) {
            return null;
        }

        return (
            <>
                <div className={this.styles.publicText}>Public</div>
                <ReactToggle
                    aria-label="Toggle public contract status"
                    checked={isPublic}
                    data-qa="connectedContractHeader-toggle-public"
                    disabled={updatingContract}
                    onChange={handlePublicChange}
                />
            </>
        );
    }

    renderSimpleHeader() {
        const {
            contract: { title },
            isContractAdmin,
            isEditor,
        } = this.props;

        const showActionButtons = isEditor || isContractAdmin;

        return (
            <div className={this.styles.container}>
                <h3 className="text-center">{title}</h3>
                {showActionButtons && (
                    <div className={`row ${this.styles.simpleControlsContainer}`}>
                        <div className="col-xs-6 text-left">{this.renderActionButtons()}</div>
                        <div className="col-xs-6 text-right">{this.renderPublicToggle()}</div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            contract: {
                departmentName,
                contractParty: { companyName },
                endDate,
                government: {
                    organization: { logo, name, timezone, timezoneReadable },
                },
                retention_code_id: retentionCodeId,
                status,
                title,
            },
            isContainedByProject,
            isContractAdmin,
            isEditor,
            isPublicView,
            isRetentionAdmin,
        } = this.props;

        if (isContainedByProject) {
            return this.renderSimpleHeader();
        }

        const showRetentionPolicyButton = status === ENDED && isRetentionAdmin;
        const showActionButtons = isEditor || isContractAdmin;

        return (
            <div className={this.styles.container}>
                {showActionButtons && (
                    <div className={this.styles.controlsContainer}>
                        {isEditor && (
                            <div className={this.styles.publicButton}>
                                {this.renderPublicToggle()}
                            </div>
                        )}
                        <div>{this.renderActionButtons()}</div>
                    </div>
                )}
                <div className={this.styles.logoContainer}>
                    <img
                        alt={`${name} logo`}
                        className={`img-rounded ${this.styles.logo}`}
                        src={logo}
                    />
                </div>
                <h2 className={this.styles.titleText}>{title}</h2>
                <div className={this.styles.cityText}>
                    {name} &middot; {departmentName}
                </div>
                <div>
                    {this.props.contract.contractParty.vendor && !isPublicView ? (
                        <span className={this.styles.vendorText}>
                            Vendor:{' '}
                            <a className="pseudoLink" onClick={this.handleVendorNameClick}>
                                {companyName}
                            </a>
                        </span>
                    ) : (
                        <span className={this.styles.vendorText}>
                            Vendor: {companyName || 'N/A'}
                        </span>
                    )}
                </div>
                <div className={this.styles.statusText}>
                    <strong className={contractStatusText(status)}>
                        <i className={`fa fa-${contractStatusIcon(status)}`} />
                        &nbsp;
                        {status.toUpperCase()}
                    </strong>
                    &nbsp;
                    {endDate && (
                        <span>Contract End Date: {moment.tz(endDate, timezone).format('ll')}</span>
                    )}
                    {showRetentionPolicyButton && (
                        <span className={this.styles.retentionPolicyButton}>
                            <ContractRetentionPolicyButton retentionCodeId={retentionCodeId} />
                        </span>
                    )}
                    <div className={this.styles.timezoneText}>
                        <em>All dates in {timezoneReadable}</em>
                    </div>
                </div>
                <div className="clearfix" />
            </div>
        );
    }
}

export const ContractHeader = connect(null, mapDispatchToProps)(ConnectedContractHeader);
