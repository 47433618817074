import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DisplayVendorCard } from './DisplayVendorCard';
import { useGetRequisitionVendorData } from './hooks';
import {
    getRequisitionCurrentStep,
    getRequisitionEndsInPurchaseOrder,
} from '../../../../../../../selectors/govApp';

export const DisplayVendors = ({ selectedVendors }) => {
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);
    const currentStep = useSelector(getRequisitionCurrentStep);

    const { contractIds, loading, vendorIds } = useGetRequisitionVendorData({
        vendors: selectedVendors,
    });

    return selectedVendors.map((vendor, index) => {
        const proVendor = vendorIds?.[vendor.pro_vendor_id];
        return (
            <DisplayVendorCard
                contract={contractIds?.[vendor.contract_id]}
                endsInPurchaseOrder={endsInPurchaseOrder}
                hasMultipleVendors={selectedVendors.length > 1}
                index={index}
                isPoCreationStep={currentStep?.isPoCreationStep}
                key={vendor.vendorId}
                loadingVendorData={loading}
                proUser={proVendor?.organization.users.find(({ id }) => id === vendor.pro_user_id)}
                proVendor={proVendor}
                vendor={vendor}
            />
        );
    });
};

DisplayVendors.propTypes = {
    selectedVendors: PropTypes.array.isRequired,
};
