import React from 'react';
import PropTypes from 'prop-types';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { Box } from '@og-pro/ui';

export const ExhibitsListDocx = ({ project }) => {
    const attachments = project?.attachments.filter(
        (a) => a.type === attachmentTypesDict.OTHER && !a.isHiddenByLogic
    );

    if (!attachments.length) {
        return null;
    }

    return (
        <Box>
            <Box mb={2}>
                <h2>Exhibits List</h2>
            </Box>

            {attachments.map((attachment, i) => {
                return (
                    <Box key={i} ml={2}>
                        <div>{attachment.name}</div>
                    </Box>
                );
            })}
        </Box>
    );
};

ExhibitsListDocx.propTypes = {
    project: PropTypes.shape({
        attachments: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                name: PropTypes.string,
            })
        ),
    }),
};
