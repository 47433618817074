import React, { useEffect, useRef } from 'react';
import { Panel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { reverseAuctionSocket } from '../../../../lib/sockets';
import { ReverseAuctionNav } from '../ReverseAuctionNav';
import { getProjectJS } from '../../selectors';
import { getShowConnectionAlert } from '../../../selectors';
import connectData from '../../../ConnectData';
import { loadGovReverseAuctionPriceItems } from '../../../../actions/reverseAuctions';
import { joinReverseAuction, leaveReverseAuction } from '../../../../actions/reverseAuctionSocket';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../../../actions/notification';
import { LoadingError, LoadingSpinner, NotFound } from '../../../../components';
import { loadQuestions } from '../../../../actions/questions';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(loadGovReverseAuctionPriceItems(projectId)),
        dispatch(loadQuestions(projectId, true)),
    ]);
}

const ConnectedReverseAuctionContainer = () => {
    const location = useLocation();
    const params = useParams();

    const loading = useSelector((state) =>
        state.reverseAuctions.get('loadingReverseAuctionPriceItems')
    );
    const loadError = useSelector((state) =>
        state.reverseAuctions.get('loadReverseAuctionPriceItemsError')
    );
    const shouldShowConnectionAlert = useSelector((state) => getShowConnectionAlert(state));
    const shouldShowConnectionAlertRef = useRef(shouldShowConnectionAlert);

    const styles = require('./index.scss');

    const dispatch = useDispatch();
    const project = useSelector((state) => getProjectJS(state));

    useEffect(() => {
        shouldShowConnectionAlertRef.current = shouldShowConnectionAlert;
    }, [shouldShowConnectionAlert]);

    useEffect(() => {
        reverseAuctionSocket.connect();

        const reconnectHandler = () => {
            if (shouldShowConnectionAlertRef.current) {
                dispatch(reconnectionAlert());
            }

            dispatch(joinReverseAuction(project.id));
            dispatch(loadGovReverseAuctionPriceItems(project.id));
        };

        const connectErrorHandler = () => {
            if (!shouldShowConnectionAlertRef.current) {
                dispatch(showConnectionAlert());
            }
        };

        reverseAuctionSocket.io.on('reconnect', reconnectHandler);
        reverseAuctionSocket.on('connect_error', connectErrorHandler);
        dispatch(joinReverseAuction(project.id));
        return () => {
            if (shouldShowConnectionAlertRef.current) {
                dispatch(hideConnectionAlert());
            }

            reverseAuctionSocket.io.off('reconnect', reconnectHandler);
            reverseAuctionSocket.off('connect_error', connectErrorHandler);
            dispatch(leaveReverseAuction(project.id));
            reverseAuctionSocket.disconnect();
        };
    }, []);

    if (!project.template.isReverseAuction) return <NotFound />;
    if (loading) return <LoadingSpinner />;
    if (loadError || !project) return <LoadingError error={loadError} />;

    return (
        <div className={styles.container}>
            <ReverseAuctionNav location={location} params={params} />
            <Panel className={styles.tabSection}>
                <Panel.Body>
                    <Outlet />
                </Panel.Body>
            </Panel>
        </div>
    );
};

export const ReverseAuctionContainer = connectData(fetchData)(ConnectedReverseAuctionContainer);
