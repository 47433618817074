import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isRequisitionClosed } from '@og-pro/shared-config/requisitions';

import { FormMenu } from './FormMenu';
import { getIsCurrentApprover, getIsFullyEditable } from './selectors';
import {
    additionalInformationFields,
    attachmentsFields,
    customFormSectionFields,
    generalInformationFields,
    purchaseDetailsFields,
    vendorSelectionFields,
} from '../../../RequisitionsCreate/RequisitionsCreateForms/constants';
import { withRequisitionsCreateForm } from '../../../RequisitionsCreate/RequisitionsCreateForms/RequisitionsCreateFormHOC';
import { ApprovalReviewAndEdit } from '../../../RequisitionsCreate/RequisitionsCreateForms/ApprovalReviewAndEdit';
import {
    saveAdditionalSection,
    saveAttachmentsSection,
    saveCustomFormSection,
    saveGeneralSection,
    savePricingSection,
    saveVendorSection,
} from '../../../../../actions/requisitions';
import {
    setSectionDisabled,
    setSectionEditable,
    setSectionEnabled,
    setSectionUneditable,
} from '../../../../../actions/requisitionsCreate';
import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';
import { getRequisitionCurrentStep } from '../../../../../selectors/govApp';
import { CustomFormSnapshotContext } from '../../../RequisitionsCreate/RequisitionsCreateForms/CustomFormSnapshotContext';

const ConnectedRequisitionEditForm = ({
    change,
    disabled,
    handleSubmit,
    invalid,
    pristine,
    requisition,
    reset,
    submitFailed,
}) => {
    const [editingSectionType, setEditingSectionType] = useState(null);
    const dispatch = useDispatch();

    const currentStep = useSelector(getRequisitionCurrentStep);
    const isFullyEditable = useSelector(getIsFullyEditable);
    const isCurrentApprover = useSelector(getIsCurrentApprover);

    const customFormSnapshot = useContext(CustomFormSnapshotContext);

    useEffect(() => {
        if (requisition.flagResponses.length === 0) {
            dispatch(setSectionDisabled(ADDITIONAL_INFORMATION));
        }
    }, [requisition.flagResponses.length]);

    useEffect(() => {
        if (customFormSnapshot.fields.length === 0) {
            dispatch(setSectionDisabled(CUSTOM_FORM));
        } else {
            dispatch(setSectionEnabled(CUSTOM_FORM));
        }
    }, [customFormSnapshot]);

    const setSectionEditableFn = (sectionType, reviewerWithPermission) => {
        const isClosed = isRequisitionClosed(requisition.status);
        if (isClosed || requisition.isOnHold) {
            return dispatch(setSectionUneditable(sectionType));
        }
        if (isFullyEditable) {
            return dispatch(setSectionEditable(sectionType));
        }
        if (isCurrentApprover && reviewerWithPermission) {
            return dispatch(setSectionEditable(sectionType));
        }
        return dispatch(setSectionUneditable(sectionType));
    };

    useEffect(() => {
        setSectionEditableFn(ADDITIONAL_INFORMATION);
        setSectionEditableFn(GENERAL_INFORMATION, currentStep?.allowGeneralEdit);
        setSectionEditableFn(PURCHASE_DETAILS, currentStep?.allowPriceEdit);
        setSectionEditableFn(VENDOR_SELECTION, currentStep?.allowVendorEdit);
        setSectionEditableFn(CUSTOM_FORM, currentStep?.allowCustomFormEdit);
        setSectionEditableFn(ATTACHMENTS, currentStep?.allowGeneralEdit);
    }, [requisition, isFullyEditable, isCurrentApprover, currentStep]);

    const cancelHandler = () => {
        reset();
        setEditingSectionType(null);
    };

    const submitHandler = (data) => {
        const opts = { onSuccess: cancelHandler };

        switch (editingSectionType) {
            case ADDITIONAL_INFORMATION:
                return dispatch(
                    saveAdditionalSection(
                        requisition.id,
                        pick(data, additionalInformationFields),
                        opts
                    )
                );
            case CUSTOM_FORM:
                return dispatch(
                    saveCustomFormSection(requisition.id, pick(data, customFormSectionFields), opts)
                );
            case GENERAL_INFORMATION:
                return dispatch(
                    saveGeneralSection(requisition.id, pick(data, generalInformationFields), opts)
                );
            case PURCHASE_DETAILS:
                return dispatch(
                    savePricingSection(requisition.id, pick(data, purchaseDetailsFields), {
                        ...opts,
                        budgetCheckAfterSave: true,
                    })
                );
            case VENDOR_SELECTION:
                return dispatch(
                    saveVendorSection(requisition.id, pick(data, vendorSelectionFields), opts)
                );
            case ATTACHMENTS:
                return dispatch(
                    saveAttachmentsSection(requisition.id, pick(data, attachmentsFields), opts)
                );
            default:
                break;
        }
    };

    const vendorAssignmentModalSubmitHandler = (data) => {
        const opts = { budgetCheckAfterSave: true, onSuccess: cancelHandler };

        return dispatch(saveVendorSection(requisition.id, pick(data, vendorSelectionFields), opts));
    };

    const styles = require('./index.scss');

    return (
        <>
            <div className={styles.formContainer}>
                <ApprovalReviewAndEdit
                    cancelHandler={cancelHandler}
                    change={change}
                    disabled={disabled}
                    editHandler={(sectionType) => setEditingSectionType(sectionType)}
                    editingSectionType={editingSectionType}
                    vendorAssignmentModalSubmitHandler={handleSubmit(
                        vendorAssignmentModalSubmitHandler
                    )}
                />
            </div>
            {editingSectionType && (
                <FormMenu
                    cancelHandler={cancelHandler}
                    disabled={disabled}
                    pristine={pristine}
                    showSubmitFailed={submitFailed && invalid}
                    submitHandler={handleSubmit(submitHandler)}
                />
            )}
        </>
    );
};

ConnectedRequisitionEditForm.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    requisition: PropTypes.shape({
        flagResponses: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        isOnHold: PropTypes.bool,
        status: PropTypes.number.isRequired,
    }).isRequired,
    reset: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool.isRequired,
};

export const RequisitionEditForm = withRequisitionsCreateForm()(ConnectedRequisitionEditForm);
