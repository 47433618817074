import { useMemo, useState } from 'react';

export const useCustomFieldsFilters = ({ customFields }) => {
    const [searchValue, setSearchValue] = useState('');

    const filteredCustomFields = useMemo(
        () =>
            customFields.filter((customField) => {
                if (!searchValue) {
                    return true;
                }
                return (
                    customField.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    customField.shortTitle?.toLowerCase().includes(searchValue.toLowerCase())
                );
            }),
        [customFields, searchValue]
    );

    return {
        filteredCustomFields,
        searchValue,
        setSearchValue,
    };
};
