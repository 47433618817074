import React from 'react';
import { Box } from '@og-pro/ui';
import { useSelector } from 'react-redux';

import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { isInitialClientLoaded } from '../../selectors';
import { AssociatedAttachments } from './components/AssociatedAttachments';
import { AssembledAgreements } from './components/AssembledAgreements';
import PendingAttachments from './components/PendingAttachments';

export const ContractDocuments = () => {
    const isClientLoaded = useSelector(isInitialClientLoaded);
    const loading = useSelector((state) => !!state.contracts.get('loadingContract'));

    if (loading || !isClientLoaded) {
        return (
            <Box py={4}>
                <LoadingSpinner noPadding useOpenGovStyle />
            </Box>
        );
    }

    return (
        <Box>
            <Box mb={4}>
                <PendingAttachments />
            </Box>
            <Box mb={4}>
                <AssembledAgreements />
            </Box>
            <Box>
                <AssociatedAttachments />
            </Box>
        </Box>
    );
};
