import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropdownButton, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { NoSsr } from '@mui/material';

import { filterTypesDict } from '@og-pro/shared-config/filters';

import { DEFAULT_PAGE_SIZE_LIMIT } from '@og-pro/shared-config/contracts';
import classNames from 'classnames';

import {
    getLayoutFilter,
    getLoadContractOptions,
    getUserDefaultFilters,
    getUserDefaultFullWidth,
    getUserDefaultSort,
    getUserLastFilters,
} from './selectors';
import { parseRawQueryParams } from '../selectors';
import { PurchaseOrderImportModal } from './PurchaseOrderImportModal';
import { getUserJS, isContractAdminUser, isDepartmentContractAdminUser } from '../../selectors';
import { storeLastFilter, updateSettingsFilter } from '../../../actions/auth';
import {
    exportPurchaseOrders,
    loadContracts,
    resetLoadedContracts,
} from '../../../actions/contracts';
import { loadGovernmentRetentionCodes } from '../../../actions/retention';
import { getUserDocBuilderHomepage } from '../../../helpers';
import { showProjectCreateModal } from '../../../actions/project/create/projectCreate';
import {
    Button,
    ContractList,
    ContractListFilters,
    DashboardControlNav,
    Main,
    MenuItem,
    OutlineButton,
    PageTitle,
} from '../../../components';
import { CooperativeContractBar } from '../../../components/CooperativeContractBar';

import { parseContractsFilters } from './helpers';

const { CONTRACT, CONTRACT_TABLE_LAYOUT_SETTINGS } = filterTypesDict;

const mapStateToProps = (state, props) => {
    const user = getUserJS(state);

    return {
        docBuilderHomepage: getUserDocBuilderHomepage(user),
        canCreateContract: isContractAdminUser(state) || isDepartmentContractAdminUser(state),
        initialLoadedContractOptions: getLoadContractOptions(state, props.location.query),
        isContractAdmin: isContractAdminUser(state),
        layoutFilter: getLayoutFilter(state, props),
        user,
        userLastFilters: getUserLastFilters(state),
        userDefaultFilters: getUserDefaultFilters(state),
        userDefaultSort: getUserDefaultSort(state),
        userDefaultFullWidth: getUserDefaultFullWidth(state),
    };
};

const mapDispatchToProps = {
    exportPurchaseOrders,
    loadContracts,
    loadGovernmentRetentionCodes,
    resetLoadedContracts,
    showProjectCreateModal,
    storeLastFilter,
    updateSettingsFilter,
};

// @connectData
// @connect
// Note: Browser navigating to update the query params in the URL is done in src/app/actions/contracts.js
// This is non-standard, but it's the most reliable way to ensure the URL is up to date with the latest
// searching and filtering.
export class ConnectedGovernmentContractsListNav extends Component {
    static propTypes = {
        exportPurchaseOrders: PropTypes.func.isRequired,
        canCreateContract: PropTypes.bool.isRequired,
        initialLoadedContractOptions: PropTypes.object,
        isContractAdmin: PropTypes.bool.isRequired,
        layoutFilter: PropTypes.shape({
            data: PropTypes.shape({
                columnState: PropTypes.array,
                defaultFilters: PropTypes.array,
                defaultSorted: PropTypes.array,
                filterModel: PropTypes.object,
                fullWidth: PropTypes.bool,
                sortModel: PropTypes.array,
            }).isRequired,
            id: PropTypes.number.isRequired,
        }),
        docBuilderHomepage: PropTypes.string,
        loadContracts: PropTypes.func.isRequired,
        loadGovernmentRetentionCodes: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            query: PropTypes.shape({
                ids: PropTypes.string,
                limit: PropTypes.string,
            }),
        }),
        resetLoadedContracts: PropTypes.func.isRequired,
        showProjectCreateModal: PropTypes.func.isRequired,
        storeLastFilter: PropTypes.func.isRequired,
        updateSettingsFilter: PropTypes.func.isRequired,
        user: PropTypes.shape({
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
                hideContractSpend: PropTypes.bool.isRequired,
            }).isRequired,
            government_id: PropTypes.number.isRequired,
            lastFilter: PropTypes.shape({
                contract: PropTypes.shape({
                    filters: PropTypes.array,
                    sort: PropTypes.array,
                }),
            }),
            organization: PropTypes.shape({
                logo: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                zipCode: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        userDefaultFilters: PropTypes.arrayOf(
            PropTypes.shape({
                length: PropTypes.number,
                filters: PropTypes.arrayOf(PropTypes.object),
            })
        ),
        userDefaultSort: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                desc: PropTypes.bool,
                length: PropTypes.number,
            })
        ),
        userDefaultFullWidth: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        const { userDefaultSort, userDefaultFullWidth } = props;

        this.state = {
            exporting: false,
            fullWidth: userDefaultFullWidth,
            showPurchaseOrderImportModal: false,
        };

        const queryParams = parseRawQueryParams(props.location.query, props.location.pathname);

        if (queryParams.sort) {
            this.state.defaultSorted = [...queryParams.sort];
        } else if (!queryParams.sort && !this.props.user.lastFilter && userDefaultSort.length > 0) {
            this.state.defaultSorted = [...userDefaultSort];
        } else {
            this.state.defaultSorted = [];
        }
    }

    componentDidMount() {
        const { initialLoadedContractOptions } = this.props;

        this.props.loadContracts(initialLoadedContractOptions);
        this.props.storeLastFilter(CONTRACT, initialLoadedContractOptions);
        this.props.loadGovernmentRetentionCodes({
            governmentId: this.props.user.government_id,
        });
    }

    componentWillUnmount() {
        this.props.resetLoadedContracts();
    }

    get styles() {
        return require('./index.scss');
    }

    get queryParams() {
        return parseRawQueryParams(this.props.location.query, this.props.location.pathname);
    }

    get hasFiltersOrSearchQuery() {
        const queryParams = this.queryParams;

        return (
            queryParams.filters?.length > 0 ||
            (queryParams.quickSearchQuery !== null && queryParams.quickSearchQuery !== undefined)
        );
    }

    exportPurchaseOrders = () => {
        this.setState({ exporting: true });
        this.props.exportPurchaseOrders().then(() => {
            this.setState({ exporting: false });
        });
    };

    handleCreateContractClick = () => {
        /**
         * Open project create modal with `isContracts` project type automatically selected
         *
         * TODO: Find a better way to determine the steps. This will be very error prone if
         * the project create steps ever change.
         */
        this.props.showProjectCreateModal({
            isContracts: true,
        });
    };

    resetDefaultSorted = () => {
        const { userDefaultSort } = this.props;

        this.setState((prevState) => ({
            ...prevState,
            defaultSorted:
                userDefaultSort.length > 0
                    ? [
                          {
                              id: userDefaultSort[0].id,
                              desc: userDefaultSort[0].desc === 'true',
                          },
                      ]
                    : [],
        }));
    };

    resetLayout = () => {
        const data = {
            defaultFilters: [],
            defaultSorted: [],
            fullWidth: false,
            pageSize: DEFAULT_PAGE_SIZE_LIMIT,
        };

        this.setState((prevState) => ({
            ...prevState,
            ...data,
            updating: true,
        }));

        this.props
            .updateSettingsFilter(CONTRACT_TABLE_LAYOUT_SETTINGS, { data })
            .then(() => this.setState({ updating: false }));

        this.executeSearch({
            filters: [],
            limit: undefined,
            quickSearch: this.queryParams.quickSearch,
            page: undefined,
            sort: [],
        });
    };

    routeToPortal = () => {
        const {
            user: {
                government: { code },
            },
        } = this.props;

        window.open(`/portal/${code}/contracts`, '_blank');
    };

    saveLayout = () => {
        const { columnApi, fullWidth, gridApi } = this.state;
        const queryParams = this.queryParams;
        const { location } = this.props;
        const {
            query: { limit },
        } = location;
        const pageSize = Number.parseInt(limit, 10);

        const data = {
            defaultFilters: queryParams.filters,
            defaultSorted: queryParams.sort,
            pageSize: Number.isNaN(pageSize) ? DEFAULT_PAGE_SIZE_LIMIT : pageSize,
            fullWidth,
        };

        if (gridApi) {
            data.sortModel = gridApi.getColumnState();
            data.filterModel = gridApi.getFilterModel();
        }

        if (columnApi) {
            data.columnState = columnApi.getColumnState();
        }

        this.setState({ updating: true });
        this.props
            .updateSettingsFilter(CONTRACT_TABLE_LAYOUT_SETTINGS, { data })
            .then(() => this.setState({ updating: false }));
    };

    toggleWidth = () => {
        this.setState((prevState) => {
            return {
                fullWidth: !prevState.fullWidth,
            };
        });
    };

    renderGridButtons() {
        const {
            isContractAdmin,
            user: {
                government: { hideContractSpend },
            },
            docBuilderHomepage,
        } = this.props;

        const { exporting, fullWidth, updating } = this.state;

        return [
            <Button
                bsStyle="link"
                key="contract-list-documents"
                qaTag="dashboard-projects"
                to={docBuilderHomepage}
            >
                <span>
                    <i className="fa fa-file-text" /> Documents
                </span>
            </Button>,
            <DropdownButton
                bsSize="sm"
                id="contract-list-view-options"
                key="contract-list-view-options"
                title={
                    <>
                        <i className="fa fa-gear" /> View Options
                    </>
                }
            >
                <MenuItem onClick={this.toggleWidth} qaTag="contracts-toggleWidth">
                    <i className={`fa fa-fw fa-${fullWidth ? 'home' : 'expand'}`} />
                    &nbsp;
                    {fullWidth ? 'Dashboard View' : 'Full Width View'}
                </MenuItem>
                <MenuItem
                    disabled={updating}
                    onClick={updating ? undefined : this.saveLayout}
                    qaTag="contracts-saveLayout"
                >
                    <i className="fa fa-fw fa-save" /> Save Layout
                </MenuItem>
                <MenuItem onClick={this.resetLayout} qaTag="contracts-resetLayout">
                    <i className="fa fa-fw fa-undo" /> Reset Layout
                </MenuItem>
            </DropdownButton>,
            ...(hideContractSpend || !isContractAdmin
                ? []
                : [
                      <DropdownButton
                          bsSize="sm"
                          id="contract-list-purchase-orders"
                          key="contract-list-purchase-orders"
                          title={
                              <>
                                  <i className="fa fa-refresh" /> Sync POs
                              </>
                          }
                      >
                          <MenuItem
                              onClick={() => this.setState({ showPurchaseOrderImportModal: true })}
                              qaTag="contracts-importPOs"
                          >
                              <i className="fa fa-fw fa-cloud-upload" /> Import POs
                          </MenuItem>
                          <MenuItem
                              disabled={exporting}
                              onClick={this.exportPurchaseOrders}
                              qaTag="contracts-downloadPOs"
                          >
                              <i className="fa fa-fw fa-download" /> Download POs
                          </MenuItem>
                      </DropdownButton>,
                  ]),
        ];
    }

    /*
        When a user navigates to the contracts list by clicking a chart segment from the Contracts Dashboard,
        the list is filtered by the segment's contract IDs. In this case, it does not make sense to apply additional
        filters so this disabled panel is rendered instead.
    */
    renderDisabledFiltersPanel() {
        const restoreHandler = () => {
            const { userDefaultFilters, userDefaultSort } = this.props;

            this.executeSearch(
                { filters: userDefaultFilters, sort: userDefaultSort },
                { removeIdsFilter: true }
            );
        };

        return (
            <Panel className="text-center">
                <Panel.Heading componentClass="h5">Filtering Disabled</Panel.Heading>
                <Panel.Body>
                    The list is currently being filtered by a chart from the Contracts Dashboard
                    <OutlineButton
                        block
                        bsStyle="primary"
                        className={this.styles.restoreButton}
                        onClick={restoreHandler}
                    >
                        <i className="fa fa-refresh" />
                        &nbsp;Restore Filters
                    </OutlineButton>
                </Panel.Body>
            </Panel>
        );
    }

    executeSearch = (data, opts = {}) => {
        const queryParams = this.queryParams;

        const filters = data.filters || queryParams.filters || [];

        const searchQuery = {
            ...queryParams,
            ...data,
            filters: parseContractsFilters(filters),
        };

        if (!queryParams.sort) {
            this.setState({
                defaultSorted: [],
            });
        }

        // Sets page to 1 when new filters or search query is applied
        if (opts.resetPage) {
            searchQuery.page = 1;
        }

        if (opts.removeIdsFilter) {
            searchQuery.filters = searchQuery.filters?.filter((f) => f.type !== 'ids');
        }

        this.props.storeLastFilter(CONTRACT, searchQuery);
        // As mentioned above, browser navigation happens in the action: src/app/actions/contracts.js
        return this.props.loadContracts(searchQuery);
    };

    render() {
        const {
            canCreateContract,
            user: {
                government_id: governmentId,
                organization: { logo, name, zipCode },
            },
        } = this.props;

        const { fullWidth, showPurchaseOrderImportModal } = this.state;

        const renderDisabledFilters =
            this.queryParams.ids ||
            this.queryParams.filters?.some((filter) => filter.type === 'ids');

        return (
            <NoSsr>
                <PageTitle title="Contract List" />
                {!fullWidth && (
                    <div className="col-sm-5 col-md-4 col-lg-3" role="region">
                        <div
                            className={classNames(
                                this.styles.controlCol,
                                this.styles.dashboardMargin
                            )}
                        >
                            <DashboardControlNav logoUrl={logo}>
                                {renderDisabledFilters ? (
                                    this.renderDisabledFiltersPanel()
                                ) : (
                                    <ContractListFilters
                                        executeSearch={this.executeSearch}
                                        queryParams={this.queryParams}
                                    />
                                )}
                                <Button
                                    block
                                    bsStyle="primary"
                                    className={
                                        canCreateContract ? undefined : this.styles.createButton
                                    }
                                    disabled={!canCreateContract}
                                    onClick={this.handleCreateContractClick}
                                    qaTag="connectedGovernmentContractsListNav-newContract"
                                    tooltip={
                                        canCreateContract
                                            ? undefined
                                            : 'Only contract admins can create contracts'
                                    }
                                >
                                    <i className="fa fa-plus" />
                                    &nbsp; New Contract Record
                                </Button>
                                <Button
                                    block
                                    className="public-portal"
                                    onClick={this.routeToPortal}
                                    qaTag="connectedGovernmentContractsListNav-goToPublicPortal"
                                >
                                    <i className="fa fa-external-link" /> Go To Public Portal
                                </Button>
                                <Button
                                    block
                                    className="emergency-portal"
                                    qaTag="connectedGovernmentContractsListNav-emergencyPortal"
                                    to={`/governments/${governmentId}/emergency-portal/contracts`}
                                >
                                    <i className="fa fa-plus-square text-danger" /> Emergency Portal
                                </Button>
                            </DashboardControlNav>
                        </div>
                        <div className={this.styles.controlCol}>
                            <CooperativeContractBar
                                publicEntityName={name}
                                sizing="xs"
                                userZipCode={zipCode}
                            />
                        </div>
                    </div>
                )}
                <Main className={fullWidth ? 'col-xs-12' : 'col-sm-7 col-md-8 col-lg-9'}>
                    <h1 className="visually-hidden">Contract List</h1>
                    <ContractList.Government
                        defaultSorted={this.state.defaultSorted}
                        executeSearch={this.executeSearch}
                        filtered={this.hasFiltersOrSearchQuery}
                        gridButtons={this.renderGridButtons()}
                        resetDefaultSorted={this.resetDefaultSorted}
                    />
                </Main>
                {showPurchaseOrderImportModal && (
                    <PurchaseOrderImportModal
                        hideModal={() => this.setState({ showPurchaseOrderImportModal: false })}
                    />
                )}
            </NoSsr>
        );
    }
}

export const GovernmentContractsListNav = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedGovernmentContractsListNav);
