import { createSelector } from 'reselect';

import { isRequisitionGroupAdminUser } from '../../../selectors';

const requisitionsAdminNavItems = [
    {
        path: '/request-types',
        title: 'Request Types',
        requiresCustomFieldsFlag: true,
        requiresGroupAdminRole: true,
    },
    {
        path: '/review-groups',
        title: 'Groups',
        requiresCustomFieldsFlag: true,
        requiresGroupAdminRole: true,
    },
    {
        path: '/entity-positions',
        title: 'Entity Positions',
    },
    {
        path: '/group-positions',
        title: 'Group Positions',
    },
    {
        path: '/flags',
        title: 'Flags',
    },
    {
        path: '/custom-fields',
        title: 'Custom Fields',
        requiresCustomFieldsFlag: true,
    },
];

const getSelectedRequisitionsAdminNavItem = createSelector(
    [(state, props) => props.location.pathname],
    (pathname) => {
        return (
            requisitionsAdminNavItems.find((item) => !!item.path && pathname.match(item.path)) ||
            // Hard code the default selected nav item to 'Entity Positions'
            requisitionsAdminNavItems.find((item) => item.title === 'Entity Positions')
        );
    }
);

export const getRequisitionsAdminNavItems = createSelector(
    [
        getSelectedRequisitionsAdminNavItem,
        isRequisitionGroupAdminUser,
        (state, props) => props.enableCustomFields,
    ],
    // Add Flag to enable/disable custom fields
    (selectedNavItem, isRequisitionGroupAdmin, enableCustomFields) => {
        return requisitionsAdminNavItems
            .filter((navItem) => !navItem.requiresCustomFieldsFlag || enableCustomFields)
            .filter((navItem) => !navItem.requiresGroupAdminRole || isRequisitionGroupAdmin)
            .map((navItem) => ({
                ...navItem,
                isActive: navItem.title === selectedNavItem.title,
            }));
    }
);
