import { useDispatch } from 'react-redux';

import { vendorProposalSocket } from '../../lib/sockets';
import { useConnectSocket } from './socketHooks';
import { reloadVendorProposal } from '../../actions/vendProposals';
import { joinVendorProposal, leaveVendorProposal } from '../../actions/vendProposalsSocket';

export const useVendorProposalSocket = (proposalId) => {
    const dispatch = useDispatch();
    useConnectSocket(vendorProposalSocket, {
        onJoin: () => dispatch(joinVendorProposal(proposalId)),
        onLeave: () => dispatch(leaveVendorProposal(proposalId)),
        onReconnect: () => dispatch(reloadVendorProposal(proposalId)),
    });
};
