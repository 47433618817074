import React from 'react';

import { ReduxFormTextField } from '@og-pro/ui';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const ShortTextPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];
    return (
        <Field
            component={ReduxFormTextField}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="shortText_PREVIEW"
            sx={{
                width: '100%',
            }}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

ShortTextPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
