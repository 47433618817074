import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { requestTypeGroupSequenceFieldNames } from '../constants';
import { getRequestTypes } from '../../../../../../selectors/govApp';

const { REQUEST_TYPE_ID, REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;

export const useGetRequestTypeOptions = (requestTypeGroupSequencesFormValues) => {
    const requestTypes = useSelector(getRequestTypes);

    const requestTypeIdsInUse = requestTypeGroupSequencesFormValues.map(
        (requestTypeGroupSequence) => requestTypeGroupSequence[REQUEST_TYPE_ID]
    );

    return useMemo(() => {
        const requestTypeIdsInUseSet = new Set(requestTypeIdsInUse);
        return requestTypes.map((requestType) => ({
            isDisabled: requestTypeIdsInUseSet.has(requestType.id),
            label: requestType.name,
            value: requestType.id,
        }));
    }, [requestTypes, requestTypeIdsInUse]);
};

export const useGetReviewSequenceOptions = (requestTypeId) => {
    const requestTypes = useSelector(getRequestTypes);

    return useMemo(() => {
        const requestType = requestTypes.find(({ id }) => id === requestTypeId);
        const reviewSequences = requestType?.reviewSequences || [];
        return reviewSequences
            .filter(({ isException }) => !isException)
            .map(({ id, name }) => ({
                label: name,
                value: id,
            }));
    }, [requestTypes, requestTypeId]);
};

// NOTE: This requires request types to be loaded with the `view=group-positions` query parameter.
// It will not work properly without that view option.
export const useGetSelectedReviewSequences = (requestTypeGroupSequencesFormValues) => {
    const requestTypes = useSelector(getRequestTypes);

    const reviewSequences = useMemo(
        () =>
            requestTypes.flatMap((requestType) =>
                requestType.reviewSequences.filter(({ isException }) => !isException)
            ),
        [requestTypes]
    );

    const selectedReviewSequenceIds = useMemo(
        () =>
            requestTypeGroupSequencesFormValues.flatMap(
                (requestTypeGroupSequence) => requestTypeGroupSequence[REVIEW_SEQUENCE_ID]
            ),
        [requestTypeGroupSequencesFormValues]
    );

    return useMemo(() => {
        return reviewSequences.filter((sequence) =>
            selectedReviewSequenceIds.includes(sequence.id)
        );
    }, [reviewSequences, selectedReviewSequenceIds]);
};
