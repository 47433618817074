import { isNil } from 'lodash';

import { fieldNames } from './constants';

const { ENDS_IN_PURCHASE_ORDER, NAME } = fieldNames;

export const validate = (values, props) => {
    const errors = {};
    const { governmentHasFMS } = props;

    if (!values[NAME] || !values[NAME].trim()) {
        errors[NAME] = 'Name is required';
    } else if (values[NAME].length > 256) {
        errors[NAME] = 'Name is too long';
    }

    if (governmentHasFMS && isNil(values[ENDS_IN_PURCHASE_ORDER])) {
        errors[ENDS_IN_PURCHASE_ORDER] = 'Please select an option';
    }

    return errors;
};
