import { customFieldFields } from '@og-pro/shared-config/customFormService/customField';

import { REQUIRED_TEXT } from '../../../../../Forms/validation';

const { CHARACTER_LIMIT, ENUM_OPTIONS, NUMBER_KIND } = customFieldFields;

const validateEnumOptions = (values) => {
    const errors = {};

    if (!values[ENUM_OPTIONS]) {
        errors[ENUM_OPTIONS] = 'Please provide options for this field';
        return errors; // Return early since the field array is missing
    }

    if (!values[ENUM_OPTIONS].length) {
        errors[ENUM_OPTIONS] = 'Please provide at least one option for this field';
        return errors; // Return early since no options are provided
    }

    const optionsErrors = [];
    values[ENUM_OPTIONS].forEach((option, index) => {
        if (!option || option.trim() === '') {
            optionsErrors[index] = 'This field is required or must be deleted.';

            if (index === 0) {
                optionsErrors[index] = REQUIRED_TEXT; // First option is required
            }
        }
    });

    if (optionsErrors.length) {
        errors[ENUM_OPTIONS] = optionsErrors;
    }

    return errors;
};

const validateCheckboxOptions = (values) => {
    if (!values[ENUM_OPTIONS] && values[ENUM_OPTIONS].length !== 1) {
        // Unexpected data model, return early with no errors
        return {};
    }

    const errors = {};

    const checkboxText = values[ENUM_OPTIONS][0];
    if (!checkboxText || checkboxText.trim() === '') {
        errors[ENUM_OPTIONS] = ['Please provide an affirmative label.'];
    }

    return errors;
};
const validateDateOptions = () => {};
const validateDropdownOptions = (values) => {
    return validateEnumOptions(values);
};
const validateLongTextOptions = (values) => {
    const errors = {};

    if (!values[CHARACTER_LIMIT]) {
        errors[CHARACTER_LIMIT] = 'Please specify a character limit or choose none';
    }

    return errors;
};
const validateMultipleChoiceOptions = (values) => {
    return validateEnumOptions(values);
};
const validateSingleChoiceOptions = (values) => {
    return validateEnumOptions(values);
};
const validateNumberOptions = (values) => {
    const errors = {};

    if (!values[NUMBER_KIND]) {
        errors[NUMBER_KIND] = 'Please specify a type of number';
    }

    return errors;
};
const validateShortTextOptions = () => {};
const validateYesNoOptions = () => {};

export {
    validateCheckboxOptions,
    validateDateOptions,
    validateDropdownOptions,
    validateLongTextOptions,
    validateMultipleChoiceOptions,
    validateSingleChoiceOptions,
    validateNumberOptions,
    validateShortTextOptions,
    validateYesNoOptions,
};
