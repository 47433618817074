import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { Box, ButtonBase } from '@og-pro/ui';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { RequisitionGroupsListItem } from './RequisitionGroupsListItem';
import { TemplateListHeader, ZeroState } from '../../../../components';

const SortableHeaderButton = (props) => {
    const { buttonField, sortField, sortDirection, setSortField, setSortDirection } = props;

    return (
        <ButtonBase
            disableRipple
            onClick={() => {
                if (buttonField === sortField) {
                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                    setSortField(buttonField);
                    setSortDirection('asc');
                }
            }}
            sx={{
                width: '100%',
                justifyContent: 'flex-start',
                gap: 0.5,
            }}
        >
            <TemplateListHeader useOpenGovStyle>
                {props.children}&nbsp;
                {buttonField === sortField &&
                    (sortDirection === 'asc' ? (
                        <ArrowUpward fontSize="inherit" />
                    ) : (
                        <ArrowDownward fontSize="inherit" />
                    ))}
            </TemplateListHeader>
        </ButtonBase>
    );
};

SortableHeaderButton.propTypes = {
    children: PropTypes.node,
    buttonField: PropTypes.string.isRequired,
    sortField: PropTypes.oneOf(['name', 'owner', 'reviewSequence', 'created_at', 'lastUpdatedAt'])
        .isRequired,
    setSortField: PropTypes.func.isRequired,
    sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
    setSortDirection: PropTypes.func.isRequired,
};

/**
 * Return a sorted array based on sortField and sortDirection
 * @param {Array<object>} requisitionGroups
 * @param {'name' | 'owner' | 'reviewSequence' | 'created_at' | 'lastUpdatedAt'} sortField
 * @param {'asc' | 'desc'} sortDirection
 * @returns {Array<object>} sorted shallow clone of requisitionGroups array
 */
const getSortedGroups = (requisitionGroups, sortField, sortDirection) => {
    let comparator;
    switch (sortField) {
        case 'name':
            comparator = (a, b) => (a.name || 'Untitled').localeCompare(b.name || 'Untitled');
            break;
        case 'owner':
            comparator = (a, b) => a.user.displayName.localeCompare(b.user.displayName);
            break;
        case 'reviewSequence':
            comparator = (a, b) =>
                (a.requestTypeGroupSequence?.[0]?.reviewSequence?.name || '').localeCompare(
                    b.requestTypeGroupSequence?.[0]?.reviewSequence?.name || ''
                );
            break;
        case 'created_at':
            comparator = (a, b) =>
                new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            break;
        case 'lastUpdatedAt':
            comparator = (a, b) =>
                new Date(a.lastUpdatedAt).getTime() - new Date(b.lastUpdatedAt).getTime();
            break;
        default:
            comparator = () => 0;
            break;
    }

    const sortedGroups = requisitionGroups.slice().sort(comparator);
    if (sortDirection === 'desc') {
        return sortedGroups.reverse();
    }
    return sortedGroups;
};

export const RequisitionGroupsList = ({
    hasNoCreatedGroups,
    RequisitionGroupCreateButton,
    requisitionGroups,
    routingHandler,
    showInUseColumn,
}) => {
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const isCustomFieldsFlagEnabled = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    if (requisitionGroups.length === 0) {
        const props = {
            button: hasNoCreatedGroups ? <RequisitionGroupCreateButton size="lg" /> : undefined,
            title: hasNoCreatedGroups ? 'No groups created yet' : 'No groups here!',
        };
        return <ZeroState {...props} useOpenGovStyle />;
    }

    const sortedRequisitionGroups = getSortedGroups(requisitionGroups, sortField, sortDirection);

    const sortStateProps = {
        sortField,
        setSortField,
        sortDirection,
        setSortDirection,
    };
    return (
        <>
            <Box className="row hidden-xs hidden-sm" sx={{ padding: '0 16px 5px' }}>
                <div className="col-md-9">
                    <div className="row">
                        <div className={showInUseColumn ? 'col-md-5' : 'col-md-6'}>
                            <SortableHeaderButton buttonField="name" {...sortStateProps}>
                                Name
                            </SortableHeaderButton>
                        </div>
                        <div className={showInUseColumn ? 'col-md-3' : 'col-md-6'}>
                            <SortableHeaderButton buttonField="owner" {...sortStateProps}>
                                Owner
                            </SortableHeaderButton>
                        </div>
                        {showInUseColumn && (
                            <div className="col-md-4">
                                {isCustomFieldsFlagEnabled ? (
                                    <TemplateListHeader>Request Types In Use</TemplateListHeader>
                                ) : (
                                    <SortableHeaderButton
                                        buttonField="reviewSequence"
                                        {...sortStateProps}
                                    >
                                        Sequence In Use
                                    </SortableHeaderButton>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-md-6">
                            <SortableHeaderButton buttonField="created_at" {...sortStateProps}>
                                Created
                            </SortableHeaderButton>
                        </div>
                        <div className="col-md-6">
                            <SortableHeaderButton buttonField="lastUpdatedAt" {...sortStateProps}>
                                Last Updated
                            </SortableHeaderButton>
                        </div>
                    </div>
                </div>
            </Box>
            <ListGroup>
                {sortedRequisitionGroups.map((requisitionGroup) => {
                    return (
                        <ListGroupItem
                            key={requisitionGroup.id}
                            onClick={() => routingHandler(requisitionGroup.id)}
                        >
                            <RequisitionGroupsListItem
                                requisitionGroup={requisitionGroup}
                                showInUseColumn={showInUseColumn}
                            />
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

RequisitionGroupsList.propTypes = {
    hasNoCreatedGroups: PropTypes.bool,
    RequisitionGroupCreateButton: PropTypes.func.isRequired,
    requisitionGroups: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
    showInUseColumn: PropTypes.bool,
};
