import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { FmsSelectedVendorSearch } from './FmsSelectedVendorSearch';
import { getAddressData } from './helper';
import { SelectedVendorSearch } from './SelectedVendorSearch';
import { fieldNames } from '../../../constants';
import { getVendorDataFromContract } from '../../../../utils';

const {
    PAYMENT_TERMS_TEXT,
    PRO_VENDOR_ID,
    VENDOR_ASSIGNED_NO,
    VENDOR_EMAIL,
    VENDOR_NAME,
    VENDOR_ID,
} = fieldNames;

const ConnectedVendorSearch = ({
    changeHandler,
    disabled,
    endsInPurchaseOrder,
    showFormValidation,
    showMismatchModal,
}) => {
    const onVendorChange = (data, additionalData) => {
        if (!data) {
            return;
        }

        const vendorData = {
            [VENDOR_ID]: data.value,
            [VENDOR_NAME]: data.user.displayName,
            [VENDOR_EMAIL]: data.user.email,
            [PAYMENT_TERMS_TEXT]: data.defaultPaymentTerms?.payment_terms_code,
            ...additionalData,
        };

        if (endsInPurchaseOrder) {
            // Use ID as vendor assigned number when FMS connected
            vendorData[VENDOR_ASSIGNED_NO] = data.value?.toString();
        } else {
            // If not FMS connected the vendor ID is the PRO vendor ID
            vendorData[PRO_VENDOR_ID] = data.value;
        }

        if (data.user.addresses.length > 0) {
            return changeHandler({
                ...vendorData,
                ...getAddressData(data.user.addresses),
            });
        }

        return changeHandler(vendorData);
    };

    // Only available for not FMS connected
    const onContractChange = (data) => {
        // Contract must belong to a vendor. Otherwise, the contract selection will not be allowed.
        const vendor = data?.contract?.contractParty?.vendor;
        if (!vendor) {
            return;
        }

        const vendorData = getVendorDataFromContract(data.contract);

        return changeHandler(vendorData);
    };

    if (endsInPurchaseOrder) {
        return (
            <FmsSelectedVendorSearch
                disabled={disabled}
                onVendorChange={onVendorChange}
                showFormValidation={showFormValidation}
                showMismatchModal={showMismatchModal}
            />
        );
    }

    return (
        <SelectedVendorSearch
            disabled={disabled}
            onContractChange={onContractChange}
            onVendorChange={onVendorChange}
            showFormValidation={showFormValidation}
        />
    );
};

ConnectedVendorSearch.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    endsInPurchaseOrder: PropTypes.bool.isRequired,
    showFormValidation: PropTypes.bool,
    showMismatchModal: PropTypes.func.isRequired,
};

export const VendorSearch = reduxForm({ form: 'requisitionVendorSearch' })(ConnectedVendorSearch);
