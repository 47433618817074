import { createSelector } from 'reselect';

import { isGovernmentFMSConnected } from '@og-pro/shared-config/financialSystems';

import { getUserJS } from '../../containers/selectors';

const getGovernment = createSelector([getUserJS], (user) => {
    return user.government;
});

export const getIsGovernmentUsingFMS = createSelector([getGovernment], (government) => {
    return isGovernmentFMSConnected(government);
});

export const getGovernmentSalesTax = createSelector(
    [getUserJS],
    (user) => user.government.salesTax
);
