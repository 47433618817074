import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@og-pro/ui';
import { statusTypes as reviewSequenceStatusTypes } from '@og-pro/shared-config/reviewSequences';
import {
    REQUISITIONS_LIMIT,
    requisitionSortingOptionsNames,
    requisitionTabsNames,
} from '@og-pro/shared-config/requisitions';

import { RequisitionsDashboardBody } from './RequisitionsDashboardBody';
import { LoadingSpinner, Main, SectionTitle } from '../../../../components';
import { ProjectCreateButton } from '../../../../components/GovApp';
import { RequisitionsDashboardSummary } from './RequisitionsDashboardSummary';
import { loadRequisitionGroups } from '../../../../actions/requisitionGroups';
import { getIsGroupAdminOrLeader } from './selectors';
import { useQueryParam, useRouteDefaultQueryParams } from '../../../../hooks';
import { getQuickFilters } from './helpers';
import { loadReviewSequences } from '../../../../actions/reviewSequences';
import { getTemporarySingleRequestType } from '../../../../selectors/govApp';
import { loadRequestTypes } from '../../../../actions/requestTypes';

const { MY_APPROVALS, ALL_REQUESTS } = requisitionTabsNames;

export const RequisitionsDashboard = () => {
    const dispatch = useDispatch();
    const loadedGroups = useSelector((state) => state.requisitionGroups.get('loadedGroups'));
    const loadedSequences = useSelector((state) => state.reviewSequences.get('loadedSequences'));
    const styles = require('./index.scss');

    const isLoadingRequisitionGroups = useSelector((state) =>
        state.requisitionGroups.get('loadingGroups')
    );

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));
    const requestType = useSelector(getTemporarySingleRequestType);

    const defaultTab = isAdminOrLeader ? ALL_REQUESTS : MY_APPROVALS;
    const [queryTab] = useQueryParam('tab', defaultTab);

    const quickFilters = useMemo(() => getQuickFilters(queryTab), [queryTab]);

    useEffect(() => {
        if (!loadedGroups) {
            dispatch(loadRequisitionGroups());
        }
        if (!loadedSequences) {
            dispatch(
                loadReviewSequences(
                    requestType.id,
                    { status: reviewSequenceStatusTypes.PUBLISHED },
                    { setLoaded: true }
                )
            );
        }
        dispatch(loadRequestTypes());
    }, [loadedGroups, loadedSequences]);

    useRouteDefaultQueryParams([
        { paramName: 'tab', defaultValue: defaultTab },
        { paramName: 'quickFilter', defaultValue: quickFilters[0].value },
        { paramName: 'sort', defaultValue: requisitionSortingOptionsNames.ACTIVE_DATE },
        { paramName: 'sortDirection', defaultValue: 'DESC' },
        { paramName: 'page', defaultValue: 1 },
        { paramName: 'limit', defaultValue: REQUISITIONS_LIMIT },
    ]);

    if (!loadedGroups || !loadedSequences) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    return (
        <Main className={styles.page}>
            <div className={styles.header}>
                <SectionTitle title="Requests" titlePlacement="left" />
                <div className={styles.headerButtons}>
                    <ProjectCreateButton
                        className={styles.newRequestBtn}
                        color="primary"
                        component={Button}
                        hideIcon
                        isRequisition
                        qaTag="requisitionDashboard-create"
                        text="New Request"
                        variant="contained"
                    />
                </div>
            </div>
            {isLoadingRequisitionGroups ? (
                <LoadingSpinner useOpenGovStyle />
            ) : (
                <Box display="flex" flexDirection="column" gap={2}>
                    <RequisitionsDashboardSummary />
                    <RequisitionsDashboardBody />
                </Box>
            )}
        </Main>
    );
};
