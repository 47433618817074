import { sortBy, uniqBy } from 'lodash';
import { buildMap } from '@og-pro/shared-config/helpers';

import { reviewGroupPositionFieldNames } from './constants';

const { REVIEW_GROUP_POSITION_USERS } = reviewGroupPositionFieldNames;

// Replaces existing `ReviewGroupPositions` with positions from new sequence. Preserves the
// `ReviewGroupPosition` when same position is used in new sequence.
export const replaceReviewGroupPositions = (
    existingReviewGroupPositionFormValues,
    reviewSequences
) => {
    const existingPositions = buildMap(existingReviewGroupPositionFormValues, 'position_id');
    const allCurrentPositions = reviewSequences.flatMap(({ positions }) => positions);
    const currentPositionsToSeed = uniqBy(allCurrentPositions, 'id');

    const reviewGroupPositions = currentPositionsToSeed.map((position) => {
        const existingPosition = existingPositions[position.id];
        // Re-use existing `ReviewGroupPosition`
        if (existingPosition) {
            return existingPosition;
        }
        // Seed new `ReviewGroupPosition`
        return {
            position,
            position_id: position.id,
            [REVIEW_GROUP_POSITION_USERS]: [],
        };
    });
    return sortBy(reviewGroupPositions, 'position.name');
};
