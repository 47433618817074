import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { contractFilterTypesDict } from '@og-pro/shared-config/contracts';

import { buildMap } from '@og-pro/shared-config/helpers';

import { hasContractingSubscription } from '../../../../../GovApp/selectors';
import { loadContractsRaw } from '../../../../../../actions/contracts';
import { searchRequisitionVendors } from '../../../../../../actions/requisitions';
import { getRequisitionJS } from '../../../../../../selectors/govApp';

export const useGetAssociatedVendorData = ({ endsInPurchaseOrder, vendors }) => {
    const [vendorData, setVendorData] = useState({});
    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);
    const hasContracting = useSelector(hasContractingSubscription);

    // For non-FMS requisitions. Get associated vendor data when selected vendors change.
    useEffect(() => {
        const vendorIds = vendors
            ?.filter((vendor) => vendor.pro_vendor_id)
            .map((vendor) => vendor.pro_vendor_id);

        if (!vendorIds || vendorIds.length === 0) {
            return;
        }

        const fetchData = async () => {
            // Get vendor + contracts + users -> Map vendor data by vendor ID
            setVendorData({ loading: true, loadingError: null, vendors: null });
            try {
                const result = await dispatch(searchRequisitionVendors(requisition.id, vendorIds));
                setVendorData({ loading: false, vendors: buildMap(result, 'id') });
            } catch (error) {
                setVendorData({ loading: false, loadingError: error.message });
            }
        };

        fetchData();
        // Only run when pro_vendor_id changes in an item
    }, [vendors?.map((vendor) => vendor.pro_vendor_id).join(',')]); // eslint-disable-line react-hooks/exhaustive-deps

    // For FMS requisitions. Get associated contract data when selected vendors change.
    useEffect(() => {
        const vendorAssignedNumbers = (vendors || [])
            .filter((vendor) => !isNil(vendor.vendorAssignedNo))
            .map((vendor) => vendor.vendorAssignedNo.toString());

        if (!endsInPurchaseOrder || !hasContracting || vendorAssignedNumbers.length === 0) {
            return;
        }

        const fetchData = async () => {
            // Get contracts -> Map contract data by vendorAssignedNo
            setVendorData({ loading: true, loadingError: null, contracts: null });
            try {
                const result = await dispatch(
                    loadContractsRaw({
                        filters: [
                            {
                                type: contractFilterTypesDict.VENDOR_ASSIGNED_NO,
                                value: vendorAssignedNumbers,
                            },
                        ],
                        limit: 20,
                        page: 1,
                    })
                );
                const contracts = result.contracts.reduce((obj, contract) => {
                    const key = contract.vendorAssignedNo?.toLowerCase();
                    if (!obj[key]) {
                        obj[key] = [];
                    }
                    obj[key].push(contract);
                    return obj;
                }, {});
                setVendorData({ loading: false, contracts });
            } catch (error) {
                setVendorData({ loading: false, loadingError: error.message });
            }
        };

        fetchData();
        // Only run when vendorAssignedNo changes in an item
    }, [vendors?.map((vendor) => vendor.vendorAssignedNo).join(',')]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        contracts: vendorData.contracts,
        error: vendorData.loadingError,
        loading: vendorData.loading,
        vendors: vendorData.vendors,
    };
};
