import React, { useContext } from 'react';

import { Field } from 'redux-form';

import { ReduxFormTextField } from '@og-pro/ui';

import PropTypes from 'prop-types';

import { customFieldFields } from '@og-pro/shared-config/customFormService/customField';

import { CreateCustomFieldContext } from '../context';

const { ENUM_OPTIONS } = customFieldFields;

export const CheckboxOptions = ({ disabled }) => {
    const { showFormValidation } = useContext(CreateCustomFieldContext);
    return (
        <Field
            component={ReduxFormTextField}
            disabled={disabled}
            label="Checkbox Text *"
            name={`${ENUM_OPTIONS}[0]`}
            showValidation={showFormValidation}
        />
    );
};

CheckboxOptions.propTypes = {
    disabled: PropTypes.bool,
};
