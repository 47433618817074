import React from 'react';
import { Box, Button, ReduxFormRadioGroup, ReduxFormTextField, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { endsInPurchaseOrderOptions, fieldNames, form } from './constants';
import { validate } from './validate';
import { qaTagName } from '../constants';
import { getIsGovernmentUsingFMS } from '../../../../../../selectors/govApp';

const formConfig = {
    form,
    validate,
};

// @reduxForm
const ConnectedRequestTypeCreateForm = ({
    disabled,
    handleSubmit,
    onCancel,
    serverError,
    submitFailed,
}) => {
    const governmentHasFMS = useSelector(getIsGovernmentUsingFMS);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <Field
                component={ReduxFormTextField}
                disabled={disabled}
                fullWidth
                label="Name of Request Type*"
                name={fieldNames.NAME}
                qaTag={`${qaTagName}-name`}
                showValidation={submitFailed}
            />
            {governmentHasFMS && (
                <Field
                    component={ReduxFormRadioGroup}
                    description="This can be decided later, but the decision has an impact on what fields and sections are required to be in the form."
                    disabled={disabled}
                    label="Will this Request Type result in a Purchase Order?*"
                    name={fieldNames.ENDS_IN_PURCHASE_ORDER}
                    options={endsInPurchaseOrderOptions}
                    qaTag={`${qaTagName}-endsInPurchaseOrder`}
                    row
                    showValidation={submitFailed}
                />
            )}
            <Box sx={{ textAlign: 'right' }}>
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onCancel}
                    qaTag={`${qaTagName}-cancel`}
                    sx={{ marginRight: 1 }}
                    variant="text"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={disabled}
                    qaTag={`${qaTagName}-create`}
                    type="submit"
                    variant="contained"
                >
                    Create
                </Button>
                {serverError && (
                    <Box className="error-block">
                        <Typography component="span" variant="bodySmall">
                            {serverError}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

ConnectedRequestTypeCreateForm.propTypes = {
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    serverError: PropTypes.string,
    submitFailed: PropTypes.bool,
};

// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withFormConfig = (ReduxFormWrappedComponent) => {
    return (props) => {
        const governmentHasFMS = useSelector(getIsGovernmentUsingFMS); // Needed for `validate` function
        return <ReduxFormWrappedComponent governmentHasFMS={governmentHasFMS} {...props} />;
    };
};

// Wraps a component with the redux-form decorator configured for the `requestTypeForm` form
export const RequestTypeCreateForm = withFormConfig(
    reduxForm(formConfig)(ConnectedRequestTypeCreateForm)
);
