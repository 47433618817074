import React from 'react';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box } from '@og-pro/ui';

const { colors } = capitalDesignTokens.foundations;

const STATUSES_COLORS = {
    [projectStatusesDict.DRAFT]: colors.gray800,
    [projectStatusesDict.REVIEW]: colors.purple700,
    [projectStatusesDict.FINAL]: colors.cerulean800,
};

export const StatusCellRenderer = (params) => {
    return (
        <Box
            component="span"
            sx={{
                color: STATUSES_COLORS[params.value] || STATUSES_COLORS[projectStatusesDict.DRAFT],
                fontWeight: 600,
                textTransform: 'capitalize',
            }}
        >
            {params.value}
        </Box>
    );
};
