import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@og-pro/ui';

import {
    createCustomFieldFormName,
    customFieldFields,
    fieldStatusesDict,
    fieldTypesArray,
} from '@og-pro/shared-config/customFormService/customField';

import { getFormValues } from 'redux-form';

import { useSelector } from 'react-redux';

import { pageBodyStyles, pageSectionContainerStyles } from './styles';
import { CreateCustomFieldSettings } from './Form/CreateCustomFieldSettings';
import { CreateCustomFieldPreview } from './Form/CreateCustomFieldPreview';
import { CustomFieldUsedList } from './Form/CustomFieldUsedList';
import { CustomFieldHistory } from './Form/CustomFieldHistory';

const { ID, STATUS } = customFieldFields;

export const CreateCustomFieldForm = ({ fieldType, isLoading }) => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const showHistory = formValues[ID] !== undefined;
    const showUsedList =
        formValues[ID] !== undefined && formValues[STATUS] !== fieldStatusesDict.ARCHIVED;

    return (
        <Box sx={pageBodyStyles}>
            <Grid container spacing={4} sx={{ width: '90%' }}>
                <Grid item md={5} xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                        }}
                    >
                        <Box sx={pageSectionContainerStyles}>
                            <Typography variant="h3">Preview</Typography>
                            <CreateCustomFieldPreview fieldType={fieldType} />
                        </Box>
                        <Box
                            sx={{
                                display: {
                                    md: 'block',
                                    xs: 'none',
                                },
                            }}
                        >
                            {showUsedList && <CustomFieldUsedList />}
                            {showHistory && <CustomFieldHistory />}
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={7} sx={pageSectionContainerStyles} xs={12}>
                    <Typography variant="h3">Field Label</Typography>
                    <CreateCustomFieldSettings fieldType={fieldType} isLoading={isLoading} />
                </Grid>
                {(showUsedList || showHistory) && (
                    <Grid
                        item
                        md={4}
                        sx={{
                            ...pageSectionContainerStyles,
                            display: {
                                md: 'none',
                                xs: 'flex',
                            },
                        }}
                        xs={12}
                    >
                        <Typography variant="h3">Details</Typography>
                        {showUsedList && <CustomFieldUsedList />}
                        {showHistory && <CustomFieldHistory />}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

CreateCustomFieldForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
};
