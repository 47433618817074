import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { arrayMove, Field, FieldArray, formValueSelector, getFormSyncErrors } from 'redux-form';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { compilerSectionTypes } from '@og-pro/shared-config/projects';
import { Box, Tooltip, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { DraggableChecklistSection } from './DraggableChecklistSection';
import { ReviewContainer } from './ReviewContainer';
import { ReviewChecklistSection } from './Section';
import { ReviewChecklistSectionsContainer } from './SectionsContainer';
import { ProjectCreateV2FunctionsContext, ProjectCreateV2NavContext } from '../../context';
import { getSDv2WritingSections } from '../../../selectors';
import { buildTabRoute } from '../../../../../../helpers';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { fieldNames } from '../../constants';
import {
    setFormCompleteConfirmation,
    showFormValidation,
} from '../../../../../../actions/project/create/projectCreate';
import { Checkbox, Toggle } from '../../../../../../components';

const { ATTACHMENTS, ATTACHMENTS_TABLE_OF_CONTENTS, SIGNATURES } = compilerSectionTypes;

const { COMPILE_SETTINGS, SECTION_SETTINGS } = fieldNames;

const ContractPackage = ({ buildRoute, form, fields, formErrors, project }) => {
    const dispatch = useDispatch();

    const showSignaturesSection =
        !!project.signatures.length &&
        project.signatures.some((signature) => !signature.isHiddenByLogic);

    const showExhibitsSection = !!project.attachments.length;

    const move = (originLocation, newLocation) => {
        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        move(originLocation, newLocation);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="contractPackageCompiler">
                {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {fields.map((field, index) => {
                            const fieldArray = fields.getAll();
                            const formSection = fieldArray[index];

                            if (
                                formSection.disabled ||
                                (formSection.type === SIGNATURES && !showSignaturesSection) ||
                                (formSection.type === ATTACHMENTS && !showExhibitsSection) ||
                                (formSection.type === ATTACHMENTS_TABLE_OF_CONTENTS &&
                                    !showExhibitsSection)
                            ) {
                                return null;
                            }

                            const canMoveUp = fieldArray
                                .slice(0, index)
                                .some((section) => !section.disabled);
                            const canMoveDown = fieldArray
                                .slice(index + 1)
                                .some((section) => !section.disabled);

                            return (
                                <DraggableChecklistSection
                                    attachments={
                                        project.attachments
                                            ?.filter((attachment) => !attachment.isHiddenByLogic)
                                            .map((attachment) => ({
                                                title: `${attachment.appendixId ? `${attachment.appendixId} - ` : ''}${attachment.title}`,
                                            })) || []
                                    }
                                    buildRoute={buildRoute}
                                    canMoveDown={canMoveDown}
                                    canMoveUp={canMoveUp}
                                    draggable={formSection}
                                    fields={fields}
                                    formErrors={formErrors}
                                    index={index}
                                    key={field.type}
                                    move={move}
                                />
                            );
                        })}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

ContractPackage.propTypes = {
    buildRoute: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    formErrors: PropTypes.object,
    project: PropTypes.shape({
        attachments: PropTypes.array,
        signatures: PropTypes.array,
    }).isRequired,
};

export const ContractReviewChecklist = () => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const signaturesTab = useFlags(FLAGS.ENABLE_SIGNATURES_TAB);
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        form,
        location,
        project,
        project: { lastUpdatedAt, useManualNumbering },
        showFormErrors,
        reviewPath,
    } = useContext(ProjectCreateV2FunctionsContext);

    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));
    const hasIncompleteSections = !!formErrors?.allSections;

    const valueSelector = formValueSelector(form);
    const compileSettings = useSelector((state) => valueSelector(state, COMPILE_SETTINGS));

    useEffect(() => {
        dispatch(
            setFormCompleteConfirmation(
                hasIncompleteSections ? null : new Date(lastUpdatedAt).getTime()
            )
        );
    }, [hasIncompleteSections, lastUpdatedAt]);

    const buildRoute = buildTabRoute(location);
    const showingSignaturesTab =
        signaturesTab && project.signatures.filter((s) => !s.isHiddenByLogic).length > 0;

    const onReviewClick = () => {
        navigate(reviewPath);
    };

    const mainDocumentSections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        showFormValidation: true,
        matchErrorToSection: (section) => {
            return formErrors?.sections && formErrors.sections[section.id];
        },
        hideEmptyDividers: true,
    });

    return (
        <ReviewContainer
            description="This tab displays the order of things within your contract document. Check for errors and update the ordering before sending your document to be approved."
            onSubmit={onReviewClick}
            showingSignaturesTab={showingSignaturesTab}
            submitButtonText="Start Review Process"
            title="Review Checklist"
        >
            <Box alignItems="center" display="flex" gap={1}>
                <Typography variant="h3">Export Settings</Typography>
            </Box>
            <Box mb={3}>
                <Box className={styles.sectionsContainer} px={3} py={2}>
                    <Box display="flex" gap={2}>
                        <Field
                            component={Toggle}
                            name={`${COMPILE_SETTINGS}.settings.addPageNumbers`}
                            qaTag="compileSettings-addPageNumbers"
                            useOpenGovStyle
                        />
                        Add Page Numbers
                    </Box>
                    <Box display="flex">
                        <Field
                            component={Checkbox}
                            disabled={!compileSettings?.settings?.addPageNumbers}
                            inline
                            name={`${COMPILE_SETTINGS}.settings.includeFirstPage`}
                            qaTag="compileSettings-includeFirstPage"
                            text="Include first page"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            </Box>
            <Box alignItems="center" display="flex" gap={1}>
                <Typography variant="h3">Contract Properties</Typography>
                <Tooltip title="Contract properties will not display in the web version of your contract or in the exported (and printable) version.">
                    <InfoOutlinedIcon
                        fontSize="small"
                        style={{ color: tokens.colors.colorGray700 }}
                    />
                </Tooltip>
            </Box>
            <Box mb={3}>
                <Box className={styles.sectionsContainer}>
                    <ReviewChecklistSection
                        incomplete={formErrors?.sections[1]}
                        onClick={() => {
                            dispatch(showFormValidation());
                            navigate(buildRoute('project-properties'));
                        }}
                        section={{ title: 'Contract Information' }}
                    />
                    <ReviewChecklistSection
                        incompleteFields={
                            formErrors?.upfrontQuestions &&
                            formErrors?.upfrontQuestions.filter((e) => !!e).length
                        }
                        last
                        onClick={() => {
                            dispatch(showFormValidation());
                            navigate(`${buildRoute('project-properties')}?activeSection=1`);
                        }}
                        section={{ title: 'Set Up Questions' }}
                    />
                </Box>
            </Box>
            <Box mb={3}>
                <Typography variant="h3">Contract Package</Typography>
                <ReviewChecklistSectionsContainer
                    buildRoute={buildRoute}
                    sections={mainDocumentSections}
                    title="Main Document Outline"
                />
            </Box>
            <FieldArray
                buildRoute={buildRoute}
                component={ContractPackage}
                form={form}
                formErrors={formErrors}
                name={`${COMPILE_SETTINGS}.${SECTION_SETTINGS}`}
                project={project}
                showValidation={showFormErrors}
            />
        </ReviewContainer>
    );
};
