import { useSelector } from 'react-redux';
import React from 'react';

import PropTypes from 'prop-types';

import { getRequestType } from '../../../../../../selectors/govApp';
import { CustomFieldsEditor, CustomFormEditorProvider } from '.';

export const CustomFieldsSection = ({ isSelected, setIsSelected }) => {
    const requestType = useSelector(getRequestType);
    const { customFormId } = requestType;

    return (
        <CustomFormEditorProvider
            customFormId={customFormId}
            isSectionSelected={isSelected}
            setIsSectionSelected={setIsSelected}
        >
            <CustomFieldsEditor />
        </CustomFormEditorProvider>
    );
};

CustomFieldsSection.propTypes = {
    isSelected: PropTypes.bool,
    setIsSelected: PropTypes.func,
};
