import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';

import { SectionHeaderTitleNumberForm } from '../../../../../../../components/SDv2';
import { ProjectCreateV2FunctionsContext } from '../../../context';
import { fieldNames } from '../../../constants';

const { PROJECT_SECTIONS, SECTION_TYPE, PARENT_DIVIDER } = fieldNames;
// The edit component that's expected to be called by a parent
// where redux form has been initialized, expecting the initialValues to be
// { [PROJECT_SECTIONS]: [{...}] }
export const DisconnectedEditSection = ({
    change,
    onCancel,
    onSave,
    form,
    index,
    showActionsOnFooter = false,
}) => {
    const [originalParentDividerIndex, setOriginalParentDivider] = useState(null);
    const [parentDividerOptions, setParentDividerOptions] = useState([]);
    const { project } = useContext(ProjectCreateV2FunctionsContext);
    const sections = useSelector((state) => formValueSelector(form)(state, PROJECT_SECTIONS));
    const rawSections = project[PROJECT_SECTIONS];

    const { useManualNumbering, useSectionDividers } = project;

    useEffect(() => {
        if (useManualNumbering && useSectionDividers) {
            const options = rawSections.reduce((acc, section, i) => {
                if (section.section_type !== sectionTypeNames.DIVIDER) {
                    return acc;
                }

                return acc.concat([
                    {
                        icon: 'indent',
                        label: section.title,
                        value: i,
                    },
                ]);
            }, []);

            if (options.length) {
                // if we have parent divider options it means we have manual numbers and dividers enabled
                // find out which is the parent divider of the current section
                // to make the "parent section" select work
                let parent = 0;

                for (let i = 0; i < options.length; i += 1) {
                    if (options[i].value > index) {
                        break;
                    }
                    parent = options[i].value;
                }

                setOriginalParentDivider(parent);
                setParentDividerOptions(options);
            }
        }
    }, [index, rawSections, useManualNumbering, useSectionDividers]);

    useEffect(() => {
        if (originalParentDividerIndex >= 0 && index) {
            change(`${PROJECT_SECTIONS}[${index}].${PARENT_DIVIDER}`, originalParentDividerIndex);
        }
    }, [originalParentDividerIndex, change, index]);

    const cancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const save = () => {
        if (onSave) {
            onSave(originalParentDividerIndex);
        }
    };

    if (index === null || !sections) {
        return null;
    }

    const selectedSection = sections[index];

    if (!selectedSection) {
        return null;
    }

    return (
        <SectionHeaderTitleNumberForm
            defaultSection={defaultSectionConfigsMap[selectedSection[SECTION_TYPE]]}
            disabled={false}
            member={`${PROJECT_SECTIONS}[${index}]`}
            onCancel={cancel}
            onSave={save}
            parentDividerOptions={
                selectedSection[SECTION_TYPE] !== sectionTypeNames.DIVIDER
                    ? parentDividerOptions
                    : null
            }
            showActionsOnFooter={showActionsOnFooter}
            showValidation
            useManualNumbering={useManualNumbering}
        />
    );
};

DisconnectedEditSection.propTypes = {
    change: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    // index of the section we are editing
    index: PropTypes.number.isRequired,
    showActionsOnFooter: PropTypes.bool,
};
