import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';
import { useQuery } from '@tanstack/react-query';
import { statuses } from '@og-pro/shared-config/electronicSignatures';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Button, LoadingButton, LoadingSpinner } from '../../../components';
import {
    listElectronicSignatures,
    cancelElectronicSignature,
} from '../../../actions/project/electronicSignatures';
import { showSnackbar } from '../../../actions/notification';

const { colors } = capitalDesignTokens.foundations;

export const Unfinalize = ({ clickHandler, copyProjectDocument, hideModal, project }) => {
    const dispatch = useDispatch();
    const updating = useSelector((state) => state.confirmation.get('updating'));
    const [cancelling, setCancelling] = useState(false);

    const { data: signatures, isFetching: loadingSignatures } = useQuery({
        enabled: true,
        queryKey: ['ListElectronicSignatures', project.id],
        queryFn: () => dispatch(listElectronicSignatures({ projectId: project.id })),
        retry: false,
        refetchOnWindowFocus: false,
    });

    const cancelSignatures = useCallback(async () => {
        setCancelling(true);

        try {
            // generally, we will never have more than 1 signature processing
            await Promise.all(
                signatures
                    .filter((s) => [statuses.PENDING, statuses.PROCESSING].includes(s.status))
                    .map((s) =>
                        dispatch(
                            cancelElectronicSignature({
                                projectId: project.id,
                                electronicSignatureId: s.id,
                            })
                        )
                    )
            );
            dispatch(showSnackbar('The signatures have been withdrawn successfully'));

            return clickHandler();
        } catch (e) {
            dispatch(
                showSnackbar('There was a problem withdrawing the signatures. Please try again', {
                    isError: true,
                })
            );
        } finally {
            setCancelling(false);
        }
    }, [clickHandler, dispatch, project.id, signatures]);

    if (loadingSignatures) {
        return (
            <Box py={4}>
                <LoadingSpinner noPadding size="medium" useOpenGovStyle />
            </Box>
        );
    }

    if (signatures?.some((s) => [statuses.PENDING, statuses.PROCESSING].includes(s.status))) {
        return (
            <Box sx={{ color: colors.gray700, fontSize: '1rem' }}>
                <Box sx={{ pb: 3, pt: 3 }}>
                    This document has been sent for signatures. If this document is unlocked, it
                    will be withdrawn. <b>This action cannot be undone.</b>
                </Box>
                <Box sx={{ pb: 3 }}>
                    Unlocking the document will take it back to the &quot;Review&quot; state and
                    allow the content to be edited again.
                </Box>
                <Box>Are you sure you want to continue?</Box>

                <Box display="flex" gap={2} justifyContent="center" mt={4}>
                    <Box>
                        <Button
                            bsSize="lg"
                            bsStyle="inherit"
                            disabled={cancelling}
                            onClick={() => hideModal()}
                            qaTag="unfininalizeContractDocument-cancel"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <LoadingButton
                            bsSize="lg"
                            bsStyle="danger"
                            loading={cancelling}
                            onClick={() => cancelSignatures()}
                            qaTag="unfininalizeContractDocument-withdrawSignaturesAndUnfinalize"
                            text={
                                <>
                                    <i className="fa fa-unlock-alt" /> Withdraw Signatures & Go back
                                    to Review
                                </>
                            }
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    if (signatures?.some((s) => s.status === statuses.COMPLETED)) {
        return (
            <Box sx={{ color: colors.gray700, fontSize: '1rem' }}>
                <Box sx={{ pb: 3, pt: 3 }}>
                    <b>This document has been fully signed and can no longer be unlocked.</b>
                    You may create an exact copy of this document to edit.
                </Box>
                <Box>
                    Please note: the copy will not include any new template language that may have
                    been added since the original document was drafted.
                </Box>

                <Box display="flex" gap={2} justifyContent="center" mt={4}>
                    <Box>
                        <Button
                            bsSize="lg"
                            disabled={cancelling}
                            onClick={() => hideModal()}
                            qaTag="unfininalizeContractDocument-cancel"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <LoadingButton
                            bsSize="lg"
                            bsStyle="success"
                            disabled={cancelling}
                            loading={updating}
                            onClick={() => copyProjectDocument()}
                            qaTag="unfininalizeContractDocument-copyDocument"
                            text={
                                <>
                                    <i className="fa fa-copy" /> Copy Document
                                </>
                            }
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ color: colors.gray700, fontSize: '1rem' }}>
            <Box sx={{ pb: 3, pt: 3 }}>
                Unlocking the document will take it back to the &quot;Review&quot; state and allow
                the content to be edited again.
            </Box>
            <Box sx={{ fontWeight: 600 }}>
                Only take this action if you need to edit your document.
            </Box>

            <Box display="flex" gap={2} justifyContent="center" mt={4}>
                <Box>
                    <Button
                        bsSize="lg"
                        onClick={() => hideModal()}
                        qaTag="unfininalizeContractDocument-cancel"
                    >
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <LoadingButton
                        bsSize="lg"
                        bsStyle="warning"
                        loading={updating}
                        onClick={() => clickHandler()}
                        qaTag="unfininalizeContractDocument-unfinalize"
                        text={
                            <>
                                <i className="fa fa-unlock-alt" /> Go Back to Review
                            </>
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

Unfinalize.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    copyProjectDocument: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};
