import React from 'react';
import { reduxForm } from 'redux-form';

import { formConfig } from '.';
import { useOpenFiscalPeriodsWithCalendarYear } from '../../../lib/ogFinancials';

// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withCustomPropsToReduxForm = (ReduxFormWrappedComponent) => {
    return (props) => {
        // Pass `endsInPurchaseOrder` as a prop to reduxForm HOC so that redux-form `validate` can use the prop.
        // Prop will also be available to the `ReduxFormWrappedComponent`
        const { data: fiscalPeriods } = useOpenFiscalPeriodsWithCalendarYear({
            enabled: props.endsInPurchaseOrder, // eslint-disable-line react/prop-types
        });

        const reduxFormProps = {
            fiscalPeriods,
        };

        return <ReduxFormWrappedComponent {...props} {...reduxFormProps} />;
    };
};

// Wraps a component with the redux-form decorator configured for the `requisitionsCreate` form
export const withRequisitionConfirmationCreateForm = () => (WrappedComponent) =>
    withCustomPropsToReduxForm(reduxForm(formConfig)(WrappedComponent));
