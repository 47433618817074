import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { LoadingSpinner } from '../../../../../components';
import { loadContractAssembledDocumentStatusHistory } from '../../../../../actions/contracts';
import { StatusHistoryLineItem } from './LineItem';

const { colors } = capitalDesignTokens.foundations;

export const StatusHistory = ({ contractDocument }) => {
    const { contractId } = useParams();
    const dispatch = useDispatch();
    const { error, isFetching, data } = useQuery({
        queryKey: ['ContractDocumentStatusHistory', contractDocument.id, contractId],
        queryFn: () =>
            dispatch(loadContractAssembledDocumentStatusHistory(contractId, contractDocument.id)),
        retry: false,
        refetchOnWindowFocus: false,
    });

    if (isFetching) {
        return (
            <Box py={4}>
                <LoadingSpinner noPadding size="small" useOpenGovStyle />
            </Box>
        );
    }

    if (error) {
        return (
            <Box py={4}>
                <Typography>Failed to load status history</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ color: colors.gray1000, fontWeight: 400 }}>
            {data?.reverse().map((item) => {
                return (
                    <Box key={`${item.date}${item.type}`} mb={5}>
                        <StatusHistoryLineItem item={item} />
                    </Box>
                );
            })}
        </Box>
    );
};

StatusHistory.propTypes = {
    contractDocument: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
    }).isRequired,
};
