import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton } from 'react-bootstrap';
import { reset } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Button, MenuItem } from '../../../components';
import ProjectListPagination from './ProjectListPagination';
import { StatusFilterForm } from './StatusFilterForm';
import { ProjectQuickSearchForm } from './ProjectQuickSearchForm';
import { SortByFieldForm } from './SortByFieldForm';
import { docBuilderForm, form, intakeForm } from './ProjectQuickSearchForm/constants';

const ProjectListBody = ({
    children,
    initialQuickSearchFormValues,
    isDocBuilder,
    isIntake,
    onExportProjectList,
    onProjectQuickSearch,
    onProjectSetSortDirection,
    onProjectSortByField,
    onProjectStatusSearch,
    paginationProps,
    projectSortByFieldOptions,
    projectStatusOptions,
    resetFilters,
    sort,
    sortDirection,
    status,
}) => {
    const didMount = useRef(false);
    const dispatch = useDispatch();

    const getFormName = () => {
        if (isDocBuilder) return docBuilderForm;
        if (isIntake) return intakeForm;

        return form;
    };

    // TODO: Update production Webpack config to allow ES6-style stylesheet imports
    const styles = require('./ProjectListBody.scss');

    const handleChange = (formValues) => {
        // Prevent onProjectQuickSearch invocation on mount
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        onProjectQuickSearch(formValues);
    };

    const renderSortDirectionButton = (direction) => {
        return direction === 'DESC' ? (
            <i className="fa fa-sort-amount-desc" />
        ) : (
            <i className="fa fa-sort-amount-asc" />
        );
    };

    const onReset = () => {
        dispatch(reset(getFormName()));
        resetFilters();
    };

    return (
        <>
            <div className="row">
                <div className="col-xs-12">
                    <ProjectQuickSearchForm
                        form={getFormName()}
                        hideLineItemSearch={isIntake}
                        initialValues={initialQuickSearchFormValues}
                        onChange={handleChange}
                        placeholder={
                            isDocBuilder
                                ? 'Search by title or contract ID'
                                : 'Search by title, project ID or contract ID'
                        }
                    />
                </div>
            </div>
            <div className={`row ${styles.projectListTopNav} row-no-gutters`}>
                <div className="col-xs-12 col-lg-8">
                    <div className={styles.sortAndFilterContainer}>
                        <span className={`hidden-xs hidden-sm ${styles.projectListTopNavLabel}`}>
                            Sort By
                        </span>
                        <span className={styles.projectListTopNavIcon}>
                            <i className="fa fa-sort" />
                        </span>
                        <span className={styles.sortOptionsAndDirectionsContainer}>
                            <SortByFieldForm
                                formClassName={styles.sortByFieldForm}
                                initialValues={{ sort }}
                                onProjectSortByField={onProjectSortByField}
                                options={projectSortByFieldOptions}
                            />
                            <DropdownButton
                                bsSize="sm"
                                className={styles.sortByDirectionDropdown}
                                id="sort-direction-button"
                                onSelect={(direction) => onProjectSetSortDirection(direction)}
                                title={renderSortDirectionButton(sortDirection)}
                            >
                                <MenuItem eventKey="ASC" qaTag="projects-sortAscending">
                                    <i className="fa fa-arrow-up" /> Ascending
                                </MenuItem>
                                <MenuItem eventKey="DESC" qaTag="projects-sortDescending">
                                    <i className="fa fa-arrow-down" /> Descending
                                </MenuItem>
                            </DropdownButton>
                        </span>
                        <span className={`hidden-xs hidden-sm ${styles.projectListTopNavLabel}`}>
                            Filter
                        </span>
                        <span className={styles.projectListTopNavIcon}>
                            <i className="fa fa-filter" />
                        </span>
                        <StatusFilterForm
                            formClassName={styles.statusFilterForm}
                            initialValues={{ status: status || 'all' }}
                            onChange={onProjectStatusSearch}
                            options={projectStatusOptions}
                        />
                        {!!resetFilters && (
                            <Button
                                bsSize="sm"
                                onClick={() => onReset()}
                                qaTag="projects-resetFilters"
                            >
                                <i className="fa fa-rotate-right" /> Reset All Filters
                            </Button>
                        )}
                    </div>
                </div>
                <div className={`${styles.paginationAndExportContainer} col-xs-12 col-lg-4`}>
                    <div>
                        <ProjectListPagination {...paginationProps} displayText />
                    </div>
                    <Button bsSize="sm" onClick={onExportProjectList} qaTag="projects-export">
                        <i className="fa fa-download" /> Export
                    </Button>
                </div>
            </div>
            <div>{children}</div>
            <div className="text-center">
                <ProjectListPagination {...paginationProps} />
            </div>
        </>
    );
};

ProjectListBody.propTypes = {
    children: PropTypes.node,
    initialQuickSearchFormValues: PropTypes.object,
    isDocBuilder: PropTypes.bool,
    isIntake: PropTypes.bool,
    onExportProjectList: PropTypes.func.isRequired,
    onProjectQuickSearch: PropTypes.func.isRequired,
    onProjectSetSortDirection: PropTypes.func.isRequired,
    onProjectSortByField: PropTypes.func.isRequired,
    onProjectStatusSearch: PropTypes.func.isRequired,
    paginationProps: PropTypes.shape({
        ...ProjectListPagination.propTypes,
    }).isRequired,
    projectSortByFieldOptions: PropTypes.array.isRequired,
    projectStatusOptions: PropTypes.array.isRequired,
    resetFilters: PropTypes.func,
    sort: PropTypes.string,
    sortDirection: PropTypes.string,
    status: PropTypes.string,
};

export default ProjectListBody;
