import React from 'react';

import { ReduxFormTextField } from '@og-pro/ui';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { CHARACTER_LIMIT, NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const LongTextPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];
    const characterLimit = customFieldDefinition[CHARACTER_LIMIT];
    return (
        <Field
            {...(characterLimit !== 'None' && { characterLimit })}
            component={ReduxFormTextField}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            fullWidth
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            multiline
            name="longText_PREVIEW"
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

LongTextPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
