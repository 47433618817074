const { isNil } = require('lodash');

const { listToDict } = require('../helpers');

exports.operators = ['eq'];

exports.operatorsDict = listToDict(exports.operators);

exports.checkConditionalStatement = function (conditionalStatement, customFormData) {
    if (isNil(customFormData)) {
        return false;
    }

    const operandObjectKey = `field${conditionalStatement.operand_custom_field_id}`;
    const value = customFormData[operandObjectKey];
    switch (conditionalStatement.operator) {
        case exports.operatorsDict.EQ: {
            return value === conditionalStatement.operandValue;
        }
        default:
            return false;
    }
};
