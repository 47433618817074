import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';

import { RequestTypeGroupSequences } from './RequestTypeGroupSequences';
import { fieldNames, requestTypeGroupSequenceFieldNames } from '../constants';
import { replaceReviewGroupPositions } from '../helpers';
import { RequisitionGroupPositionsField } from '../RequisitionGroupPositionsField';
import { getRequisitionGroupFormValues } from '../../selectors';
import { Button } from '../../../../../../components';
import { useGetRequestTypeOptions, useGetSelectedReviewSequences } from './hooks';

const { REQUEST_TYPE_GROUP_SEQUENCES, REVIEW_GROUP_POSITIONS } = fieldNames;
const { REQUEST_TYPE_ID } = requestTypeGroupSequenceFieldNames;

export const RequestTypesSection = ({
    change,
    disabled,
    fields,
    fields: { push: fieldsPush },
    meta: { error: arrayError },
    showFormValidation,
}) => {
    const formValues = useSelector(getRequisitionGroupFormValues);

    const requestTypeGroupSequencesFormValues = formValues[REQUEST_TYPE_GROUP_SEQUENCES];
    const requestTypeOptions = useGetRequestTypeOptions(requestTypeGroupSequencesFormValues);
    const selectedReviewSequences = useGetSelectedReviewSequences(
        requestTypeGroupSequencesFormValues
    );
    const hasReviewGroupPositions = formValues[REVIEW_GROUP_POSITIONS]?.length > 0;

    const addRequestTypeGroupSequence = useCallback(() => fieldsPush({}), [fieldsPush]);

    // Adds a request type group sequence if the form does not have one already
    useEffect(() => {
        if (fields.length === 0 && requestTypeOptions.length > 0) {
            addRequestTypeGroupSequence();
        }
    }, [fields.length, requestTypeOptions, addRequestTypeGroupSequence]);

    useEffect(() => {
        const currentReviewGroupPositionFormValues = get(formValues, REVIEW_GROUP_POSITIONS) || [];
        const newReviewGroupPosition = replaceReviewGroupPositions(
            currentReviewGroupPositionFormValues,
            selectedReviewSequences
        );
        change(REVIEW_GROUP_POSITIONS, newReviewGroupPosition);
        // Hook only needs to run when selectedReviewSequences changes
    }, [selectedReviewSequences]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box component="h4" sx={{ paddingBottom: 2 }}>
                Request Types and Sequences
            </Box>
            {fields.map((field, index) => {
                return (
                    <RequestTypeGroupSequences
                        change={change}
                        disabled={disabled}
                        field={field}
                        key={field}
                        removeHandler={() => fields.remove(index)}
                        requestTypeId={fields.get(index)[REQUEST_TYPE_ID]}
                        requestTypeOptions={requestTypeOptions}
                        showFormValidation={showFormValidation}
                        showRemoveButton={fields.length > 1}
                    />
                );
            })}
            {fields.length < requestTypeOptions.length && (
                <Box sx={{ paddingBottom: 2 }}>
                    <Button
                        bsStyle="link"
                        onClick={addRequestTypeGroupSequence}
                        qaTag="requisitionGroupEdit-addRequestType"
                    >
                        <i className="fa fa-plus" /> Add Another Request Type
                    </Button>
                </Box>
            )}
            {hasReviewGroupPositions && (
                <Box
                    sx={{
                        borderTop: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                        paddingBottom: 2,
                        paddingTop: 2,
                    }}
                >
                    <Box component="h4" sx={{ paddingBottom: 0.5 }}>
                        Group Positions
                    </Box>
                    <div className="text-muted">
                        Define who should be assigned to a step in the request type(s).
                    </div>
                </Box>
            )}
            <FieldArray
                change={change}
                component={RequisitionGroupPositionsField}
                disabled={disabled}
                name={REVIEW_GROUP_POSITIONS}
                showFormValidation={showFormValidation}
            />
            {showFormValidation && !!arrayError && <div className="error-block">{arrayError}</div>}
        </>
    );
};

RequestTypesSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
    }).isRequired,
    showFormValidation: PropTypes.bool,
};
