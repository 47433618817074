import React, { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
    ArrowBack,
    ContentCopy as ContentCopyIcon,
    Inventory as InventoryIcon,
    Undo as UndoIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
    Box,
    Button,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@og-pro/ui';

import {
    createCustomFieldFormName,
    customFieldFields,
    fieldDataTypes,
    fieldStatusesDict,
    fieldTypesArray,
} from '@og-pro/shared-config/customFormService/customField';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, omit } from 'lodash';

import { getFormSyncErrors, getFormValues, initialize } from 'redux-form';

import { showSnackbar } from '../../../../actions/notification';

import { getCustomFieldsLibraryPath } from '../selectors';
import CustomFieldsModal from '../CustomFieldsLibrary/CustomFieldsModal';
import { CustomFieldStatusTag } from './CustomFieldStatusTag';
import { CUSTOM_FORM_SERVICE_QUERY_PREFIX } from '../../../../lib/customFormService/useCustomFormService';
import { countFormErrors } from '../../../../helpers/formErrors';
import { CreateCustomFieldContext } from './context';
import CustomFieldsExitConfirmationModal from '../CustomFieldsLibrary/CustomFieldsExitConfirmationModal';
import { getCreateCustomFieldInitialFormValues } from './helpers';
import { LoadingSpinner } from '../../../../components';

const { STATUS, ID, FIELD_TYPE, NAME, SHORT_TITLE, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } =
    customFieldFields;

const responsiveButtonStyle = {
    flexGrow: {
        md: 0,
        xs: 1,
    },
};

function omitPreviewFormValues(formValues) {
    // omit all the values of the formValues object that have the _PREVIEW prefix
    return omit(
        formValues,
        Object.keys(formValues).filter((key) => key.includes('_PREVIEW'))
    );
}

const ActionsMenu = ({ handleSubmit, handleArchiveClick, handleCopyClick, disabled }) => {
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const canBeArchived = formValues[STATUS] !== fieldStatusesDict.PUBLISHED;

    return (
        <DropdownMenu
            color="secondary"
            label="Actions"
            sx={responsiveButtonStyle}
            variant="outlined"
        >
            <Box sx={{ maxWidth: '363px' }}>
                <ListItem disablePadding>
                    <ListItemButton
                        disabled={disabled}
                        onClick={handleSubmit(handleCopyClick)}
                        qaTag="requisitionsAdminCustomFields-copy"
                    >
                        <ListItemIcon>
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Copy this Field"
                            secondary="A new draft field will be created from this one."
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        disabled={!canBeArchived || disabled}
                        onClick={handleSubmit(handleArchiveClick)}
                        qaTag="requisitionsAdminCustomFields-archive"
                    >
                        <ListItemIcon>
                            <InventoryIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Archive this Field"
                            secondary="Remove this field from the active list so it cannot be added to any request type."
                        />
                    </ListItemButton>
                </ListItem>
            </Box>
        </DropdownMenu>
    );
};

ActionsMenu.propTypes = {
    disabled: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleArchiveClick: PropTypes.func.isRequired,
    handleCopyClick: PropTypes.func.isRequired,
};

const HeaderContainer = ({ children, isLoading }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [openChangeFieldTypeModal, setOpenChangeFieldTypeModal] = useState(false);
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const fieldType = formValues[FIELD_TYPE];
    const isExisting = formValues[ID] !== undefined;

    const [openExitConfirmationModal, setOpenExitConfirmationModal] = useState(false);

    const customFieldsLibraryPath = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );

    function handleBackToLibraryClick() {
        setOpenExitConfirmationModal(true);
    }

    function handleOpenChangeFieldTypeModal() {
        setOpenChangeFieldTypeModal(true);
    }

    function handleCloseChangeFieldTypeModal() {
        setOpenChangeFieldTypeModal(false);
    }

    function onFieldTypeClick(selectedFieldType) {
        const { initialValues } = getCreateCustomFieldInitialFormValues(selectedFieldType);

        const initialFormValues = {
            ...initialValues,
            [FIELD_TYPE]: selectedFieldType,
            [NAME]: formValues[NAME],
            [SHORT_TITLE]: formValues[SHORT_TITLE],
            [INSTRUCTIONS_MODE]: formValues[INSTRUCTIONS_MODE],
            [INSTRUCTIONS_TEXT]: formValues[INSTRUCTIONS_TEXT],
        };

        dispatch(initialize(createCustomFieldFormName, initialFormValues));
    }

    return (
        <Box>
            <Box>
                <Button
                    color="secondary"
                    onClick={handleBackToLibraryClick}
                    qaTag="requisitionsAdminCustomFields-back"
                    startIcon={<ArrowBack />}
                    sx={{
                        padding: 0,
                    }}
                    variant="text"
                >
                    Back to Library of Custom Fields
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                    flexDirection: {
                        md: 'row',
                        xs: 'column',
                    },
                    gap: 3,
                }}
            >
                <Box
                    alignItems="end"
                    display="flex"
                    gap={1}
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    {isExisting ? (
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                            variant="h2"
                        >
                            {fieldDataTypes[fieldType].label} Field
                            <CustomFieldStatusTag status={formValues[STATUS]} />
                        </Typography>
                    ) : (
                        <Typography variant="h2">
                            Create a New {fieldDataTypes[fieldType].label} Field
                        </Typography>
                    )}
                    {!isExisting && (
                        <>
                            <Button
                                onClick={handleOpenChangeFieldTypeModal}
                                qaTag="requisitionsAdminCustomFields-changeFieldType"
                                size="small"
                            >
                                Change Field Type
                            </Button>
                            <CustomFieldsModal
                                onClose={handleCloseChangeFieldTypeModal}
                                onFieldTypeClick={onFieldTypeClick}
                                open={openChangeFieldTypeModal}
                            />
                        </>
                    )}
                </Box>
                <Box
                    display="flex"
                    gap={1}
                    sx={{
                        width: {
                            md: 'auto',
                            xs: '100%',
                        },
                    }}
                >
                    {isLoading && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LoadingSpinner noPadding size="extraSmall" useOpenGovStyle />
                        </Box>
                    )}
                    {children}
                </Box>
            </Box>
            <CustomFieldsExitConfirmationModal
                handleNavigate={() => navigate(customFieldsLibraryPath)}
                onClose={() => setOpenExitConfirmationModal(false)}
                open={openExitConfirmationModal}
            />
        </Box>
    );
};

HeaderContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export const CreateCustomFieldHeader = ({
    fieldType,
    handleSubmit,
    createCustomFieldMutation,
    copyCustomFieldMutation,
    updateCustomFieldMutation,
}) => {
    const params = useParams();
    const dispatch = useDispatch();
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};
    const queryClient = useQueryClient();
    const formErrors = useSelector((state) => getFormSyncErrors(createCustomFieldFormName)(state));
    const { setShowFormValidation, setFormErrorsCount } = useContext(CreateCustomFieldContext);

    const navigate = useNavigate();
    const customFieldsLibraryPath = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );
    const isExisting = formValues[ID] !== undefined;
    const isLoading =
        !!createCustomFieldMutation.isLoading ||
        !!updateCustomFieldMutation.isLoading ||
        !!copyCustomFieldMutation.isLoading;

    const [openModal, setOpenModal] = useState(false);

    function handleBackToLibraryClick() {
        setOpenModal(true);
    }

    function submitCreateField(formValuesArg, status) {
        const formData = omitPreviewFormValues(formValuesArg);

        return createCustomFieldMutation
            .mutateAsync({
                ...formData,
                fieldType,
                status,
            })
            .then((response) => {
                const { createCustomField } = response;
                setShowFormValidation(false);
                if (status === fieldStatusesDict.DRAFT) {
                    navigate(`${customFieldsLibraryPath}/${createCustomField.id}`);
                } else {
                    navigate(customFieldsLibraryPath);
                }
                return true;
            })
            .catch(() => {
                dispatch(
                    showSnackbar(formData[NAME], { isError: true, title: 'Error Creating Field' })
                );
                return false;
            });
    }

    function handleCreateFieldClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitCreateField(values, fieldStatusesDict.DRAFT).then(() => {
                dispatch(
                    showSnackbar(values.name, {
                        title: 'Success - New Field Created',
                        isSuccess: true,
                    })
                );
            })
        )();
    }

    function handleCreateAndPublishFieldClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitCreateField(values, fieldStatusesDict.PUBLISHED).then(() => {
                dispatch(
                    showSnackbar(values.name, {
                        isSuccess: true,
                        title: 'Success - Field Published',
                    })
                );
            })
        )();
    }

    function submitUpdateField(formValuesArg, status, customData = {}) {
        const formData = isEmpty(customData) ? omitPreviewFormValues(formValuesArg) : customData;
        return updateCustomFieldMutation
            .mutateAsync({
                ...formData,
                status,
            })
            .then((response) => {
                queryClient.invalidateQueries({
                    queryKey: [
                        CUSTOM_FORM_SERVICE_QUERY_PREFIX,
                        'getCustomField',
                        { customFieldId: response.editCustomField.id },
                    ],
                });
                setShowFormValidation(false);
                if (status !== fieldStatusesDict.DRAFT) {
                    navigate(customFieldsLibraryPath);
                }
                return true;
            })
            .catch(() => {
                dispatch(
                    showSnackbar(formData[NAME], { isError: true, title: 'Error Saving Field' })
                );
                return false;
            });
    }

    function handleSaveDraftClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitUpdateField(values, fieldStatusesDict.DRAFT).then(
                (success) =>
                    success &&
                    dispatch(
                        showSnackbar(values[NAME], {
                            isSuccess: true,
                            title: 'Success - Draft Saved',
                        })
                    )
            )
        )();
    }

    function handlePublishedClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitUpdateField(values, fieldStatusesDict.PUBLISHED).then(() =>
                dispatch(
                    showSnackbar(values[NAME], {
                        isSuccess: true,
                        title: 'Success - Field Published',
                    })
                )
            )
        )();
    }

    function handleArchiveClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitUpdateField(values, fieldStatusesDict.ARCHIVED, {
                id: values[ID],
            }).then(() => dispatch(showSnackbar(values[NAME], { title: 'Field Archived' })))
        )();
    }

    function handleUnarchiveClick() {
        setShowFormValidation(true);
        setFormErrorsCount(countFormErrors(formErrors));
        return handleSubmit((values) =>
            submitUpdateField(values, fieldStatusesDict.DRAFT, {
                id: values[ID],
            }).then(() => {
                dispatch(showSnackbar(values[NAME], { title: 'Field Unarchived' }));
            })
        )();
    }

    function handleCopyClick() {
        copyCustomFieldMutation.mutateAsync().then((response) => {
            const { copyCustomField } = response;
            navigate(`${customFieldsLibraryPath}/${copyCustomField.id}`);
            dispatch(
                showSnackbar(copyCustomField[NAME], {
                    isSuccess: true,
                    title: 'Success - Field Copied',
                })
            );
        });
    }

    if (!isExisting) {
        return (
            <HeaderContainer isLoading={isLoading}>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={handleBackToLibraryClick}
                    qaTag="requisitionsAdminCustomFields-cancel"
                    sx={{
                        display: {
                            md: 'inline-flex',
                            xs: 'none',
                        },
                    }}
                    variant="text"
                >
                    Cancel
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={handleCreateFieldClick}
                    qaTag="requisitionsAdminCustomFields-create"
                    sx={responsiveButtonStyle}
                    variant="outlined"
                >
                    Save Draft
                </Button>
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handleCreateAndPublishFieldClick}
                    qaTag="requisitionsAdminCustomFields-createAndPublish"
                    sx={responsiveButtonStyle}
                    variant="contained"
                >
                    Publish
                </Button>
                <CustomFieldsExitConfirmationModal
                    handleNavigate={() => navigate(customFieldsLibraryPath)}
                    onClose={() => setOpenModal(false)}
                    open={openModal}
                />
            </HeaderContainer>
        );
    }

    if (formValues[STATUS] === fieldStatusesDict.DRAFT) {
        return (
            <HeaderContainer isLoading={isLoading}>
                <ActionsMenu
                    disabled={isLoading}
                    handleArchiveClick={handleArchiveClick}
                    handleCopyClick={handleCopyClick}
                    handleSubmit={handleSubmit}
                />
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handleSaveDraftClick}
                    qaTag="requisitionsAdminCustomFields-saveDraft"
                    sx={responsiveButtonStyle}
                    variant="outlined"
                >
                    Save Draft
                </Button>
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handlePublishedClick}
                    qaTag="requisitionsAdminCustomFields-publish"
                    sx={responsiveButtonStyle}
                    variant="contained"
                >
                    Publish
                </Button>
            </HeaderContainer>
        );
    }

    if (formValues[STATUS] === fieldStatusesDict.PUBLISHED) {
        return (
            <HeaderContainer isLoading={isLoading}>
                <ActionsMenu
                    disabled={isLoading}
                    handleArchiveClick={handleArchiveClick}
                    handleCopyClick={handleCopyClick}
                    handleSubmit={handleSubmit}
                />
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handlePublishedClick}
                    qaTag="requisitionsAdminCustomFields-publishChanges"
                    sx={responsiveButtonStyle}
                    variant="contained"
                >
                    Publish Changes
                </Button>
            </HeaderContainer>
        );
    }

    if (formValues[STATUS] === fieldStatusesDict.ARCHIVED) {
        return (
            <HeaderContainer isLoading={isLoading}>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={handleUnarchiveClick}
                    qaTag="requisitionsAdminCustomFields-unarchive"
                    startIcon={<UndoIcon />}
                    sx={responsiveButtonStyle}
                    variant="outlined"
                >
                    Unarchive
                </Button>
            </HeaderContainer>
        );
    }

    return <HeaderContainer />;
};

CreateCustomFieldHeader.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    createCustomFieldMutation: PropTypes.object.isRequired,
    updateCustomFieldMutation: PropTypes.object.isRequired,
    copyCustomFieldMutation: PropTypes.object.isRequired,
};
