import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    ALL_SECTIONS,
    fieldNames,
    FORM_SECTIONS_KEY,
    formSectionNames,
    formSections,
    incompleteSectionInstructions,
    incompleteSectionInstructionsOld,
} from './constants';
import { RequestTypeGroupSequences } from './RequestTypeGroupSequence';
import { RequisitionGroupCreators } from './RequisitionGroupCreators';
import { RequisitionGroupInfo } from './RequisitionGroupInfo';
import { RequisitionGroupSequence } from './RequisitionGroupSequence';
import { SectionContainer, TemplateReviewSection } from '../../../../../components';
import { RequisitionGroupInfoSection } from './RequisitionGroupInfoSection';
import { RequisitionGroupCreatorsSection } from './RequisitionGroupCreatorsSection';
import { RequisitionGroupRequestTypesSection } from './RequisitionGroupRequestTypesSection';

const {
    ALLOW_OVER_BUDGET,
    CREATOR_IDS,
    LEADER_IDS,
    REQUEST_TYPE_GROUP_SEQUENCES,
    REVIEW_GROUP_POSITIONS,
    REVIEW_SEQUENCE,
} = fieldNames;
const { CREATORS, INFO, SEQUENCE } = formSectionNames;

export const RequisitionGroupForm = ({
    change,
    disabled,
    isPublished,
    requisitionGroupFormValues,
    setShowFormValidation,
    showFormValidation,
    toggleLive,
    updateRequisitionGroup,
}) => {
    const [openSectionNumber, setOpenSectionNumber] = useState(null);
    const isCustomFieldsFlagEnabled = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    const openSectionHandler = (sectionNumber, shouldShowFormValidation = false) => {
        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSectionNumber ? null : sectionNumber;
        setOpenSectionNumber(newSectionNumber);
        setShowFormValidation(shouldShowFormValidation);
    };

    const formSectionProps = {
        change,
        disabled,
        showFormValidation,
    };

    return (
        <>
            <Field
                component={SectionContainer}
                info={<RequisitionGroupInfo requisitionGroup={requisitionGroupFormValues} />}
                isOpen={openSectionNumber === 1}
                name={`${FORM_SECTIONS_KEY}.${INFO}`}
                number={1}
                onClick={openSectionHandler}
                title="Design your group"
            >
                <RequisitionGroupInfoSection {...formSectionProps} />
            </Field>
            <Field
                component={SectionContainer}
                info={
                    <RequisitionGroupCreators
                        creatorIds={get(requisitionGroupFormValues, CREATOR_IDS, [])}
                        leaderIds={get(requisitionGroupFormValues, LEADER_IDS, [])}
                    />
                }
                isOpen={openSectionNumber === 2}
                name={`${FORM_SECTIONS_KEY}.${CREATORS}`}
                number={2}
                onClick={openSectionHandler}
                title="Assign request creators"
            >
                <RequisitionGroupCreatorsSection
                    allowOverBudget={get(requisitionGroupFormValues, ALLOW_OVER_BUDGET)}
                    creatorIds={get(requisitionGroupFormValues, CREATOR_IDS, [])}
                    {...formSectionProps}
                />
            </Field>
            <Field
                component={SectionContainer}
                info={
                    isCustomFieldsFlagEnabled ? (
                        <RequestTypeGroupSequences
                            positions={
                                get(requisitionGroupFormValues, REVIEW_GROUP_POSITIONS) || []
                            }
                            requestTypeGroupSequences={get(
                                requisitionGroupFormValues,
                                REQUEST_TYPE_GROUP_SEQUENCES,
                                []
                            )}
                        />
                    ) : (
                        <RequisitionGroupSequence
                            positions={
                                get(requisitionGroupFormValues, REVIEW_GROUP_POSITIONS) || []
                            }
                            sequence={get(requisitionGroupFormValues, REVIEW_SEQUENCE)}
                        />
                    )
                }
                isOpen={openSectionNumber === 3}
                name={`${FORM_SECTIONS_KEY}.${SEQUENCE}`}
                number={3}
                onClick={openSectionHandler}
                title={
                    isCustomFieldsFlagEnabled
                        ? 'Select request type(s)'
                        : 'Select approval sequence'
                }
            >
                <RequisitionGroupRequestTypesSection {...formSectionProps} />
            </Field>
            {!isPublished && (
                <Field
                    component={SectionContainer}
                    isOpen={openSectionNumber === 4}
                    name={ALL_SECTIONS}
                    number={4}
                    onClick={openSectionHandler}
                    title="Review your group and set it live"
                >
                    <Field
                        component={TemplateReviewSection}
                        disabled={disabled}
                        formSectionKey={FORM_SECTIONS_KEY}
                        formSections={formSections}
                        incompleteSectionInstructions={
                            isCustomFieldsFlagEnabled
                                ? incompleteSectionInstructions
                                : incompleteSectionInstructionsOld
                        }
                        name={ALL_SECTIONS}
                        openSectionHandler={openSectionHandler}
                        readyText="This group will be available for use by your team as soon as it's live"
                        saveHandler={updateRequisitionGroup}
                        setLiveHandler={toggleLive}
                    />
                </Field>
            )}
        </>
    );
};

RequisitionGroupForm.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isPublished: PropTypes.bool.isRequired,
    requisitionGroupFormValues: PropTypes.object.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    toggleLive: PropTypes.func.isRequired,
    updateRequisitionGroup: PropTypes.func.isRequired,
};
