import React from 'react';

import { ReduxFormCheckboxGroup } from '@og-pro/ui';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { ENUM_OPTIONS, NAME, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const MultipleChoicePreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT] || '';
    const defaultDescription = 'Select all that apply.';

    const multipleChoiceOption = customFieldDefinition[ENUM_OPTIONS] || [];

    const multipleChoiceOptionArray = multipleChoiceOption.map((option, index) => ({
        value: option || `Option ${index + 1}`,
        label: option || `Option ${index + 1}`,
    }));

    const description =
        customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION && instructionsText ? (
            <>
                {instructionsText}
                <br />
                {defaultDescription}
            </>
        ) : (
            defaultDescription
        );

    const tooltip =
        customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined;

    return (
        <Field
            component={ReduxFormCheckboxGroup}
            description={description}
            disabled
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="multipleChoice_PREVIEW"
            options={multipleChoiceOptionArray}
            sx={{
                width: '100%',
            }}
            tooltip={tooltip}
        />
    );
};

MultipleChoicePreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
