import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Field, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { fieldNames, form } from './constants';
import { Button, ExpectedPurchaseOrderDateField, SearchSelect, UserProfilePicture } from '../..';
import { getActiveUsersSelectOptions } from '../../../containers/selectors';
import { useFiscalPeriodFormDisplay } from '../../../lib/ogFinancials';
import { validate } from './validate';
import { getLiveUserRequisitionGroups } from '../../../selectors/govApp';
import { withRequisitionConfirmationCreateForm } from './RequisitionConfirmationCreateFormHOC';

const {
    EXPECTED_PURCHASE_ORDER_DATE,
    REQUESTOR_ID,
    REVIEW_GROUP_ID,
    CREATOR_ID,
    FISCAL_PERIOD_OBJECT,
    FISCAL_PERIOD,
    FISCAL_PERIOD_TAG_ID,
    TEMPLATE_REQUEST_TYPE_ID,
} = fieldNames;

export const formConfig = {
    form,
    validate,
};

/**
 * Calculate the initial expected purchase order date based on fiscal periods range.
 *
 * @param {Object} fiscalPeriodsRange - An object containing start and end dates of fiscal periods.
 * @param {Date} fiscalPeriodsRange.start - The start date of the fiscal period.
 * @param {Date} fiscalPeriodsRange.end - The end date of the fiscal period.
 * @returns {Date} The initial expected purchase order date.
 */
const getInitialExpectedPurchaseOrderDate = (fiscalPeriodsRange) => {
    const today = new Date();
    let initialExpectedPurchaseOrderDate = today;

    if (today > fiscalPeriodsRange.end) {
        initialExpectedPurchaseOrderDate = fiscalPeriodsRange.end;
    } else if (today < fiscalPeriodsRange.start) {
        initialExpectedPurchaseOrderDate = fiscalPeriodsRange.start;
    }

    return initialExpectedPurchaseOrderDate;
};

const ConnectedRequisitionConfirmationCreateForm = ({ change, endsInPurchaseOrder }) => {
    const dispatch = useDispatch();

    const [editFields, setEditFields] = useState({
        [REQUESTOR_ID]: false,
        [REVIEW_GROUP_ID]: false,
        [TEMPLATE_REQUEST_TYPE_ID]: false,
        [CREATOR_ID]: false,
        [EXPECTED_PURCHASE_ORDER_DATE]: false,
        [FISCAL_PERIOD_OBJECT]: false,
        [FISCAL_PERIOD]: false,
        [FISCAL_PERIOD_TAG_ID]: false,
    });

    const formValues = useSelector((state) => getFormValues(form)(state) || {});
    const userSelectOptions = useSelector((state) => getActiveUsersSelectOptions(state));

    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    const styles = require('./index.scss');

    const {
        isError,
        openFiscalPeriodsRange,
        isLoadingFiscalPeriodsRange,
        isLoadingFiscalPeriods,
        fmsFiscalPeriod,
        handleExpectedPurchaseOrderDateChange,
        fiscalYearSelectOptions,
    } = useFiscalPeriodFormDisplay(form, FISCAL_PERIOD_OBJECT, FISCAL_PERIOD);

    const isLoading = isLoadingFiscalPeriodsRange || isLoadingFiscalPeriods;

    if (
        isLoading === false &&
        openFiscalPeriodsRange &&
        !formValues[EXPECTED_PURCHASE_ORDER_DATE]
    ) {
        const initialExpectedPurchaseOrderDate =
            getInitialExpectedPurchaseOrderDate(openFiscalPeriodsRange);

        dispatch(change(EXPECTED_PURCHASE_ORDER_DATE, initialExpectedPurchaseOrderDate));
        handleExpectedPurchaseOrderDateChange(initialExpectedPurchaseOrderDate);
    }

    const renderFormDisplay = ({ name }) => {
        const userId = get(formValues, name);
        const selectedUser = userSelectOptions.find((userOption) => userOption.value === userId);

        if (!selectedUser) {
            return 'None';
        }

        return (
            <span>
                <UserProfilePicture horizontal user={selectedUser.user} />
                &nbsp;&nbsp;
                {selectedUser.label}
            </span>
        );
    };

    const renderFormField = ({ help, name, optional = false, userOptions }) => {
        if (!editFields[name]) {
            return (
                <>
                    {renderFormDisplay({ name })}
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={styles.editButton}
                        onClick={() =>
                            setEditFields((prevState) => ({ ...prevState, [name]: true }))
                        }
                        qaTag={`projectCreateModal-edit-${name}`}
                    >
                        <i className="fa fa-pencil" /> Edit
                    </Button>
                </>
            );
        }

        return (
            <Field
                backspaceRemovesValue={optional}
                component={SearchSelect}
                help={help}
                isClearable={optional}
                name={name}
                onChange={() => setEditFields((prevState) => ({ ...prevState, [name]: false }))}
                options={userOptions}
                placeholder="Select user or start typing a name"
            />
        );
    };

    const reviewGroups = useSelector((state) => getLiveUserRequisitionGroups(state));

    const reviewGroup = useMemo(() => {
        const reviewGroupId = get(formValues, REVIEW_GROUP_ID);
        return reviewGroups.find((group) => group.id === reviewGroupId);
    }, [formValues, reviewGroups]);

    const requestorOptions = useMemo(() => {
        const creatorIdsSet = new Set(reviewGroup.creators.map(({ id }) => id));
        return userSelectOptions.filter(({ value }) => creatorIdsSet.has(value));
    }, [reviewGroup]);

    const requestType = useMemo(() => {
        const requestTypeId = get(formValues, TEMPLATE_REQUEST_TYPE_ID);
        return reviewGroup.requestTypeGroupSequences
            .map((requestTypeGroupSequence) => requestTypeGroupSequence.requestType)
            .filter(Boolean)
            .find((reviewGroupRequestType) => reviewGroupRequestType.id === requestTypeId);
    }, [formValues, reviewGroup]);

    return (
        <div className={styles.container}>
            <dl className="dl-horizontal">
                <dt>Creator:</dt>
                <dd>{renderFormDisplay({ name: CREATOR_ID })}</dd>

                <dt>Requestor:</dt>
                <dd>{renderFormField({ name: REQUESTOR_ID, userOptions: requestorOptions })}</dd>

                <dt>Review Group:</dt>
                <dd>{reviewGroup?.name}</dd>

                {enableCustomFields && (
                    <>
                        <dt>Request Type:</dt>
                        <dd>{requestType?.name}</dd>
                    </>
                )}

                {endsInPurchaseOrder ? (
                    <>
                        <dt>Expected Purchase Order Date:</dt>
                        <dd>
                            <ExpectedPurchaseOrderDateField
                                className={styles.datePicker}
                                hasLoadingError={isError}
                                isLoading={isLoading}
                                name={EXPECTED_PURCHASE_ORDER_DATE}
                                onChange={handleExpectedPurchaseOrderDateChange}
                                openFiscalPeriodsRange={openFiscalPeriodsRange}
                            />
                        </dd>
                        <dt>Fiscal Period:</dt>
                        <dd>{fmsFiscalPeriod}</dd>
                    </>
                ) : (
                    <>
                        <dt>Fiscal Year:</dt>
                        <dd>
                            <Field
                                component={SearchSelect}
                                hasFeedback={false}
                                help="Target Fiscal Period for the request"
                                name={FISCAL_PERIOD_TAG_ID}
                                options={fiscalYearSelectOptions}
                                placeholder="Choose a Fiscal Year..."
                            />
                        </dd>
                    </>
                )}
            </dl>
        </div>
    );
};

export const RequisitionConfirmationCreateForm = withRequisitionConfirmationCreateForm()(
    ConnectedRequisitionConfirmationCreateForm
);

ConnectedRequisitionConfirmationCreateForm.propTypes = {
    change: PropTypes.func.isRequired,
    endsInPurchaseOrder: PropTypes.bool,
};
