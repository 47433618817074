import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { LoadingError, SearchSelect } from '../../../../../components';
import { useOpenFiscalPeriodsWithCalendarYear } from '../../../../../lib/ogFinancials';
import { getIsGovernmentUsingFMS } from '../../../../../selectors/govApp';
import { fieldsDict } from './form';
import { getFiscalYearOptions } from './helpers';
import { getFiscalYearSelectOptions } from '../../../../App/selectors';

const { FISCAL_YEAR } = fieldsDict;

export const FiscalYear = () => {
    const styles = require('./FiscalYear.scss');
    const governmentHasFMS = useSelector(getIsGovernmentUsingFMS);

    const {
        data: fiscalPeriods,
        error: fiscalPeriodsError,
        isError: isFiscalPeriodsError,
        isLoading: isLoadingFiscalPeriods,
    } = useOpenFiscalPeriodsWithCalendarYear({ enabled: governmentHasFMS });

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    if (isFiscalPeriodsError) {
        return <LoadingError error={fiscalPeriodsError} useOpenGovStyle />;
    }

    if (isLoadingFiscalPeriods && governmentHasFMS) {
        return (
            <div className={styles.loadingLabel}>
                <i className="fa spinner fa-spin fa-spinner" />
                <span> Loading fiscal periods...</span>
            </div>
        );
    }

    return (
        <div className={styles.fiscalYear}>
            <Field
                component={SearchSelect}
                label="Fiscal Year"
                name={FISCAL_YEAR}
                options={
                    governmentHasFMS ? getFiscalYearOptions(fiscalPeriods) : fiscalYearSelectOptions
                }
            />
        </div>
    );
};
