import { useDispatch, useSelector } from 'react-redux';

import { getUserJS } from '../../../../containers/selectors';
import { hideLoginModal, showLoginModal } from '../../../../actions/auth';

export const useContextSwitcherOptions = () => {
    const user = useSelector(getUserJS);
    const dispatch = useDispatch();

    return {
        activeProductKey: 'procurement',
        auth0Config: {
            auth0clientID: process.env.AUTH0_UI_CLIENT_ID,
            auth0domain: process.env.AUTH0_URL,
            auth0callbackUrl: `${process.env.SITE_URL}/login`,
            onAuth0SessionExpired: () =>
                dispatch(
                    showLoginModal(
                        'Your session has timed out. Please log in again to continue working.',
                        () => dispatch(hideLoginModal()),
                        { isSessionTimeout: true }
                    )
                ),
        },
        baseUrl: process.env.OG_CONTROL_PANEL_URL ?? 'https://controlpanel.ogintegration.us',
        userUUID: user.platformUuid,
        activeEntityId: user.government.code,
        activeEntityIdType: 'BY_PROCUREMENT_ID',
    };
};
