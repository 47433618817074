import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray, getFormSyncErrors } from 'redux-form';
import ErrorIcon from '@mui/icons-material/Error';

import { useSelector } from 'react-redux';

import { Box, FormHelperText } from '@mui/material';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { fieldNames, flagResponseFieldNames, radioOptions } from '../../constants';
import { HelpBlock, RadioGroup } from '../../../../../../components';
import { formConfig } from '../../form';

const FlagResponsesFieldArray = ({ disabled, fields, showFormValidation }) => {
    const styles = require('./index.scss');

    const normalizeBooleans = (value) => {
        return !!(value === 'true' || value === true);
    };

    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));
    const flagResponsesError = formErrors?.flagResponsesSummary;

    return (
        <>
            {/* Note: the Additional Information section is not shown on the REQ creation form when fields are empty, so this empty state will never be seen on that form. But it will be seen on the request type form builder.  */}
            {fields.length === 0 && (
                <Box sx={{ pb: 2 }}>
                    <FormHelperText error>
                        <ErrorIcon
                            sx={{
                                color: capitalDesignTokens.foundations.colors.ruby700,
                            }}
                        />
                        When a flag is part of the sequence, it will show up here.
                    </FormHelperText>
                </Box>
            )}
            {fields.map((member, index) => {
                const { flag, id } = fields.get(index);
                return (
                    <div className={styles.flag} key={id}>
                        {flag.name}
                        <Field
                            className={styles.radioGroup}
                            component={RadioGroup}
                            disabled={disabled}
                            groupLabel={flag.name}
                            inline
                            name={`${member}.${flagResponseFieldNames.IS_ENABLED}`}
                            normalize={normalizeBooleans}
                            options={radioOptions}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                );
            })}
            {showFormValidation && flagResponsesError && (
                <HelpBlock className={styles.error}>
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp; {flagResponsesError}
                </HelpBlock>
            )}
        </>
    );
};

FlagResponsesFieldArray.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object,
    showFormValidation: PropTypes.bool,
};

export const AdditionalInformation = ({ disabled, showFormValidation }) => {
    const styles = require('./index.scss');

    return (
        <div>
            <h4>Are any of the following true? *</h4>
            <div className={styles.flags}>
                <FieldArray
                    component={FlagResponsesFieldArray}
                    disabled={disabled}
                    name={fieldNames.FLAG_RESPONSES}
                    showFormValidation={showFormValidation}
                />
            </div>
        </div>
    );
};

AdditionalInformation.propTypes = {
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
