import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import CustomFieldsModal from './CustomFieldsModal';

const EmptyCustomFieldState = () => {
    const [showModal, setShowModal] = React.useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                padding: 2,
            }}
        >
            <Box
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        fontWeight={capitalDesignTokens.foundations.typography.fontWeight.light}
                        variant="h1"
                    >
                        No Custom Fields
                    </Typography>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.text.secondary,
                        })}
                        variant="bodyDefault"
                    >
                        Add a new field to see it here.
                    </Typography>
                </Box>
                <Button
                    color="primary"
                    onClick={handleOpenModal}
                    startIcon={<AddIcon />}
                    variant="contained"
                >
                    New Custom Field
                </Button>
            </Box>
            {showModal && <CustomFieldsModal onClose={handleCloseModal} open={showModal} />}
        </Box>
    );
};
export default EmptyCustomFieldState;
