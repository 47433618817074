import { createSelector } from 'reselect';

import {
    CLEANUP_DOCUMENTS,
    DELETION_HISTORY,
    EXPIRED,
    POLICIES_ASSIGNED,
    RECENTLY_CLOSED,
} from './constants';

const getCleanupDocuments = (state) => state.projects.get(CLEANUP_DOCUMENTS);
const getPoliciesAssigned = (state) => state.projects.get(POLICIES_ASSIGNED);
const getRecentlyClosed = (state) => state.projects.get(RECENTLY_CLOSED);
const getExpired = (state) => state.projects.get(EXPIRED);
const getDeletionHistory = (state) => state.retention.get(DELETION_HISTORY);

export const getNavItems = createSelector([], () => {
    return [
        {
            name: 'Recently Closed (Policy Needed)',
            status: RECENTLY_CLOSED,
            to: '',
        },
        {
            name: 'Expiration Date Passed',
            status: EXPIRED,
            to: '/expired',
        },
        {
            name: 'Document Clean Up',
            status: CLEANUP_DOCUMENTS,
            to: '/documents',
        },
    ];
});

export const getCleanupDocumentsJS = createSelector(
    [getCleanupDocuments],
    (rawCleanupDocuments) => {
        if (rawCleanupDocuments) {
            return rawCleanupDocuments.toJS();
        }
        return [];
    }
);

export const getGovernmentRetentionLoading = (state) => state.retention.loadingRetentionCodes;
export const getGovernmentRetentionUpdating = (state) => state.retention.editingRetentionCode;

export const getPoliciesAssignedJS = createSelector(
    [getPoliciesAssigned],
    (rawPoliciesAssigned) => {
        if (rawPoliciesAssigned) {
            return rawPoliciesAssigned.toJS();
        }
        return [];
    }
);

export const getRecentlyClosedJS = createSelector([getRecentlyClosed], (rawRecentlyClosed) => {
    if (rawRecentlyClosed) {
        return rawRecentlyClosed.toJS();
    }
    return [];
});

export const getExpiredJS = createSelector([getExpired], (rawExpired) => {
    if (rawExpired) {
        return rawExpired.toJS();
    }
    return [];
});

export const getDeletionHistoryJS = createSelector([getDeletionHistory], (rawDeletionHistory) => {
    if (rawDeletionHistory) {
        return rawDeletionHistory.toJS();
    }
    return [];
});
