import { useSelector } from 'react-redux';

import { useOgFinancials } from './useOgFinancials';
import { glCoaSettings } from './queries';
import { getRequisitionEndsInPurchaseOrder } from '../../selectors/govApp';

const ONE_HOUR = 1000 * 60 * 60;

export const useCoaConfigLookup = () => {
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    return useOgFinancials({
        queryKey: 'glCoaSettings',
        query: glCoaSettings,
        staleTime: ONE_HOUR,
        enabled: endsInPurchaseOrder,
    });
};
