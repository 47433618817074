import PropTypes from 'prop-types';
import React from 'react';
import { Button, Chip, Typography } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';

export const CategoryCodesHeader = ({ label, selectedCategoriesCount, showModal, disabled }) => {
    const hasSelections = selectedCategoriesCount > 0;
    if (!hasSelections) {
        return (
            <Button
                color="secondary"
                disabled={disabled}
                onClick={showModal}
                startIcon={<AddIcon />}
                variant="text"
            >
                Add Category Codes
            </Button>
        );
    }

    return (
        <Typography mb={1} variant="h5">
            {label} <Chip label={selectedCategoriesCount} size="extraSmall" variant="filled" />
        </Typography>
    );
};

CategoryCodesHeader.defaultProps = {
    selectedCategoriesCount: 0,
};

CategoryCodesHeader.propTypes = {
    label: PropTypes.string.isRequired,
    selectedCategoriesCount: PropTypes.number,
    showModal: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
