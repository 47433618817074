import React, { useCallback } from 'react';

import { Box, Button, Switch, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import AddIcon from '@mui/icons-material/Add';

import { fieldTypes } from '@og-pro/shared-config/customFormService/customField';

import FormControlLabel from '@mui/material/FormControlLabel';

import { ConditionalCustomField, inColumnsStyle, useCustomFormEditor } from '.';
import { FieldTypePreview } from '../../../RequisitionsCreateCustomField/FieldTypePreview';
import { overflowdedBoxStyle } from './styles';

const { YES_NO } = fieldTypes;

export const RootCustomField = ({ rootCustomField, showEditingControls }) => {
    const {
        removeField,
        isRequired,
        toggleRequiredField,
        getCustomFieldDefinition,
        addConditionalField,
        setFocusedCustomFieldId,
    } = useCustomFormEditor();

    const rootFieldDefinition = getCustomFieldDefinition(rootCustomField.customFieldId);

    const handleAddConditionalField = useCallback(() => {
        addConditionalField(rootCustomField.customFieldId);
    }, [addConditionalField, rootCustomField.customFieldId]);

    if (!rootFieldDefinition) {
        return null;
    }

    const canHaveConditionalFields = rootFieldDefinition.fieldType === YES_NO;

    return (
        <Box
            onClick={(e) => {
                setFocusedCustomFieldId(rootCustomField.customFieldId);
                e.stopPropagation();
            }}
            sx={overflowdedBoxStyle[showEditingControls ? 'highlighted' : 'readOnly']}
        >
            <Box sx={{ ...inColumnsStyle, pb: 1 }}>
                <FieldTypePreview
                    customFieldDefinition={rootFieldDefinition}
                    fieldType={rootFieldDefinition.fieldType}
                />
                {showEditingControls && (
                    <Button
                        color="secondary"
                        onClick={() => removeField(rootCustomField.customFieldId)}
                        qaTag="customFieldOption-removeCustomField"
                        startIcon={<CloseIcon />}
                        variant="text"
                    >
                        Remove
                    </Button>
                )}
            </Box>
            {showEditingControls && (
                <Box sx={{ pl: 0.5 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isRequired(rootCustomField.customFieldId)}
                                onChange={() => {
                                    toggleRequiredField(rootCustomField.customFieldId);
                                }}
                                size="small"
                            />
                        }
                        label={<Typography variant="bodySmall">Required</Typography>}
                        labelPlacement="end"
                    />
                </Box>
            )}
            {rootCustomField.conditionalFields.map((conditionalField, index) => (
                <ConditionalCustomField
                    conditionalField={conditionalField}
                    conditionalFieldIndex={index}
                    key={index}
                    rootCustomFieldId={rootCustomField.customFieldId}
                    rootFieldOptions={rootFieldDefinition.enumOptions}
                    showEditingControls={showEditingControls}
                />
            ))}

            {canHaveConditionalFields && showEditingControls && (
                <Box
                    sx={(theme) => ({
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                        paddingTop: 2,
                        marginTop: 3,
                    })}
                >
                    <Button
                        color="primary"
                        onClick={handleAddConditionalField}
                        qaTag="customFieldOption-fieldAdd"
                        startIcon={<AddIcon />}
                        variant="text"
                    >
                        Add {rootCustomField?.conditionalFields?.length > 0 && 'Another'}{' '}
                        Conditional Field
                    </Button>
                </Box>
            )}
        </Box>
    );
};

RootCustomField.propTypes = {
    rootCustomField: PropTypes.shape({
        customFieldId: PropTypes.number,
        conditionalFields: PropTypes.arrayOf(
            PropTypes.shape({
                show_custom_field_id: PropTypes.number,
                operand_custom_field_id: PropTypes.number,
                operandValue: PropTypes.string,
                // Could be null if this is the first time the field is being created
                conditionalStatementId: PropTypes.number,
            })
        ),
    }),
    showEditingControls: PropTypes.bool,
};
