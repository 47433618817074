const {
    ThumbUpOutlined,
    DoDisturb,
    Edit,
    CheckCircle,
    Email,
    AttachMoney,
    AccessTime,
    Help,
} = require('@mui/icons-material');

const { statusTypes } = require('.');
const { reviewTypes } = require('../reviewSequences');

const DRAFTED = 0;
const SUBMITTED = 1;
const APPROVED = 2;
const ACKNOWLEDGED = 3;
const NOTIFIED = 4;
const PO_CREATED = 5;
const REJECTED = 6;
const CANCELLED = 7;
const ON_HOLD = 8;

exports.actionTypes = {
    DRAFTED,
    SUBMITTED,
    APPROVED,
    ACKNOWLEDGED,
    NOTIFIED,
    PO_CREATED,
    REJECTED,
    CANCELLED,
    ON_HOLD,
};

const successData = {
    bsStyle: 'success',
    icon: 'fa-check-circle',
    muiIcon: CheckCircle,
    backgroundColor: '#F7F9FA',
    color: '#257D34',
};

const rejectedData = {
    bsStyle: 'danger',
    icon: 'fa-ban',
    muiIcon: DoDisturb,
    color: '#992F3A',
    backgroundColor: '#FFECE8',
};

exports.actionTypesDisplayData = {
    [DRAFTED]: {
        bsStyle: 'muted',
        icon: 'fa-pencil',
        muiIcon: Edit,
        text: 'Drafted',
        backgroundColor: '#F7F9FA',
    },
    [SUBMITTED]: {
        ...successData,
        text: 'Submitted',
    },
    [APPROVED]: {
        ...successData,
        text: 'Approved',
    },
    [ACKNOWLEDGED]: {
        ...successData,
        icon: 'fa-thumbs-up',
        muiIcon: ThumbUpOutlined,
        text: 'Acknowledged',
    },
    [NOTIFIED]: {
        ...successData,
        icon: 'fa-envelope',
        muiIcon: Email,
        text: 'Notification Sent',
    },
    [PO_CREATED]: {
        ...successData,
        icon: 'fa-usd',
        muiIcon: AttachMoney,
        text: 'Purchase Order Created',
    },
    [REJECTED]: {
        ...rejectedData,
        text: 'Rejected',
    },
    [CANCELLED]: {
        ...rejectedData,
        text: 'Cancelled',
    },
    [ON_HOLD]: {
        bsStyle: 'warning',
        icon: 'fa-pause',
        muiIcon: AccessTime,
        text: 'On Hold',
        backgroundColor: '#FFEEDB',
        color: '#B06209',
    },
};

/**
 * @typedef {Object} LastActionDisplay
 * @property {string} bsStyle - Last action display style
 * @property {string} icon - Last action display icon
 * @property {node} muiIcon - Last action display MUI icon
 * @property {string} text - Last action display text
 */

/**
 * Display data for last action taken on a requisition
 * @param {object} requisition Requisition to display
 * @returns {LastActionDisplay}
 */
exports.getLastActionDisplayData = (requisition) => {
    const { endsInPurchaseOrder, isOnHold, lastStep, status } = requisition;

    const defaultData = {
        bsStyle: 'muted',
        icon: 'fa-question',
        muiIcon: Help,
        text: 'Unknown',
    };

    if (isOnHold) {
        return exports.actionTypesDisplayData[ON_HOLD];
    }

    switch (status) {
        case statusTypes.DRAFT:
            return exports.actionTypesDisplayData[DRAFTED];
        case statusTypes.REVIEW: {
            if (!lastStep) {
                return exports.actionTypesDisplayData[SUBMITTED];
            }
            if (lastStep.reviewType === reviewTypes.APPROVE) {
                return exports.actionTypesDisplayData[APPROVED];
            }
            if (lastStep.reviewType === reviewTypes.CONFIRM) {
                return exports.actionTypesDisplayData[ACKNOWLEDGED];
            }
            if (lastStep.reviewType === reviewTypes.NOTIFY) {
                return exports.actionTypesDisplayData[NOTIFIED];
            }
            return defaultData;
        }
        case statusTypes.CLOSED_PO_CREATED:
            return {
                ...exports.actionTypesDisplayData[PO_CREATED],
                ...(!endsInPurchaseOrder && {
                    text: 'Final Approval Received',
                    icon: 'fa-check-circle',
                    muiIcon: CheckCircle,
                }),
            };
        case statusTypes.DRAFT_REJECTED:
        case statusTypes.REVIEW_REJECTED:
        case statusTypes.CLOSED_REJECTED:
            return exports.actionTypesDisplayData[REJECTED];
        case statusTypes.CLOSED_CANCELLED:
            return exports.actionTypesDisplayData[CANCELLED];
        default:
            return defaultData;
    }
};
