import { fromJS } from 'immutable';

import * as retentionActions from '../actions/retention';

const initialState = fromJS({
    retentionCode: null,
    retentionCodes: [],
    loadingRetentionCode: false,
    loadRetentionCodesError: null,
    loadedRetentionCodes: false,
    loadingRetentionCodes: false,
    creatingRetentionCode: false,
    createRetentionCodeError: false,
    createdRetentionCode: false,
    editingRetentionCode: false,
    editRetentionCodeError: false,
    editedRetentionCode: false,
    deletionHistory: [],
});

export default function retentionReducer(state = initialState, action = {}) {
    switch (action.type) {
        case retentionActions.LOAD_RETENTION_CODE:
            return state.merge(
                fromJS({
                    loadingRetentionCode: true,
                })
            );
        case retentionActions.LOAD_RETENTION_CODE_FAIL:
            return state.merge(
                fromJS({
                    loadingRetentionCode: false,
                })
            );
        case retentionActions.LOAD_RETENTION_CODE_SUCCESS:
            return state.merge(
                fromJS({
                    retentionCode: fromJS(action.result),
                    loadingRetentionCode: false,
                })
            );
        case retentionActions.LOAD_GOVERNMENT_RETENTION_CODES:
            return state.merge(
                fromJS({
                    loadingRetentionCodes: true,
                    loadRetentionCodesError: null,
                })
            );
        case retentionActions.LOAD_GOVERNMENT_RETENTION_CODES_FAIL:
            return state.merge(
                fromJS({
                    loadingRetentionCodes: false,
                    loadRetentionCodesError: action.error.message,
                })
            );
        case retentionActions.LOAD_GOVERNMENT_RETENTION_CODES_SUCCESS:
            return state.merge(
                fromJS({
                    retentionCodes: fromJS(action.result),
                    loadedRetentionCodes: true,
                    loadingRetentionCodes: false,
                })
            );
        case retentionActions.ADD_NEW_POLICY:
            return state.merge(
                fromJS({
                    creatingRetentionCode: true,
                    createRetentionCodeError: null,
                })
            );
        case retentionActions.ADD_NEW_POLICY_FAIL:
            return state.merge(
                fromJS({
                    creatingRetentionCode: false,
                    createRetentionCodeError: action.error.message,
                })
            );
        case retentionActions.ADD_NEW_POLICY_SUCCESS:
            return state.merge(
                fromJS({
                    createdRetentionCode: true,
                    creatingRetentionCode: false,
                })
            );
        case retentionActions.EDIT_POLICY:
            return state.merge(
                fromJS({
                    editingRetentionCode: true,
                    editRetentionCodesError: null,
                })
            );
        case retentionActions.EDIT_POLICY_FAIL:
            return state.merge(
                fromJS({
                    editingRetentionCode: false,
                    editRetentionCodeError: action.error.message,
                })
            );
        case retentionActions.EDIT_POLICY_SUCCESS:
            return state.merge(
                fromJS({
                    editedRetentionCode: true,
                    editingRetentionCode: false,
                })
            );
        case retentionActions.LOAD_DELETION_HISTORY_SUCCESS:
            return state.merge(
                fromJS({
                    deletionHistory: action.result,
                })
            );
        default:
            return state;
    }
}
