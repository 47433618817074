import React from 'react';
import { Outlet } from 'react-router-dom';

import {
    CustomFieldsLibrary,
    RequestTypeForm,
    RequestTypeNav,
    RequestTypeReviewGroups,
    RequestTypeReviewSequences,
    RequestTypesList,
    RequisitionGroupEdit,
    RequisitionGroupsList,
    RequisitionsAdminNav,
    RequisitionsEntityPositions,
    RequisitionsFlags,
    RequisitionsGroupPositions,
    RequisitionsSettings,
    ReviewSequenceEdit,
    ReviewSequencesList,
} from '../../../containers/Requisitions';
import {
    legacyFetchData,
    requireSystemAdmin,
    requireRequisitionGroupAdmin,
    requireRequisitionsSubscription,
} from '../../loaders';
import { RequisitionsCreateCustomField } from '../../../containers/Requisitions/RequisitionsAdmin/RequisitionsCreateCustomField';
import { ArchiveCustomFieldTable } from '../../../containers/Requisitions/RequisitionsAdmin/CustomFieldsLibrary/ArchiveCustomFieldTable';

export default (getState, dispatch) => {
    return [
        {
            element: <Outlet />,
            loader: async (props) => {
                const permissionRedirect = await requireSystemAdmin(getState)(props);

                if (permissionRedirect) {
                    return permissionRedirect;
                }

                return requireRequisitionsSubscription(getState)(props);
            },
            path: 'requisitions-admin',
            children: [
                {
                    element: <RequisitionsAdminNav />,
                    children: [
                        {
                            element: <RequestTypesList />,
                            loader: async (props) => {
                                const permissionRedirect =
                                    await requireRequisitionGroupAdmin(getState)(props);

                                if (permissionRedirect) {
                                    return permissionRedirect;
                                }

                                return legacyFetchData(getState, dispatch, RequestTypesList)(props);
                            },
                            path: 'request-types',
                        },

                        {
                            element: <Outlet />,
                            loader: requireRequisitionGroupAdmin(getState),
                            path: 'review-groups',
                            children: [
                                {
                                    element: <RequisitionGroupsList />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        RequisitionGroupsList
                                    ),
                                    index: true,
                                },
                                {
                                    element: <RequisitionGroupEdit />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        RequisitionGroupEdit
                                    ),
                                    path: ':requisitionGroupId',
                                },
                            ],
                        },
                        {
                            element: <RequisitionsEntityPositions />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                RequisitionsEntityPositions
                            ),
                            index: true,
                        },
                        {
                            element: <RequisitionsEntityPositions />,
                            loader: legacyFetchData(
                                getState,
                                dispatch,
                                RequisitionsEntityPositions
                            ),
                            path: 'entity-positions',
                        },
                        {
                            element: <RequisitionsGroupPositions />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsGroupPositions),
                            path: 'group-positions',
                        },
                        {
                            element: <RequisitionsFlags />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsFlags),
                            path: 'flags',
                        },
                        {
                            element: <CustomFieldsLibrary />,
                            path: 'custom-fields',
                        },
                    ],
                },
                {
                    // Use this object for children that are still under the /requisitions-admin path
                    // but don't want to render RequisitionsAdminNav
                    children: [
                        {
                            element: <RequisitionsCreateCustomField />,
                            path: 'custom-fields/create/:fieldType',
                        },
                        {
                            element: <RequisitionsCreateCustomField />,
                            path: 'custom-fields/:customFieldId',
                        },
                        {
                            element: <ArchiveCustomFieldTable />,
                            path: 'custom-fields/archive',
                        },
                        {
                            element: <RequisitionsSettings />,
                            loader: legacyFetchData(getState, dispatch, RequisitionsSettings),
                            path: 'settings',
                        },
                    ],
                },
                {
                    element: <RequestTypeNav />,
                    loader: async (props) => {
                        const permissionRedirect =
                            await requireRequisitionGroupAdmin(getState)(props);

                        if (permissionRedirect) {
                            return permissionRedirect;
                        }

                        return legacyFetchData(getState, dispatch, RequestTypeNav)(props);
                    },
                    path: 'request-types/:requestTypeId',
                    children: [
                        {
                            element: <RequestTypeForm />,
                            index: true,
                        },
                        {
                            element: <RequestTypeForm />,
                            path: 'form',
                        },
                        {
                            element: <RequestTypeReviewSequences />,
                            path: 'sequences',
                            children: [
                                {
                                    element: <ReviewSequencesList />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        ReviewSequencesList
                                    ),
                                    index: true,
                                },
                                {
                                    element: <ReviewSequenceEdit />,
                                    loader: legacyFetchData(getState, dispatch, ReviewSequenceEdit),
                                    path: ':reviewSequenceId',
                                },
                            ],
                        },
                        {
                            element: <RequestTypeReviewGroups />,
                            path: 'review-groups',
                            children: [
                                {
                                    element: <RequisitionGroupsList />,
                                    loader: legacyFetchData(
                                        getState,
                                        dispatch,
                                        RequisitionGroupsList
                                    ),
                                    index: true,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};
