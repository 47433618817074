import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import { ReduxFormDatePicker } from '@og-pro/ui';
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const DatePreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT] || '';
    return (
        <Field
            component={ReduxFormDatePicker}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            disabled
            fullWidth
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="datePicker_PREVIEW"
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

DatePreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
