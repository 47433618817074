import { projectTypesDict } from '@og-pro/shared-config/projects';

import { REQUIRED_TEXT } from './constants';

export function financialId(values, props) {
    const errors = {};

    if (
        props.isProjectIdRequired &&
        !values.financialId &&
        !props?.project?.isIntake &&
        props?.project?.type !== projectTypesDict.CONTRACT
    ) {
        errors.financialId = REQUIRED_TEXT;
    }

    if (values.financialId && values.financialId.length > 255) {
        errors.financialId = 'Field is too long (250 chars max)';
    }

    return errors;
}
