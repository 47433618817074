import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import React from 'react';

import { fieldTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';

import { FieldTypePreview } from '../FieldTypePreview';
import { formConfig } from './form';

export const CreateCustomFieldPreview = ({ fieldType }) => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
                    borderRadius: 1,
                    border: `1px solid ${capitalDesignTokens.foundations.colors.brand50}`,
                    padding: 3,
                }}
            >
                <FieldTypePreview customFieldDefinition={formValues} fieldType={fieldType} />
            </Box>
        </Box>
    );
};

CreateCustomFieldPreview.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
};
