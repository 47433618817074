import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@og-pro/ui';
import { Settings } from '@mui/icons-material';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { NavItems } from './NavItems';
import { getRequisitionsAdminNavItems } from './selectors';
import { getRequisitionsAdminPath } from '../selectors';
import { requisitionsAdminNavContainerStyle } from './style';
import { getIsGovernmentUsingFMS } from '../../../../selectors/govApp';

export const RequisitionsAdminNav = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const hasFMS = useSelector(getIsGovernmentUsingFMS);
    const navItems = useSelector((state) =>
        getRequisitionsAdminNavItems(state, { location, enableCustomFields })
    );

    const requisitionsAdminPath = useSelector((state) =>
        getRequisitionsAdminPath(state, { params })
    );

    const handleSettingsClick = () => {
        navigate(`${requisitionsAdminPath}/settings`);
    };

    return (
        <Box
            sx={{
                flexDirection: 'column',
                flexGrow: 1,
                display: 'flex',
            }}
        >
            <Box sx={requisitionsAdminNavContainerStyle}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h1">Requests Admin</Typography>
                    {(hasFMS || !enableCustomFields) && (
                        <Button
                            color="secondary"
                            onClick={handleSettingsClick}
                            startIcon={<Settings />}
                            variant="outlined"
                        >
                            Settings
                        </Button>
                    )}
                </Box>
                <Box>
                    <NavItems items={navItems} requisitionsAdminPath={requisitionsAdminPath} />
                </Box>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    height: '100%',
                    backgroundColor: capitalDesignTokens.foundations.colors.white,
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        pt: 3,
                        pr: 8,
                        pb: 8,
                        pl: 8,
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};
