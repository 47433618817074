import React, { useContext } from 'react';

import { ReduxFormCustomForm } from '@og-pro/ui';

import { formValueSelector } from 'redux-form';

import { useSelector } from 'react-redux';

import { CustomFormSnapshotContext } from '../../CustomFormSnapshotContext';
import { fieldNames } from '../../constants';
import { form } from '../../form';

const { CUSTOM_FORM_DATA } = fieldNames;

export const ReviewCustomFieldsSection = () => {
    const customFormSnapshot = useContext(CustomFormSnapshotContext);

    const customFormData = useSelector((state) => formValueSelector(form)(state, CUSTOM_FORM_DATA));

    return (
        <ReduxFormCustomForm
            customFormData={customFormData}
            customFormSnapshot={customFormSnapshot}
            disabled
            interactive={false}
            reduxFormPrefix={CUSTOM_FORM_DATA}
        />
    );
};

ReviewCustomFieldsSection.propTypes = {};
