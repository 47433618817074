import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Tooltip as TooltipBS, Popover as PopoverBS, OverlayTrigger } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';

export class Tooltip extends PureComponent {
    static propTypes = {
        blockComponent: PropTypes.bool,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        delayShow: PropTypes.number,
        disabled: PropTypes.bool,
        isPopover: PropTypes.bool,
        placement: PropTypes.string,
        text: PropTypes.string,
        tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        tooltipId: PropTypes.string,
        tooltipTitle: PropTypes.string,
    };

    static defaultProps = {
        blockComponent: false,
        className: undefined,
        delayShow: undefined,
        disabled: false,
        isPopover: false,
        placement: undefined,
        text: undefined,
        tooltipId: UUIDv4(),
        tooltipTitle: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    renderChildComponent() {
        const { blockComponent, children, disabled } = this.props;

        if (!disabled) {
            return children;
        }

        return (
            <div
                aria-roledescription="disabled button with tooltip"
                className={classnames(
                    this.styles.disabledTooltip,
                    !blockComponent && this.styles.inlineComponent
                )}
                role="button"
                tabIndex="0"
            >
                {children}
            </div>
        );
    }

    renderTooltip() {
        const { className, isPopover, tooltip, tooltipId, tooltipTitle } = this.props;

        if (isPopover) {
            return (
                <PopoverBS
                    className={classnames(className, this.styles.wrap)}
                    id={`popover-${tooltipId}`}
                    title={tooltipTitle}
                >
                    {tooltip}
                </PopoverBS>
            );
        }

        return (
            <TooltipBS
                className={classnames(className, this.styles.wrap)}
                id={`tooltip-${tooltipId}`}
            >
                {tooltip}
            </TooltipBS>
        );
    }

    render() {
        const { blockComponent, children, className, disabled, isPopover, ...props } = this.props;

        return (
            <OverlayTrigger overlay={this.renderTooltip()} {...props}>
                {this.renderChildComponent()}
            </OverlayTrigger>
        );
    }
}
