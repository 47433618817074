import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, initialize, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { ProjectCreateV2FunctionsContext } from '../../../context';
import { editSectionForm as form, fieldNames } from '../../../constants';
import { updateProjectSections } from '../../../../../../../actions/govProjects';
import { validate } from '../../../../../../../components/ProjectSectionsEditButton/validate';
import {
    sortSections,
    getIndexToChangeParentDivider,
    moveArrayElement,
} from '../../../../../../../helpers';
import { DisconnectedEditSection } from './DisconnectedEditSection';
import { getSDv2WritingSections } from '../../../../selectors';

const { PARENT_DIVIDER, PROJECT_SECTIONS } = fieldNames;
const selector = formValueSelector(form);

// This component mimics (in a way) the behaviour of the legacy component "ProjectSectionsEditModal"
// The create project for doesnt have an object for `projectSections`, they are handled separatedly,
// thats why this component (and ProjectSectionsEditModal) rely in a subform that contains only
// the sections and call a specific endpoint on update.)
const ConnectedSectionHeaderEditSection = ({
    afterCancel,
    afterSave,
    change,
    index,
    showActionsOnFooter = false,
    useRawSections = false,
}) => {
    const dispatch = useDispatch();
    const { project } = useContext(ProjectCreateV2FunctionsContext);

    let sections = useSelector(getSDv2WritingSections);
    const rawSections = project[PROJECT_SECTIONS];

    if (useRawSections) {
        sections = rawSections;
    }

    const values = useSelector((state) => selector(state, PROJECT_SECTIONS));
    const { useManualNumbering, useSectionDividers } = project;

    useEffect(() => {
        if (index !== null && sections[index]) {
            dispatch(initialize(form, { [PROJECT_SECTIONS]: sections }));
        }
    }, [dispatch, index, sections]);

    const onCancel = () => {
        if (afterCancel) {
            afterCancel();
        }
    };

    const onSave = (originalParentDividerIndex = null) => {
        const activeSection = values[index];
        const activeSectionRawIndex = rawSections.findIndex((s) => s.id === activeSection.id);

        // merge modified section with all raw sections
        const updatedRawSections = rawSections.map((s) => {
            if (s.id === activeSection.id) {
                return activeSection;
            }

            return s;
        });

        // if the parent divider changed we will need to reorder the elements
        // to move the element to be a child of the divider
        let newParentIndex = null;
        const parentDividerChanged =
            !Number.isNaN(parseInt(activeSection[PARENT_DIVIDER], 10)) &&
            parseInt(activeSection[PARENT_DIVIDER], 10) !== originalParentDividerIndex;

        if (parentDividerChanged) {
            newParentIndex = getIndexToChangeParentDivider(
                activeSection[PARENT_DIVIDER],
                updatedRawSections.length
            );
        }

        const updatedAndResortedSections = sortSections({
            sections:
                newParentIndex === null
                    ? updatedRawSections
                    : moveArrayElement(updatedRawSections, activeSectionRawIndex, newParentIndex),
            useManualNumbering,
            useSectionDividers,
        });

        dispatch(
            updateProjectSections(project.id, {
                [PROJECT_SECTIONS]: updatedAndResortedSections,
            })
        );

        if (afterSave) {
            const visibleSectionIds = values.map((s) => s.id);

            const sectionThatWasEdited = updatedAndResortedSections
                .filter((s) => visibleSectionIds.includes(s.id))
                .findIndex((section) => section.id === sections[index].id);

            afterSave(sectionThatWasEdited > -1 ? sectionThatWasEdited : null);
        }
    };

    return (
        <DisconnectedEditSection
            form={form}
            {...{
                change,
                onCancel,
                onSave,
                index,
                showActionsOnFooter,
            }}
        />
    );
};

ConnectedSectionHeaderEditSection.propTypes = {
    afterSave: PropTypes.func,
    afterCancel: PropTypes.func,
    change: PropTypes.func.isRequired, // redux-form prop
    // index of the section we are editing
    index: PropTypes.number.isRequired,
    showActionsOnFooter: PropTypes.bool,
    useRawSections: PropTypes.bool,
};

export const SectionHeaderEditSection = reduxForm({
    enableReinitialize: true,
    form,
    validate,
})(ConnectedSectionHeaderEditSection);
