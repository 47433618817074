import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isRequisitionClosed } from '@og-pro/shared-config/requisitions';

import { requisitionSocket } from '../../lib/sockets';
import { budgetCheckRequisition } from '../../actions/requisitions';
import { joinRequisition, leaveRequisition } from '../../actions/requisitionSocket';
import { getShowConnectionAlert } from '../selectors';
import { getRequisitionEndsInPurchaseOrder } from '../../selectors/govApp';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../actions/notification';

export const useRequisitionSocket = (requisitionId) => {
    const dispatch = useDispatch();
    const shouldShowConnectionAlert = useSelector((state) => getShowConnectionAlert(state));
    const shouldShowConnectionAlertRef = useRef(shouldShowConnectionAlert);

    useEffect(() => {
        shouldShowConnectionAlertRef.current = shouldShowConnectionAlert;
    }, [shouldShowConnectionAlert]);

    useEffect(() => {
        const reconnectHandler = () => {
            dispatch(joinRequisition(requisitionId));

            if (shouldShowConnectionAlertRef.current) {
                dispatch(reconnectionAlert());
            }
        };

        const connectErrorHandler = () => {
            if (!shouldShowConnectionAlertRef.current) {
                dispatch(showConnectionAlert());
            }
        };

        requisitionSocket.connect();
        requisitionSocket.io.on('reconnect', reconnectHandler);
        requisitionSocket.on('connect_error', connectErrorHandler);
        dispatch(joinRequisition(requisitionId));

        return () => {
            if (shouldShowConnectionAlertRef.current) {
                dispatch(hideConnectionAlert());
            }

            requisitionSocket.io.off('reconnect', reconnectHandler);
            requisitionSocket.off('connect_error', connectErrorHandler);
            dispatch(leaveRequisition(requisitionId));
            requisitionSocket.disconnect();
        };
    }, []);
};

export const useRequisitionBudgetCheckOnLoad = (requisition) => {
    const dispatch = useDispatch();
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    // Hook runs whenever the requisition has been loaded onto a page
    useEffect(() => {
        // Skip budget check for the non-FMS case or if requisition is closed
        if (requisition && endsInPurchaseOrder && !isRequisitionClosed(requisition.status)) {
            dispatch(budgetCheckRequisition(requisition.id));
        }
    }, [requisition?.id]);
};
