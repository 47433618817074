import {
    requisitionFiltersNames,
    requisitionSortingOptionsNames,
} from '@og-pro/shared-config/requisitions';
import {
    actionTypesDisplayData,
    actionTypes,
} from '@og-pro/shared-config/requisitions/lastActions';

const {
    CREATOR_LAST_NAME,
    SUBMITTED_AT,
    DESCRIPTION_OF_REQUEST,
    IDENTIFIER,
    ACTIVE_DATE,
    STEP_NAME,
} = requisitionSortingOptionsNames;

const ASC = 'ASC';
const DESC = 'DESC';

export const buildSortingOptionValue = (sort, sortDirection) => `${sort},${sortDirection}`;

export const sortingOptions = [
    // REQ TODO: Add sorting options when Line Items are implemented
    // {
    //     label: 'Amount',
    //     options: [
    //         { label: 'Line Items - Most to Least', value: '' },
    //         { label: 'Line Items - Least to Most', value: '' },
    //         { label: 'Total - Most to Least', value: '' },
    //         { label: 'Total - Most to Least', value: '' },
    //     ],
    // },
    {
        label: 'Request',
        options: [
            {
                label: 'Creator Last Name - A to Z',
                value: buildSortingOptionValue(CREATOR_LAST_NAME, ASC),
            },
            {
                label: 'Creator Last Name - Z to A',
                value: buildSortingOptionValue(CREATOR_LAST_NAME, DESC),
            },
            {
                label: 'Date Submitted - New to Old',
                value: buildSortingOptionValue(SUBMITTED_AT, DESC),
            },
            {
                label: 'Date Submitted - Old to New',
                value: buildSortingOptionValue(SUBMITTED_AT, ASC),
            },
            {
                label: 'Description - A to Z',
                value: buildSortingOptionValue(DESCRIPTION_OF_REQUEST, ASC),
            },
            {
                label: 'Description - Z to A',
                value: buildSortingOptionValue(DESCRIPTION_OF_REQUEST, DESC),
            },
            {
                label: 'Number - Ascending',
                value: buildSortingOptionValue(IDENTIFIER, ASC),
            },
            {
                label: 'Number - Descending',
                value: buildSortingOptionValue(IDENTIFIER, DESC),
            },
        ],
    },
    {
        label: 'Step',
        options: [
            {
                label: 'Active Date - New to Old',
                value: buildSortingOptionValue(ACTIVE_DATE, DESC),
            },
            {
                label: 'Active Date - Old to New',
                value: buildSortingOptionValue(ACTIVE_DATE, ASC),
            },
            {
                label: 'Name - A to Z',
                value: buildSortingOptionValue(STEP_NAME, ASC),
            },
            {
                label: 'Name - Z to A',
                value: buildSortingOptionValue(STEP_NAME, DESC),
            },
        ],
    },
];

export const getSortingOptionAndDirection = (value) => {
    const [sort, sortDirection] = value.split(',');

    return { sort, sortDirection };
};

const { CREATORS, REVIEW_GROUPS, EXCEPTION_REVIEW_SEQUENCES, LAST_ACTION, REQUEST_TYPES } =
    requisitionFiltersNames;
const { DRAFTED, SUBMITTED, APPROVED, PO_CREATED, REJECTED, CANCELLED, ON_HOLD } = actionTypes;

const dashboardActionTypesDisplayData = {
    [DRAFTED]: actionTypesDisplayData[DRAFTED],
    [SUBMITTED]: actionTypesDisplayData[SUBMITTED],
    [APPROVED]: {
        ...actionTypesDisplayData[APPROVED],
        subText: '(includes Acknowledged and Notification Sent)',
    },
    [PO_CREATED]: actionTypesDisplayData[PO_CREATED],
    [REJECTED]: actionTypesDisplayData[REJECTED],
    [CANCELLED]: actionTypesDisplayData[CANCELLED],
    [ON_HOLD]: actionTypesDisplayData[ON_HOLD],
};

export const initialCheckboxFilters = {
    [LAST_ACTION]: {
        title: 'Last Action',
        options: Object.keys(dashboardActionTypesDisplayData).map((key) => ({
            label: dashboardActionTypesDisplayData[key].text,
            description: dashboardActionTypesDisplayData[key].subText,
            value: key,
        })),
    },
    [CREATORS]: {
        title: 'Creators',
        options: [],
    },
    [REVIEW_GROUPS]: {
        title: 'Review Groups',
        options: [],
    },
    [EXCEPTION_REVIEW_SEQUENCES]: {
        title: 'Exceptions',
        options: [],
    },
    [REQUEST_TYPES]: {
        title: 'Request Types',
        options: [],
    },
};
