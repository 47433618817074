import React from 'react';

import { Field } from 'redux-form';

import { ReduxFormRadioGroup } from '@og-pro/ui';

import PropTypes from 'prop-types';

import {
    customFieldFields,
    numberKindsArray,
} from '@og-pro/shared-config/customFormService/customField';

const { NUMBER_KIND } = customFieldFields;

const ConnectedNumberOptions = ({ disabled }) => {
    return (
        <Field
            component={ReduxFormRadioGroup}
            defaultSelected
            disabled={disabled}
            label="Type of Number *"
            name={NUMBER_KIND}
            options={numberKindsArray}
            showValidation
        />
    );
};

export const NumberOptions = ConnectedNumberOptions;

ConnectedNumberOptions.propTypes = {
    disabled: PropTypes.bool,
};
