import { useDispatch, useSelector } from 'react-redux';

import { setSectionEnabled } from '../../../../actions/requisitionsCreate';
import {
    EDIT_MODE,
    formSections,
    ADDITIONAL_INFORMATION,
    CUSTOM_FORM,
} from '../../../../constants/requisitionsCreate';
import { useQueryParam } from '../../../../hooks';
import { getRequisitionJS } from '../../../../selectors/govApp';
import { scrollToSection } from '../utils';

export const useRequisitionSectionClick = () => {
    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);

    const [, , setMultipleQueryParams] = useQueryParam('mode');

    const handleEditClick = (sectionKey) => {
        setMultipleQueryParams({
            mode: EDIT_MODE,
            section: sectionKey,
        });

        formSections.forEach((section) => {
            // Do not enable additional information if there are no flag responses
            if (section === ADDITIONAL_INFORMATION && requisition.flagResponses.length === 0) {
                return;
            }

            // Do not enable custom fields section if there are no custom fields
            if (section === CUSTOM_FORM && !requisition.customFormSnapshotVersion) {
                return;
            }

            dispatch(setSectionEnabled(section));
        });

        setTimeout(() => {
            scrollToSection(sectionKey);
        }, 0);
    };

    return handleEditClick;
};
