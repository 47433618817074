import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Drawer, Typography } from '@og-pro/ui';
import { History as HistoryIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { StatusHistory } from './StatusHistory';

export const HistoryButton = ({ contractDocument }) => {
    const [opened, setOpened] = useState(false);

    return (
        <>
            <Drawer
                PaperProps={{ sx: { width: '45vw' } }}
                anchor="right"
                onClose={() => setOpened(false)}
                open={opened}
            >
                <Box sx={{ px: 6, py: 3 }}>
                    <Box sx={{ mb: 2.5 }}>
                        <Button
                            color="inherit"
                            onClick={() => setOpened(false)}
                            qaTag="contractDocuments-closeHistory"
                            size="small"
                            sx={{ padding: 0 }}
                            variant="text"
                        >
                            <ArrowBackIcon sx={{ fontSize: '1rem' }} /> &nbsp;Back to Documents
                        </Button>
                    </Box>
                    <Typography variant="h2">{contractDocument.title || 'Untitled'}</Typography>

                    <Box mt={3}>
                        <StatusHistory contractDocument={contractDocument} />
                    </Box>
                </Box>
            </Drawer>
            <IconButton
                onClick={() => setOpened(true)}
                qaTag="contractDocuments-history"
                size="small"
            >
                <HistoryIcon fontSize="small" />
            </IconButton>
        </>
    );
};

HistoryButton.propTypes = {
    contractDocument: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
    }).isRequired,
};
