import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import React from 'react';
import PropTypes from 'prop-types';

export const SelectCustomFieldAutocompleteOptions = ({ option, ...props }) => {
    const IconComponent = option.icon;
    return (
        <ListItem
            {...props}
            disablePadding
            sx={{
                borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
            }}
        >
            <ListItemButton qaTag={`customFieldOption-${option.name}`}>
                <ListItemIcon>
                    <IconComponent />
                </ListItemIcon>
                <ListItemText
                    primary={`${option.name}${option.shortTitle ? ` (${option.shortTitle})` : ''}`}
                    secondary={option.label}
                />
            </ListItemButton>
        </ListItem>
    );
};

SelectCustomFieldAutocompleteOptions.propTypes = {
    option: PropTypes.object,
};
