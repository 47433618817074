import { buildMap } from '@og-pro/shared-config/helpers';

export const GENERAL_INFORMATION = 'generalInformation';
export const PURCHASE_DETAILS = 'purchaseDetails';
export const VENDOR_SELECTION = 'vendorSelection';
export const CUSTOM_FORM = 'customForm';
export const ADDITIONAL_INFORMATION = 'additionalInformation';
export const ATTACHMENTS = 'attachments';

export const sections = [
    {
        name: 'General Information',
        type: GENERAL_INFORMATION,
        sectionNameKey: 'sectionNameGeneral',
        sectionInstructionsKey: 'sectionInstructionsGeneral',
        sectionHideKey: null,
        disabled: false,
    },
    {
        name: 'Purchase Details',
        type: PURCHASE_DETAILS,
        sectionNameKey: 'sectionNamePurchase',
        sectionInstructionsKey: 'sectionInstructionsPurchase',
        sectionHideKey: 'hideSectionPurchase',
        disabled: false,
    },
    {
        name: 'Vendor Selection',
        type: VENDOR_SELECTION,
        sectionNameKey: 'sectionNameVendor',
        sectionInstructionsKey: 'sectionInstructionsVendor',
        sectionHideKey: 'hideSectionVendor',
        disabled: false,
    },
    {
        name: 'Custom Fields',
        type: CUSTOM_FORM,
        sectionNameKey: 'sectionNameCustomForm',
        sectionInstructionsKey: 'sectionInstructionsCustomForm',
        sectionHideKey: null,
        disabled: false,
    },
    {
        name: 'Additional Information',
        type: ADDITIONAL_INFORMATION,
        sectionNameKey: 'sectionNameAdditional',
        sectionInstructionsKey: 'sectionInstructionsAdditional',
        sectionHideKey: null,
        disabled: false,
    },
    {
        name: 'Attachments',
        type: ATTACHMENTS,
        sectionNameKey: 'sectionNameAttachment',
        sectionInstructionsKey: 'sectionInstructionsAttachment',
        sectionHideKey: 'hideSectionAttachment',
        disabled: false,
        internal: true,
        optional: true,
        description: 'These documents are for internal use only.',
    },
].map((section) => ({
    completed: false,
    ...section,
}));

export const sectionsMap = buildMap(sections, 'type');
export const sectionsTypes = sections.map((section) => section.type);

export const NO_ADDRESS_FOUND_TEXT = 'No Address Found';
export const NO_EMAIL_FOUND_TEXT = 'No Email Found';

export const formSections = [
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
    CUSTOM_FORM,
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
];

export const EDIT_MODE = 'edit';
export const REVIEW_MODE = 'review';
