import { listToDict } from '@og-pro/shared-config/helpers';

const cols = ['fieldName', 'reportName', 'lastUpdated', 'status'];

export const colsDict = listToDict(cols);

const { FIELD_NAME, REPORT_NAME, LAST_UPDATED, STATUS } = colsDict;

export const defaultSortConfig = {
    sortBy: FIELD_NAME,
    sortDirection: 'asc',
};

export const columns = {
    [FIELD_NAME]: {
        label: 'Field Name',
        sortKey: FIELD_NAME,
        headProps: { align: 'left' },
        bodyProps: { align: 'left', width: '600px' },
    },
    [REPORT_NAME]: {
        label: 'Report Name',
        sortKey: REPORT_NAME,
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [LAST_UPDATED]: {
        label: 'Last Updated',
        sortKey: LAST_UPDATED,
        headProps: { align: 'left' },
        bodyProps: { align: 'left' },
    },
    [STATUS]: {
        label: 'Status',
        sortKey: STATUS,
        headProps: { align: 'center' },
        bodyProps: { align: 'center', width: '120px' },
    },
};
