import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { useDispatch } from 'react-redux';

import { fieldNames } from '../../../../constants';
import { qaTagPageName } from '../../../../../constants';
import { ContractSearchInput, SearchSelect } from '../../../../../../../../components';
import { SearchOption } from './SearchOption';
import { SupplementalInfo } from './SupplementalInfo';
import { usePreviousWithInitialValue } from '../../../../../../../../hooks';

const { CONTRACT_ID, CONTRACT_ID_TO_UPDATE, PRO_USER_ID, VENDOR_ASSIGNED_NO } = fieldNames;

export const VendorContractInput = ({
    contracts,
    change,
    disabled,
    endsInPurchaseOrder,
    fieldName,
    selectedContractId,
    showFormValidation,
    showMismatchModal,
    vendor,
    vendorUsers,
}) => {
    const [formStateKey, setFormStateKey] = useState(1);
    const [additionalContracts, setAdditionalContracts] = useState([]);

    const dispatch = useDispatch();
    const options = contracts.map((contract) => ({
        contract,
        label: `${contract.title || 'Untitled Contract'}${contract.contractId ? ` - ${contract.contractId}` : ''}`,
        value: contract.id,
    }));

    const availableContracts = contracts.concat(additionalContracts);
    const selectedContract = availableContracts.find(({ id }) => id === selectedContractId);

    const previousSelectedContract = usePreviousWithInitialValue(selectedContract);

    const updateVendorUser = (contract) => {
        const contractPartyEmail = contract?.contractParty?.companyEmail;
        if (contractPartyEmail) {
            const vendorUser = vendorUsers.find(({ email }) => email === contractPartyEmail);
            if (vendorUser) {
                dispatch(change(`${fieldName}.${PRO_USER_ID}`, vendorUser.id));
            }
        }
    };

    // Handles changes to the selected contract
    useEffect(() => {
        const hasChanged = previousSelectedContract?.id !== selectedContract?.id;
        if (!hasChanged) {
            return;
        }

        // For FMS check that the contract actually matches the vendor assigned number
        // Do not allow association when there is no match
        if (endsInPurchaseOrder) {
            const contractId = selectedContract?.id;
            if (
                selectedContract &&
                selectedContract[VENDOR_ASSIGNED_NO] !== `${vendor[VENDOR_ASSIGNED_NO]}` &&
                vendor[CONTRACT_ID_TO_UPDATE] !== contractId
            ) {
                // When confirmed, re-add the contract ID that was originally removed
                const onUpdate = () => {
                    // This field keeps track of the contract ID that was confirmed to be updated
                    dispatch(change(`${fieldName}.${CONTRACT_ID_TO_UPDATE}`, contractId));
                    dispatch(change(`${fieldName}.${CONTRACT_ID}`, contractId));
                    setFormStateKey(formStateKey + 1); // Needed to re-render input, so value change is reflected
                };

                // Remove the CONTRACT_ID value from form and show mismatch modal
                dispatch(change(`${fieldName}.${CONTRACT_ID}`, null));
                setFormStateKey(formStateKey + 1); // Needed to re-render input, so value change is reflected
                showMismatchModal({ contract: selectedContract, onUpdate, vendor });
            }
            return;
        }

        // Remove user when contract is cleared
        if (!selectedContract) {
            dispatch(change(`${fieldName}.${PRO_USER_ID}`, null));
        }

        // Update vendor assigned number when contract is changed
        if (selectedContract?.vendorAssignedNo) {
            dispatch(
                change(`${fieldName}.${VENDOR_ASSIGNED_NO}`, selectedContract.vendorAssignedNo)
            );
        }

        // Attempt to update user when the contract is changed
        updateVendorUser(selectedContract);
    }, [selectedContract?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handles when contract is included with initial vendor selection
    useEffect(() => {
        const isNew = !vendor.id;
        if (isNew) {
            updateVendorUser(selectedContract);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const searchProps = endsInPurchaseOrder
        ? {
              component: ContractSearchInput,
              getOptions: (searchResults) => [
                  { label: 'Matched Contracts', options },
                  { label: 'Search Results', options: searchResults },
              ],
              onChangeWithOption: (contractData) => {
                  // `additionalContracts` are contracts that are not in the queried results due to
                  // the contract being selected from the input. We manually add it here, so
                  // `selectedContract` can find it.
                  const contractOptions = contractData?.contract ? [contractData.contract] : [];
                  setAdditionalContracts(contractOptions);
              },
          }
        : {
              component: SearchSelect,
              options,
          };

    return (
        <>
            <Field
                {...searchProps}
                components={{
                    Option: SearchOption,
                }}
                disabled={disabled}
                isClearable
                key={formStateKey}
                label="Contract"
                name={`${fieldName}.${CONTRACT_ID}`}
                noMarginBottom
                placeholder="Select a contract"
                qaTag={`${qaTagPageName}-contractId`}
                showValidation={showFormValidation}
                useOpenGovStyle
            />
            {selectedContract && (
                <Box marginTop={0.5}>
                    <SupplementalInfo contract={selectedContract} />
                </Box>
            )}
        </>
    );
};

VendorContractInput.propTypes = {
    contracts: PropTypes.arrayOf(
        PropTypes.shape({
            contractId: PropTypes.string,
            deliveryCode: PropTypes.string,
            paymentTerms: PropTypes.string,
        })
    ).isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    endsInPurchaseOrder: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    selectedContractId: PropTypes.number,
    showFormValidation: PropTypes.bool,
    showMismatchModal: PropTypes.func.isRequired,
    vendor: PropTypes.shape({
        id: PropTypes.number,
        vendorAssignedNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    vendorUsers: PropTypes.array.isRequired,
};
