import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getRequisitionEndsInPurchaseOrder } from '../../../../../../../selectors/govApp';

export const Header = ({ showDiscountInHeader, tax }) => {
    const styles = require('./index.scss');
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    return (
        <div className={styles.priceItemHeader}>
            <div className={styles.line}>Line</div>
            {endsInPurchaseOrder && <div className={styles.budget}>Budget</div>}
            <div className={styles.description}>Description</div>
            <div className={styles.quantity}>Qty</div>
            <div className={styles.unitOfMeasure}>UoM</div>
            <div className={styles.unitPrice}>Unit Price</div>
            {showDiscountInHeader && <div className={styles.discount}>Discount</div>}
            {!!tax && <div className={styles.tax}>Tax</div>}
            <div className={styles.total}>Total</div>
        </div>
    );
};

Header.propTypes = {
    showDiscountInHeader: PropTypes.bool,
    tax: PropTypes.number,
};
